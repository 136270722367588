import DashboardPage from "../components/pages/dashboard/DashboardPage";

const routes = [
    {
        path: "/dashboard",
        RouteComponent: DashboardPage
    }
];

export default routes;
