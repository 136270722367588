import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    useCreateGroupCategoryMutation,
    useGetGroupCategoryMutation,
    useGetGroupCategoryTagsMutation,
    useUpdateGroupCategoryMutation
} from "../../../api/products/products.query";
import "../../../assets/css/pages/productTag.css";
import { APP_TITLE } from "../../../config/constant";
import { colorPicker } from "../../../utils/utilHelpers";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import TagComponent from "../../common/forms/TagComponent";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import errorSuccess from "./../../../assets/images/icons8-check-64.png";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
toast.configure();

export default function ProductCategoryList(props) {
    document.title = `${APP_TITLE} - Categories`;
    const navigate = useNavigate();
    const handleSuccess = (responseData, context) => {
        navigate("/products/cluster");
        toast.success(
            <>
                <div style={{ fontSize: "12px", fontWeight: 700, marginBottom: "8px" }}>Category Created!</div>
                <div style={{ fontSize: "10px", fontWeight: "400" }}>New category was saved!</div>
            </>,
            {
                theme: "dark",
                icon: ({ theme, type }) => <img src={errorSuccess} alt="Success" />,
                autoClose: 4000
            }
        );
    };
    /* #region  STATES */
    const { mutate: getData, data, isLoading } = useGetGroupCategoryTagsMutation();
    const { mutate: postTags, data: info, isLoading: createLoad } = useCreateGroupCategoryMutation(handleSuccess);
    const { mutate: getCategoryData, data: categoryData, isLoading: dataLoading } = useGetGroupCategoryMutation();
    const { mutate: updateCategoryData, data: updatedData, isLoading: updateLoad } = useUpdateGroupCategoryMutation();

    const [buttonName, setButtonName] = useState("SAVE");
    const [confirmPopUp, setConfirmPopUp] = useState(false);
    const [buttonGray, setButtonGray] = useState(true);
    const [preLoader, setPreLoader] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);

    const [fieldCategoryName, setFieldCategoryName] = useState("");
    const [fieldCategoryNameError, setFieldCategoryNameError] = useState(false);

    const [fieldDescription, setFieldDescription] = useState("");
    const [fieldDescriptionError, setFieldDescriptionError] = useState(false);
    const [categoryArray, setCategoryArray] = useState([]);
    const [categoryArrayAI, setCategoryArrayAi] = useState([]);
    const [categoryTag, setCategoryTag] = useState("");
    const inputRef = useRef(null);
    const handleCategoryTag = tag => {
        setCategoryArray(tag);
    };
    useEffect(() => {
        if (categoryData) {
            setFieldCategoryName(categoryData?.data?.name);
            setFieldDescription(categoryData?.data?.description);
            setCategoryArray(categoryData?.data?.tags);
        }
    }, [categoryData]);
    useEffect(() => {
        if (data) {
            setCategoryArray([...(categoryArray || []), ...(data?.records || [])]);
        }
    }, [data]);
    const [controlBarCenterBtns, setControlBarCenterBtns] = useState([
        { to: "/products", active: false, disabled: false, title: "Products" },
        {
            to: "/products/categories",
            active: true,
            disabled: false,
            title: "Categories"
        }
    ]);
    const [controlBarRigthBtns, setControlBarRightBtns] = useState([
        {
            to: "#",
            active: false,
            title: "View",
            icon: "fa-eye"
        },
        {
            to: "#",
            active: true,
            title: "Add",
            icon: "fa-plus"
        },
        {
            to: "/products/cluster",
            active: false,
            //   disabled: true,
            title: "Cluster",
            icon: "fa-list-tree"
        }
    ]);

    const { id } = useParams();

    const fetchData = () => {
        const formData = {
            groupCategoryID: id
        };
        getCategoryData(formData);
    };

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        //setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region METHODS */

    //   const [categoryArray, setCategoryArray] = useState([]);

    const handleCategoryChange = e => {
        if (e.key === "Enter") {
            return;
        }
        setCategoryTag(e.target.value);
    };

    const handleCategoryName = e => {
        setFieldCategoryName(e.target.value);
    };
    const handleFieldDescription = e => {
        setFieldDescription(e.target.value);
    };
    const generateWithAi = async e => {
        e.preventDefault();
        // setIsLoading(true);

        const formData = {
            categoryName: fieldCategoryName,
            description: fieldDescription
        };

        getData(formData);

        // let bo = [];
        // let gpt = [];
        // await Library.makePostRequest("generateCategoryClusterByBO", formData, false, setPreLoader).then(res => {
        //     if (res.data.status !== 200) {
        //         return;
        //     }
        //     const data = res.data.records;
        //     bo = [...data];
        // });
        // await Library.makePostRequest("generateCategoryClusterByChatGPT", formData, false, setPreLoader).then(res => {
        //     if (res.data.status !== 200) {
        //         return;
        //     }

        //     const data = res.data.records;
        //     gpt = [...data];
        // });
        // setIsLoading(false);
        // setCategoryArray([...categoryArray, ...bo, ...gpt]);
    };

    const handleSubmit = e => {
        if (id) {
            const formData = {
                groupCategoryID: id,
                data: {
                    name: fieldCategoryName,
                    description: fieldDescription,
                    tags: categoryArray
                }
            };
            updateCategoryData(formData);
        } else {
            const formData = {
                name: fieldCategoryName,
                description: fieldDescription,
                tags: categoryArray,
                BOstagesAccountID: localStorage.getItem("token"),
                companyID: localStorage.getItem("currentCompanyID")
            };
            postTags(formData);
        }
        setFieldCategoryName("");
        setFieldDescription("");
        setCategoryArray([]);
        // Library.makePostRequest("createCategoryCluster", formData, false, setPreLoader).then(res => {
        //     if (res.data.status !== 200) {
        //         return;
        //     } else if (res.data.status === 200) {
        //         setCategoryArray([]);
        //         setFieldCategoryName("");
        //         setFieldDescription("");
        //         Library.showSuccessMessage(
        //             <div style={{ display: "flex", flexDirection: "column" }}>
        //                 <div>{res.data.heading}</div>
        //                 <div>{res.data.message}</div>
        //             </div>
        //         );
        //     }

        //     const data = res.data;
        // });
    };
    const handleInputKeyPress = event => {
        if (event.key === "Enter" && categoryTag.trim() !== "") {
            event.preventDefault();
            setCategoryArray([...(categoryArray || []), { tagName: categoryTag.trim(), type: "Manual" }]);
            setCategoryTag("");
        }
    };

    const handleRemove = rem => {
        const array = [...(categoryArray || [])];

        const indexToRemove = categoryArray.indexOf(rem);

        if (indexToRemove !== -1) {
            array.splice(indexToRemove, 1);
        }

        setCategoryArray([...array]);
    };

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    /* #endregion */
    function setupHeaders(props) {
        props.setControlBarLeftBtns([
            {
                to: "#",
                disabled: true,
                title: "Products",
                icon: "fa-magnifying-glass",
                onClick: e => e.preventDefault()
            }
        ]);
        props.setControlBarCenterBtns([
            { to: "/products", active: false, disabled: false, title: "PRODUCTS" },
            { to: "/products/categories", active: true, disabled: false, title: "Categories" }
        ]);
        if (id) {
            props.setControlBarRightBtns([
                { to: "/products/cluster", active: false, title: "View", icon: "fa-angle-left" },
                { to: "#", active: true, title: "Add", icon: "fa-pen-line" }
            ]);
        } else {
            props.setControlBarRightBtns([
                { to: "/products/cluster", active: false, title: "View", icon: "eye" },
                { to: "#", active: true, title: "Add", icon: "fa-plus" },
                { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
            ]);
        }

        props.setFilterHeaderProperties({
            visible: false,
            gridEnabled: false,
            listEnabled: false,
            onRefreshAction: undefined
        });
    }

    return (
        <>
            <style>
                {`.form-group.activeborder .form-control:nth-child(2) {
                  border-left:${
                      fieldCategoryName.trim() !== "" || fieldDescription.trim() !== ""
                          ? "5px solid var(--backgrounds-lines-n-500) !important"
                          : "5px solid var(--Buttons-Orange-Outline) !important"
                  }  ;
                 `}
            </style>
            {isLoading || dataLoading || updateLoad ? <FullScreenLoader /> : null}
            <>
                <div className="filtertopcombo" style={{ marginTop: "-2px", textAlign: "right" }}>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">
                            <div
                                className="filtersection creatorsbtnset enroll-brand "
                                style={{ marginBottom: "10px" }}>
                                <span
                                    className={
                                        buttonGray
                                            ? "customeset textinside next-new-btn"
                                            : "customeset textinside next-new-btn disabledbtn"
                                    }>
                                    <BtnLargeSolid
                                        type="submit"
                                        onClick={() => {
                                            setConfirmPopUp(true);
                                        }}
                                        className="float-right"
                                        disabled={!fieldCategoryName || !categoryArray.length || !fieldDescription}
                                        text={buttonName}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="new-section" style={{ marginTop: "30px", position: "relative" }}>
                    {confirmPopUp && (
                        <div
                            style={{
                                position: "fixed",
                                zIndex: "9999999",
                                top: "452px",
                                left: "44%"
                            }}>
                            <FullScreenOverlay
                                children={
                                    <DialogBoxComponent
                                        heading="JUST CHECKING..."
                                        headingColor="#00AEEF"
                                        title="Ready To Save?"
                                        message={`Category will be created`}
                                        onClickYes={() => {
                                            handleSubmit();
                                            setConfirmPopUp(false);
                                        }}
                                        onClickNo={() => {
                                            setConfirmPopUp(false);
                                        }}
                                    />
                                }
                            />
                        </div>
                    )}
                    {createLoad ? (
                        <div style={{ position: "fixed", zIndex: "9999999", top: "452px", left: "44%" }}>
                            <FullScreenOverlay
                                children={
                                    <DialogBoxComponent heading="SAVING..." headingColor="#00BB00" loading="true" />
                                }
                            />
                        </div>
                    ) : (
                        // <div style={{ display: "none" }}></div>
                        ""
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cardsection">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        CREATE CATEGORY
                                        <span className="formcautions">
                                            <ul>
                                                <li>
                                                    <span className="makesqaure mandatorythis"></span>
                                                    <span>recommended</span>
                                                </li>
                                                <li>
                                                    <span className="makesqaure mandatorythis"></span>
                                                    mandatory
                                                </li>
                                                <li>
                                                    <span className="makesqaure lockedthis"></span>
                                                    locked
                                                </li>
                                            </ul>
                                        </span>
                                    </h3>
                                </div>

                                <div className="card-body">
                                    <form>
                                        <div className="row first-portion-container">
                                            <div className="col-md-3 first-portion-input-one">
                                                <div className="form-group activeborder">
                                                    <label htmlFor="fieldCategoryName">
                                                        &nbsp;{" "}
                                                        <i
                                                            className="fas fa-info-circle"
                                                            aria-hidden="true"
                                                            style={{ fontSize: "9px", fontWeight: "500" }}></i>{" "}
                                                        Category name
                                                    </label>
                                                    <input
                                                        onChange={handleCategoryName}
                                                        type="text"
                                                        id="fieldCategoryName"
                                                        value={fieldCategoryName}
                                                        className={
                                                            fieldCategoryNameError
                                                                ? "form-control error-input"
                                                                : "form-control"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-9 first-portion-second-input">
                                                <div className="form-group activeborder">
                                                    <label htmlFor="fieldCategoryName">
                                                        &nbsp;{" "}
                                                        <i
                                                            className="fas fa-info-circle"
                                                            aria-hidden="true"
                                                            style={{ fontSize: "9px", fontWeight: "500" }}></i>{" "}
                                                        Description
                                                    </label>
                                                    <textarea
                                                        rows={4}
                                                        type="text"
                                                        onChange={handleFieldDescription}
                                                        id="fieldCategoryName"
                                                        value={fieldDescription}
                                                        className={
                                                            fieldCategoryNameError
                                                                ? "form-control error-input disable-resize"
                                                                : "form-control disable-resize"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="generate-btn-container">
                                                <p>Will be consider by AI if you click 'GENERATE WITH AI'</p>

                                                <button
                                                    className="btn main-inner-btn accent"
                                                    onClick={generateWithAi}
                                                    disabled={!fieldCategoryName || !fieldDescription}>
                                                    GENERATE WITH AI
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <div
                                                className="col-md-12 first-portion-third-input"
                                                style={{ marginBottom: "0px" }}>
                                                <div className="form-group activeborder">
                                                    <label htmlFor="fieldCategoryName">PRODUCTS INCLUDING</label>
                                                    <div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                gap: "8px",
                                                                flexWrap: "wrap",
                                                                height: "auto",
                                                                minHeight: "90px"
                                                            }}
                                                            className={
                                                                fieldCategoryNameError
                                                                    ? "form-control error-input disable-resize"
                                                                    : "form-control disable-resize tags-input"
                                                            }
                                                            onClick={focusInput}>
                                                            {categoryArray?.map((item, index) => (
                                                                // <div
                                                                //      className="category-tag-container"
                                                                //     key={index}
                                                                //     style={{
                                                                //         backgroundColor: colorPicker(item?.type)
                                                                //     }}>
                                                                //     <div style={{ fontSize: "12px" }}>
                                                                //         {item?.tagName}
                                                                //     </div>
                                                                //     <div onClick={() => handleRemove(item)}>
                                                                //         <i
                                                                //             className="fa-solid fa-xmark"
                                                                //             style={{
                                                                //                 fontSize: "13px",
                                                                //                 fontWeight: "500"
                                                                //             }}></i>
                                                                //     </div>
                                                                // </div>

                                                                <TagComponent
                                                                    index={index}
                                                                    bgColor={colorPicker(item?.type)}
                                                                    tagName={item?.tagName || item?.name}
                                                                    onClick={() => handleRemove(item)}
                                                                />
                                                            ))}

                                                            <div className="category-tag-input">
                                                                <input
                                                                    rows={3}
                                                                    type="text"
                                                                    id="fieldCategoryName"
                                                                    value={categoryTag}
                                                                    onKeyPress={handleInputKeyPress}
                                                                    onChange={handleCategoryChange}
                                                                    ref={inputRef}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-legend-group">
                                                        <div className="small-legend-field">
                                                            <div
                                                                className="box"
                                                                style={{
                                                                    background: "#2F5279",
                                                                    borderRadius: "50%"
                                                                }}></div>
                                                            <span className="legend">BO AI</span>
                                                        </div>
                                                        <div className="small-legend-field">
                                                            <div
                                                                className="box"
                                                                style={{
                                                                    background: "#3c7fc1",
                                                                    borderRadius: "50%"
                                                                }}></div>
                                                            <span className="legend">ChatGPT</span>
                                                        </div>
                                                        <div className="small-legend-field">
                                                            <div
                                                                className="box"
                                                                style={{
                                                                    background: "#7233DA",
                                                                    borderRadius: "50%"
                                                                }}></div>
                                                            <span className="legend">Manual Input</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}
