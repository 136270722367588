import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    useGetBrandContactInfoMutation,
    useGetBrandNameMutation,
    useGetGroupInvitedMutation,
    useInviteLabelBrandMutation
} from "../../../api/brands/brands.query";
import useDebounce from "../../../hooks/useDebouncs";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import SearchableDropdown from "../../common/editableDropdown/EditableDropdown";
import FormSimpleComponent from "../../common/forms/FormSimpleComponent";
import ListComponentLast10Entries from "../../common/lists/ListComponentLast10Entries";
import NotificationHandler from "../../common/notificationHandler/NotificationHandler";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import Tooltips from "../../common/tooltip/Tooltips";
toast.configure();

const Invite = props => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [selectedLabelBrand, setSelectedLabelBrand] = useState("");
    const [value, setValue] = useState("");

    const handleSuccess = responseData => {
        const failText = "User Already Invited";
        const successTextHeader = "Invitation Sent!";
        const successTextBody = `${firstName} will get an invitation by email with the guidelines for ${labelBrandName} to join Texcon group.`;
        const navigateTo = "/brands";

        NotificationHandler(responseData, null, failText, successTextHeader, successTextBody, navigate, navigateTo);
    };

    const { mutate: getBrandInfo, data: brandInfo, isLoading: brandInfoLoading } = useGetBrandContactInfoMutation();

    const { mutate: getData, data: brandData, isLoading } = useGetBrandNameMutation();
    const { mutate: getGroupData, data: tableData, isLoading: tableDataLoading } = useGetGroupInvitedMutation();
    useEffect(() => {
        getGroupData({
            companyID: localStorage.getItem("currentCompanyID")
        });
    }, []);
    const {
        mutate: inviteBrand,
        data: inviteData,
        isLoading: inviteLoading
    } = useInviteLabelBrandMutation(handleSuccess);

    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [brandName, setBrandName] = useState("");
    const [labelBrandName, setLabelBrandName] = useState("");
    const [buttonGray, setButtonGray] = useState(false);
    const [confirmPopUp, setConfirmPopUp] = useState(false);
    const [prevBrandData, setPrevBrandData] = useState(null);
    const brand = useDebounce(brandName, 100);

    const getBrandData = () => {
        getData({
            labelBrandName: brand,
            companyID: localStorage.getItem("currentCompanyID")
        });
    };
    useEffect(() => {
        if (brand.length > 2) {
            getBrandData();
        }
    }, [brand]);

    useEffect(() => {
        if (brandData) {
            setPrevBrandData(brandData?.data);
        }
    }, [brandData]);

    useEffect(() => {
        if (selectedLabelBrand) {
            getBrandInfo({
                labelBrandID: selectedLabelBrand
            });
        }
    }, [selectedLabelBrand]);

    useEffect(() => {
        setEmail(brandInfo?.data?.data?.email || "");
        setFirstName(brandInfo?.data?.data?.firstName || "");
        setLastName(brandInfo?.data?.data?.lastName || "");
    }, [brandInfo]);

    const columns = [
        {
            name: "Brand",
            selector: row => row?.Brand ?? "-"
        },
        {
            name: "Contact Person",
            selector: row => row?.contactPerson ?? "-"
        },
        {
            name: "Contact email",
            selector: row => row?.contactEmail ?? "-"
        },
        {
            name: "Invited By",
            selector: row => row?.invitedBy ?? "-"
        },
        {
            name: "Invited On",
            selector: row => formatDefaultDateTime(row?.invitedOn)
        },
        {
            name: "Joined  On",
            selector: row => formatDefaultDateTime(row?.affiliatedOn)
        }
    ];

    const handleSubmit = () => {
        const formData = {
            companyID: localStorage.getItem("currentCompanyID"),
            BOstagesAccountID: localStorage.getItem("token"),
            contactEmail: email,
            contactFirstName: firstName,
            labelBrandID: selectedLabelBrand,
            labelBrandName: value || brandName,
            contactLastName: lastName,
            id: !value ? "1" : "2"
        };
        inviteBrand(formData);
    };

    useEffect(() => {
        if (!props?.layoutType) return;
        setupHeaders(props);
    }, [props?.layoutType]);

    const data = brandData?.data;

    return (
        <div style={{ marginTop: "-24px", marginBottom: "24px" }}>
            <div className="filtertopcombo" style={{ paddingTop: "15px", paddingBottom: "15px", textAlign: "right" }}>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <div className="filtersection creatorsbtnset enroll-brand ">
                            <span
                                className={
                                    buttonGray
                                        ? "customeset textinside next-new-btn"
                                        : "customeset textinside next-new-btn disabledbtn"
                                }>
                                <BtnLargeSolid
                                    type="submit"
                                    onClick={() => {
                                        setConfirmPopUp(true);
                                    }}
                                    className="float-right"
                                    disabled={!firstName || (!value && !brandName) || !email}
                                    text="INVITE"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="new-section" style={{ marginTop: "24px", borderRadius: "4px" }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="cardsection" style={{ marginBottom: "24px" }}>
                            <div className="card-header">
                                <h3 className="card-title">
                                    <div style={{ paddingLeft: "0px", display: "inline-block" }}> INVITE BRAND</div>
                                    <span className="formcautions">
                                        <ul>
                                            <li>
                                                <span className="makesqaure mandatorythis"></span>
                                                <span>recommended</span>
                                            </li>
                                            <li>
                                                <span className="makesqaure mandatorythis"></span>
                                                mandatory
                                            </li>
                                            <li>
                                                <span className="makesqaure lockedthis"></span>
                                                locked
                                            </li>
                                        </ul>
                                    </span>
                                </h3>
                            </div>

                            <div className="card-body" style={{ paddingBottom: "32px" }}>
                                <form>
                                    <div className="row first-portion-container">
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "16px",
                                                alignItems: "center"
                                            }}>
                                            <div
                                                className="first-portion-input-one invite-input-one"
                                                style={{ paddingLeft: "16px", color: "#7C868B" }}>
                                                <label
                                                    htmlFor="fieldCategoryName"
                                                    style={{ marginBottom: "4px", fontSize: "0.75rem" }}>
                                                    <Tooltips
                                                        title="Insert the name of the Brand you want to invite to join your group. If the Brand is already in the BOtag network, you'll see it in the dropdown. If not, insert the name of the Brand, complete the rest of the form and your contact person will get the invitation to enroll on BOtag and join your group."
                                                        children={
                                                            <i
                                                                className="fas fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ fontSize: "10px", fontWeight: "500" }}></i>
                                                        }></Tooltips>
                                                    <span style={{ marginLeft: "4px" }}>Brand</span>
                                                </label>

                                                <SearchableDropdown
                                                    options={data || prevBrandData}
                                                    label="name"
                                                    id="id"
                                                    selectedVal={value}
                                                    handleChange={val => {
                                                        setValue(val);
                                                    }}
                                                    handleInputChange={e => {
                                                        setBrandName(e);
                                                    }}
                                                    setLabelId={setSelectedLabelBrand}
                                                    NoOptionsMessage="No Brands Available"
                                                    isLoading={isLoading}
                                                />
                                            </div>
                                            <div
                                                className="first-portion-input-one invite-input-two"
                                                style={{ color: "#7C868B" }}>
                                                <label
                                                    htmlFor="fieldCategoryName"
                                                    style={{ marginBottom: "4px", fontSize: "0.75rem" }}>
                                                    <Tooltips
                                                        title="The contact person can be anyone you know, responsible to take decisions on this Brand. Insert their email here. If your contact person is not the decision maker, no worries, they will be able to redirect the invitation to the right person."
                                                        children={
                                                            <i
                                                                className="fas fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ fontSize: "10px", fontWeight: "500" }}></i>
                                                        }></Tooltips>
                                                    <span style={{ marginLeft: "4px" }}>Contact Email</span>
                                                </label>
                                                <br />
                                                <div>
                                                    <i className="fa-regular fa-envelope email-icon"></i>
                                                    <div className="line"></div>
                                                    <input
                                                        value={email}
                                                        type="email"
                                                        className="invite-email-input"
                                                        onChange={e => {
                                                            setEmail(e.target.value);
                                                        }}
                                                        style={{
                                                            borderLeft:
                                                                email?.trim() !== ""
                                                                    ? "5px solid #141922"
                                                                    : "5px solid #ff9900",
                                                            maxWidth: "100%"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" first-portion-input-one invite-input-two">
                                                <FormSimpleComponent
                                                    titleName="Contact first name"
                                                    titleColor="#7C868B"
                                                    backgroundColor="#10141B"
                                                    border="true"
                                                    inputColor="#00BB00"
                                                    // borderColor="#ff9900"
                                                    width="100%"
                                                    maxWidth="100%"
                                                    iconDisplay="none"
                                                    onChange={e => {
                                                        setFirstName(e.target.value);
                                                    }}
                                                    value={firstName}
                                                    style={{
                                                        borderLeft:
                                                            firstName?.trim() !== ""
                                                                ? "5px solid #141922"
                                                                : "5px solid #ff9900",
                                                        backgroundColor: "#10141B",
                                                        color: "#00BB00",
                                                        maxWidth: "100%",
                                                        width: "100%"
                                                    }}
                                                    tooltipTitle="Insert the first name of the contact person. The contact person can be anyone you know, responsible to take decisions on this Brand. If your contact person is not the decision maker, no worries, they will be able to redirect the invitation to the right person."
                                                />
                                            </div>
                                            <div
                                                className="first-portion-input-one invite-input-two"
                                                style={{ paddingRight: "20px" }}>
                                                <FormSimpleComponent
                                                    titleName="Contact last name"
                                                    titleColor="#7C868B"
                                                    backgroundColor="#10141B"
                                                    border="true"
                                                    inputColor="#00BB00"
                                                    // borderColor="#ff9900"
                                                    width="100%"
                                                    maxWidth="100%"
                                                    iconDisplay="none"
                                                    onChange={e => {
                                                        setLastName(e.target.value);
                                                    }}
                                                    value={lastName}
                                                    style={{
                                                        borderLeft:
                                                            lastName?.trim() !== ""
                                                                ? "5px solid #141922"
                                                                : "5px solid var(--text-icons-blue-500)",
                                                        backgroundColor: "#10141B",
                                                        color: "#00BB00",
                                                        maxWidth: "100%",
                                                        width: "100%"
                                                    }}
                                                    tooltipTitle="Insert the last name of the contact person. The contact person can be anyone you know, responsible to take decisions on this Brand. If your contact person is not the decision maker, no worries, they will be able to redirect the invitation to the right person."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="col-md-12 "
                style={{ backgroundColor: "#1E2631", paddingLeft: "-10px", position: "relative", borderRadius: "4px" }}>
                {confirmPopUp && (
                    <div style={{ position: "absolute", zIndex: "9999999", top: "10px", left: "38%" }}>
                        <FullScreenOverlay
                            children={
                                <DialogBoxComponent
                                    heading="JUST CHECKING..."
                                    headingColor="#00AEEF"
                                    title="Ready To Invite?"
                                    message={`Ready to send an invitation to ${firstName}, inviting their brand ${
                                        value || brandName
                                    } to join the Texcon group?`}
                                    onClickYes={() => {
                                        handleSubmit();
                                        setConfirmPopUp(false);
                                    }}
                                    onClickNo={() => {
                                        setConfirmPopUp(false);
                                    }}
                                    YesBtnColor={true}
                                />
                            }
                        />
                    </div>
                )}
                {inviteLoading ? (
                    <div style={{ position: "absolute", zIndex: "9999999", top: "10px", left: "38%" }}>
                        <FullScreenOverlay
                            children={
                                <DialogBoxComponent
                                    heading="SENDING INVITATION"
                                    headingColor="#00BB00"
                                    loading="true"
                                />
                            }
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div>
                <ListComponentLast10Entries
                    title="Brands"
                    columns={columns}
                    api={{
                        endpoint: "getGroupInvitedLabelBrandsData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID")
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={"BrandsInviteList"}
                    actions={{ includeActions: true }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-blue-600", forValues: ["Active", "Accepted"] },
                            { colorClass: "text-green-500", forValues: ["Invited"] }
                        ]
                    }}
                />
            </div>
        </div>
    );
};

export default Invite;
function setupHeaders(props) {
    props.setControlBarLeftBtns([
        { to: "#", disabled: true, active: false, title: "Orders", icon: "fa-magnifying-glass" }
    ]);
    props.setControlBarCenterBtns([{ to: "/brands", active: true, disabled: false, title: "BRANDS" }]);
    props.setControlBarRightBtns([
        { to: "/brands", disabled: false, title: "View", icon: "eye" },
        { to: "/brands/invite", active: true, disable: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: false,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
