import AccountPageDetails from "../components/pages/account/AccountPageDetails";
import AccountUsersPage from "../components/pages/account/AccountUsersPage";
//import AccountPage from "../components/pages/account/AccountPage";

const routes = [
    /*{
        path: "/account",
        RouteComponent: AccountPage
    },*/
    {
        path: "/account",
        RouteComponent: AccountPageDetails
    },
    {
        path: "/account/users",
        RouteComponent: AccountUsersPage
    }
];

export default routes;
