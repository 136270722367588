import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import InfinityGridCompanyCards from "../../common/cardContainers/InfinityGridCompanyCards";
import "./../../../assets/css/components/CheckboxDark.css";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    &.page-no-scroll {
        overflow-y: hidden;
        height: 100%;
    }
`;
/* #endregion */

export default function CompaniesPage(props) {
    document.title = `${APP_TITLE} - Companies`;

    /* #region STATES */
    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!props?.layoutType) return;
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);
    /* #endregion */

    const listColumns = [
        {
            name: "ID",
            selector: row => row?.ourID ?? "--"
        },
        {
            name: "Company Name",
            selector: row => row?.name ?? "--"
        },
        {
            name: "Registration Nr.",
            selector: row => row?.registrationNumber ?? "--"
        },
        {
            name: "Tax Nr.",
            selector: row => row?.taxNr ?? "--"
        },
        {
            name: "Company Phone",
            selector: row => row?.phoneNr ?? "--"
        },
        {
            name: "Company Address",
            selector: row => row?.address ?? "--"
        },
        {
            name: "Contact Name",
            selector: row => "--" //TODO: row?.contactName ?? "--"
        },
        {
            name: "Contact Mobile",
            selector: row => row?.phoneNr ?? "--"
        },
        {
            name: "Number of Brands",
            selector: row => row?.nrOfBrands ?? "--"
        },
        {
            name: "Number of Users",
            selector: row => row?.nrOfStores ?? "--"
        }
    ];

    return (
        <StyledPage className={viewLayoutStyle === "Grid" ? "page-no-scroll" : ""}>
            {viewLayoutStyle === "Grid" && <InfinityGridCompanyCards minColumnWidth="394px" />}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Companies"
                    columns={listColumns}
                    api={{
                        endpoint: "getCompaniesData",
                        formData: {
                            organizationID: localStorage.getItem("currentOrganizationID")
                        }
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={"CompaniesList"}
                    actions={{
                        includeActions: true,
                        extraActions: [
                            {
                                title: "Details"
                            },
                            {
                                title: "Add Users"
                                /* onClick: () => {
                                    navigate("/companies/users");
                                } */
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-green-500", forValues: ["Invited"] },
                            { colorClass: "text-blue-600", forValues: ["Active"] }
                        ]
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Company", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/companies", active: true, disabled: false, title: "Companies" },
        { to: "/companies/users", active: false, disabled: false, title: "Users" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: false, title: "View", icon: "eye" },
        { to: "#", active: false, disabled: true, title: "Add", icon: "plus" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
}
