import fullfillmentCenters from "../components/pages/fullfillmentCenters/FullfillmentCentersPage";
import FFCStock from "../components/pages/fullfillmentCenters/FFCStock";

const routes = [
    {
        path: "/ffcenters",
        RouteComponent: fullfillmentCenters
    },
    {
        path: "/ffcenters/stock",
        RouteComponent: FFCStock
    },
    {
        path: "/ffcenters/stock/:storeID/:storeName",
        RouteComponent: FFCStock
    }
];

export default routes;
