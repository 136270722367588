import CompaniesPage from "../components/pages/companies/CompaniesPage";
import CompaniesUsersPage from "../components/pages/companies/CompaniesUsersPage";

const routes = [
    {
        path: "/companies",
        RouteComponent: CompaniesPage
    },
    {
        path: "/companies/users",
        RouteComponent: CompaniesUsersPage
    }
];

export default routes;
