import React, { useEffect, useState } from "react";
import InfinityGridFFCsCards from "../../common/cardContainers/InfinityGridFFCsCards";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ListComponent from "../../common/lists/ListComponent";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { AppQueryClient } from "../../../api/queryClient";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: hidden;
        height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
/* #endregion */

export default function FullfillmentCentersPage(props) {
    document.title = `${APP_TITLE} - Fullfillment Centers`;

    /* #region STATES */
    const navigate = useNavigate();
    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Store",
            selector: row => row?.storeName ?? "--"
        },
        {
            name: "BOtag ID",
            selector: row => row?.BOtagID ?? "--"
        },
        {
            name: "Retail Brand",
            selector: row => row?.retailBrand ?? "--"
        },
        {
            name: "FFCenters/In group",
            selector: row => row?.ffcentersInGroup ?? "--"
        },
        {
            name: "Accepted on",
            selector: row => moment(row?.affiliatedOn)?.format("DD/MM/YYYY HH:mm") ?? "--"
        },
        {
            name: "Open now",
            selector: row =>
                row?.isOpen === true ? (
                    <span className="text-green-500">Yes</span>
                ) : row?.isOpen === false ? (
                    <span className="text-red-500">No</span>
                ) : (
                    "--"
                )
        },
        {
            name: "Courier",
            selector: row =>
                row?.courier === true ? (
                    <span className="text-green-500">Yes</span>
                ) : row?.courier === false ? (
                    <span className="text-red-500">No</span>
                ) : (
                    "--"
                )
        },
        {
            name: "Shipping",
            selector: row =>
                row?.shipping === true ? (
                    <span className="text-green-500">Yes</span>
                ) : row?.shipping === false ? (
                    <span className="text-red-500">No</span>
                ) : (
                    "--"
                )
        }
    ];
    /* #endregion */

    return (
        <StyledPage>
            {viewLayoutStyle === "Grid" && <InfinityGridFFCsCards minColumnWidth="238px" limit={12} />}
            {viewLayoutStyle === "List" && (
                <ListComponent
                    title="Fulfilment Centers"
                    columns={listColumns}
                    api={{
                        endpoint: "getFulfillmentCentersData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID")
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={"getFulfillmentCentersData"}
                    actions={{
                        includeActions: true,
                        editIsVisible: false,
                        suspendIsVisible: false,
                        removeIsVisible: false,
                        extraActions: [
                            {
                                title: "Details"
                            },
                            {
                                title: "Stock",
                                onClick: row => {
                                    navigate(`/ffcenters/stock/${row?.storeID}/${row?.storeName}`);
                                }
                            }
                        ]
                    }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-green-500", forValues: ["Invited"] },
                            { colorClass: "text-blue-600", forValues: ["Active", "Fulfilling"] },
                            { colorClass: "text-red-500", forValues: ["Inactive", "Suspended", "Not connected"] }
                        ]
                    }}
                />
            )}
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "FFCenters", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/ffcenters", active: true, disabled: false, title: "FFCENTERS" },
        { to: "/ffcenters/stock", active: false, disabled: false, title: "STOCK" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, disabled: false, title: "View", icon: "eye" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
}
