import { useInfiniteQuery, useMutation } from "react-query";
import { axiosInstance } from "../axios";

export const useGetBrandNameMutation = onSuccess => {
    return useMutation({
        mutationKey: ["get-label-brands"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`getLabelBrandsToInvite`, payload);
            return data;
        },
        onSuccess: onSuccess
    });
};

export const useInviteLabelBrandMutation = onSuccess => {
    return useMutation({
        mutationKey: ["invite-label"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`inviteLabelBrandToGroup`, payload);
            return data;
        },
        onSuccess: onSuccess
    });
};

export const useGetGroupInvitedMutation = () => {
    return useMutation({
        mutationKey: ["invite-label"],
        mutationFn: async payload => {
            const { data, status } = await axiosInstance.post(`getGroupInvitedLabelBrandsData`, payload);
            return { data, status };
        }
    });
};

export const useGetBrandContactInfoMutation = () => {
    return useMutation({
        mutationKey: ["brand-info"],
        mutationFn: async payload => {
            const { data, status } = await axiosInstance.post(`getLabelBrandContactInfo`, payload);
            return { data, status };
        }
    });
};
