import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { QueryClientProvider } from "react-query";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import { AppQueryClient } from "./api/queryClient";
import "./assets/css/Colors.css";
import "./assets/css/UtilityClasses.css";
import NoInternetMarker from "./components/common/NoInternetMarker";
import FullScreenLoader from "./components/common/loaders/FullScreenLoader";
import PreLoader from "./components/common/loaders/PreLoader";
import ControlBar from "./components/common/mainApp/ControlBar";
import FiltersHeader from "./components/common/mainApp/FiltersHeader";
import Footer from "./components/common/mainApp/Footer";
import Header from "./components/common/mainApp/Header";
import NavigationBar from "./components/common/mainApp/NavigationBar";
import AccountRoutes from "./routes/AccountRoutes";
import BrandsRoutes from "./routes/BrandsRoutes";
import CompaniesRoutes from "./routes/CompaniesRoutes";
import OrderRoutes from "./routes/OrderRoutes";
import ProductRoutes from "./routes/ProductRoutes";
import FullfillmentCentersRoutes from "./routes/FulfillmentCentersRoutes";
import StoresRoutes from "./routes/StoresRoutes";
import DashboardRoutes from "./routes/DashboardRoutes";
import * as Library from "./utils/Library";
import PrivateRoute from "./utils/privateRoute";
import PublicRoute from "./utils/publicRoute";
import { setData } from "./utils/redux/userDataSlice";
import { APP_HOMEPAGE_ROUTE } from "./config/constant";
import TermsAndConditions from "./components/common/termsandConditions/TermsAndConditions";
const Login = lazy(() => import("./components/pages/login/LoginPage"));

const { navigationBar } = require("./config/navigationBar");

function App() {
    if (["development", "testing", "production", "staging"].includes(process.env.NODE_ENV)) {
        window.BOLibrary = Library;
    }

    /* #region STATES */
    const ref = useRef();
    const navigate = useNavigate();
    const [showMaster, setShowMaster] = useState(false);
    const [isLoadingApp, setIsLoadingApp] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();

    const [navigationBarMenuBtns, setNavigationBarMenuBtns] = useState([]);
    const [showTAC, setShowTAC] = useState(false);
    const [controlBarLeftBtns, setControlBarLeftBtns] = useState([]);
    const [controlBarCenterBtns, setControlBarCenterBtns] = useState([]);
    const [controlBarRigthBtns, setControlBarRightBtns] = useState([]);

    const [layoutType, setLayoutType] = useState("Grid");

    const [headerCenterBtns, setHeaderCenterBtns] = useState([
        { title: "New Brand", disabled: true, to: "/suppliers/create" },
        { title: "New Store", disabled: true, to: "/members/create" }
    ]);

    const [filterHeaderProperties, setFilterHeaderProperties] = useState({
        visible: true,
        onFilterRefreshAction: undefined,
        onSearch: undefined,
        onRefreshAction: undefined,
        filterOptions: undefined,
        onFilterAction: undefined,
        onResetAction: undefined,
        className: undefined
    });

    /* #endregion */

    /* #region METHODS */
    const getUserDataFunction = (
        id,
        currentCompanyID = undefined,
        currentGroupID = undefined,
        redirectToHomepage = false
    ) => {
        const formData = { token: id, currentCompanyID: currentCompanyID, currentGroupID: currentGroupID };

        Library.makePostRequest("getUserData", formData, false, setIsLoadingApp).then(res => {
            if (res.data.status === 200) {
                setShowMaster(true);
                const data = res.data.data;

                //const status = data?.BOStagesAccount?.status;
                //const billingPlan = data?.currentCompany?.billingPlan;
                const userName =
                    data?.BOStagesAccount?.firstName +
                    (data?.BOStagesAccount?.lastName ? " " + data?.BOStagesAccount?.lastName : "");
                const userEmail = data?.BOStagesAccount?.email;

                const currentCompanyName = data?.currentCompany?.name;
                const currentCompanyID = data?.currentCompany?._id;
                const currentCompanyLogo = data?.currentCompany?.logoURL;
                const currentGroupName = data?.currentGroup?.name;
                const currentGroupID = data?.currentGroup?._id;
                const currentOrganizationID = data?.organization?._id;
                const currentOrganizationName = data?.organization?.name;
                const currentOrganizationLogo = data?.organization?.mainImageUrl;
                const activeBrands = data?.activeBrands;
                const activeStores = data?.activeStores;
                const activeCompanies = data?.activeCompanies;
                const activeFFCenters = data?.activeFFCenters;
                const NDAaccepted = data?.NDAaccepted ?? false;
                const profilePhoto = data?.BOStagesAccount?.profilePhotoUrl;
                const currentProfileRole = data?.organization?.role;
                //const currentProfileRole = data?.currentCompany?.role;

                localStorage.setItem("status", "completed");
                localStorage.setItem("billingPlan", "Basic"); //TODO: temporary hardcoded as basic
                localStorage.setItem("userName", userName);
                localStorage.setItem("userEmail", userEmail);
                localStorage.setItem("currentCompanyName", currentCompanyName);
                localStorage.setItem("currentCompanyID", currentCompanyID);
                localStorage.setItem("currentCompanyLogo", currentCompanyLogo);
                localStorage.setItem("currentGroupName", currentGroupName);
                localStorage.setItem("currentGroupID", currentGroupID);
                localStorage.setItem("currentOrganizationID", currentOrganizationID);
                localStorage.setItem("activeCompanies", activeCompanies ?? "-");
                localStorage.setItem("activeBrands", activeBrands ?? "-");
                localStorage.setItem("activeStores", activeStores ?? "-");
                localStorage.setItem("activeFFCenters", activeFFCenters ?? "-");
                localStorage.setItem("currentOrganizationName", currentOrganizationName ?? "-");
                localStorage.setItem("NDAaccepted", NDAaccepted ?? false);
                if (profilePhoto) localStorage.setItem("currentProfilePhoto", profilePhoto);
                localStorage.setItem("currentProfileRole", currentProfileRole ?? "--");
                localStorage.setItem("currentOrganizationLogo", currentOrganizationLogo);

                //redux save userData
                dispatch(setData({ allUserInfo: data.allUserData, userAppData: data }));

                //redirect to home
                if (redirectToHomepage) {
                    showHeader();
                    navigate(APP_HOMEPAGE_ROUTE);
                }
            } else {
                localStorage.clear();
            }
        });
    };

    const hideHeader = () => {
        setShowMaster(false);
    };

    const showHeader = () => {
        setShowMaster(true);
    };

    const checkUserLoggedIn = () => {
        const auth = localStorage.getItem("token");

        if (!auth) {
            setShowMaster(false);
            hideHeader();
            localStorage.clear();
            navigate("/");
            return;
        }
    };
    /* #endregion */

    /* #region EFFECTS */

    // This useEffect is called when the location changes
    useEffect(() => {
        const path = location.pathname;
        const navigationBarMenuBtns = navigationBar(path);
        setNavigationBarMenuBtns(navigationBarMenuBtns);

        setLayoutType("Grid");

        if (path === "/" && localStorage.getItem("token")) {
            navigate(APP_HOMEPAGE_ROUTE);
        } else if (path !== "/" && !localStorage.getItem("token")) {
            navigate("/");
        }
    }, [location]);

    useEffect(() => {
        if (!localStorage.getItem("token")) return;
        const interval = setInterval(checkUserLoggedIn, 2000);
        return () => clearInterval(interval);
    }, [localStorage.getItem("token")]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getUserDataFunction(
                localStorage.getItem("token"),
                localStorage.getItem("currentCompanyID"),
                localStorage.getItem("currentGroupID")
            );
        }
    }, [dispatch]);
    /* #endregion */

    /* #region ROUTES */
    const AllRoutes = [
        ...OrderRoutes,
        ...ProductRoutes,
        ...FullfillmentCentersRoutes,
        ...BrandsRoutes, // ALSO SUPPLIERS
        ...StoresRoutes, // ALSO MEMBERS
        ...CompaniesRoutes,
        ...AccountRoutes,
        ...DashboardRoutes
    ];

    const MainLayoutProps = {
        setHeaderCenterBtns: setHeaderCenterBtns,
        setControlBarLeftBtns: setControlBarLeftBtns,
        setControlBarCenterBtns: setControlBarCenterBtns,
        setControlBarRightBtns: setControlBarRightBtns,
        setFilterHeaderProperties: setFilterHeaderProperties,
        filterHeaderProperties: filterHeaderProperties,
        getUserDataFunction: getUserDataFunction
    };
    /* #endregion */

    return (
        <>
            <FullScreenLoader isLoading={isLoadingApp}>
                {showTAC && <TermsAndConditions setShowTAC={setShowTAC} />}
                <QueryClientProvider client={AppQueryClient}>
                    {/* process.env.REACT_APP_IS_TESTING && (
                        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
                    ) */}
                    <div className="wrapper" ref={ref} id="myscreen">
                        <NoInternetMarker />

                        <Suspense
                            fallback={
                                <div>
                                    <PreLoader />
                                </div>
                            }>
                            {!showMaster ? (
                                <Routes>
                                    <Route
                                        path="/"
                                        exact={true}
                                        element={
                                            <PublicRoute>
                                                <Login
                                                    showHeader={showHeader}
                                                    getUserDataFunction={getUserDataFunction}
                                                />
                                            </PublicRoute>
                                        }
                                    />
                                    <Route path="*" element={<PrivateRoute></PrivateRoute>} />
                                </Routes>
                            ) : (
                                <div className="main-wrapper">
                                    <div className="navigation-container">
                                        <NavigationBar menuBtns={navigationBarMenuBtns} />
                                    </div>
                                    <div className="main-content">
                                        <div className="header-container">
                                            <Header
                                                centerBtns={headerCenterBtns}
                                                hideHeader={hideHeader}
                                                getUserDataFunction={getUserDataFunction}
                                            />
                                        </div>
                                        <div className="controlbar-container">
                                            <ControlBar
                                                leftBtns={controlBarLeftBtns}
                                                centerBtns={controlBarCenterBtns}
                                                rigthBtns={controlBarRigthBtns}></ControlBar>
                                        </div>
                                        <div className="ml-24px mr-24px">
                                            <FiltersHeader
                                                layoutType={layoutType}
                                                onLayoutTypeChange={setLayoutType}
                                                {...filterHeaderProperties}
                                            />
                                        </div>
                                        <div className="router-container container">
                                            <Routes>
                                                <Route
                                                    path="/"
                                                    element={
                                                        <PublicRoute>
                                                            <Login
                                                                showHeader={showHeader}
                                                                getUserDataFunction={getUserDataFunction}
                                                            />
                                                        </PublicRoute>
                                                    }
                                                    exact={true}
                                                />

                                                {AllRoutes.map(({ path, RouteComponent }) => (
                                                    <Route
                                                        key={uuidv4()}
                                                        path={path}
                                                        element={
                                                            <PrivateRoute>
                                                                <RouteComponent
                                                                    {...MainLayoutProps}
                                                                    layoutType={layoutType}
                                                                />
                                                            </PrivateRoute>
                                                        }
                                                    />
                                                ))}
                                            </Routes>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showMaster ? (
                                <div className="footer-wrapper">
                                    <Footer setShowTAC={setShowTAC} />
                                </div>
                            ) : (
                                ""
                            )}
                        </Suspense>
                    </div>
                </QueryClientProvider>
            </FullScreenLoader>
        </>
    );
}

export default App;
