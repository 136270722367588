import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import iconError from "../../../assets/images/icons-error-48.png";
import errorSuccess from "../../../assets/images/icons8-check-64.png";
import Success from "../../../assets/icons/success.png";

toast.configure();

const NotificationHandler = (
    responseData,
    setSelectedLabelBrand,
    failText,
    successTextHeader,
    successTextBody,
    navigate,
    navigateTo
) => {
    if (responseData.status === 400) {
        toast.error(
            <>
                <div style={{ fontSize: "0.875rem !important", fontWeight: 700, marginBottom: "3px" }}>{failText}</div>
            </>,
            {
                theme: "dark",
                icon: ({ theme, type }) => <img src={iconError} alt="Error" />,
                autoClose: 4000
            }
        );
    } else {
        navigate(navigateTo);
        toast.success(
            <>
                <div style={{ fontSize: "0.875rem !important", fontWeight: 700, marginBottom: "4px" }}>
                    {successTextHeader}
                </div>
                <div style={{ fontSize: "0.75rem !important", fontWeight: 400 }}>{successTextBody}</div>
            </>,
            {
                theme: "dark",
                icon: ({ theme, type }) => <img src={Success} alt="Success" />,
                autoClose: 4000
            }
        );

        setSelectedLabelBrand("");
    }
};

export default NotificationHandler;
