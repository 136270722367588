import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactSelect from "react-select";
import { selectDarkBackgroundStyle } from "../../../utils/utilHelpers";
import { ReactComponent as IconSearch } from "../../../assets/icons/icon-search.svg";
import { useGetGroupOrdersMutation } from "../../../api/orders/orders.query";
import { DatePicker } from "antd";
import OrderCardComponent from "./OrderCard";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import Dropdown from "../../common/dropdown/Dropdown";
import GridCardComponent from "../../common/gridCards/generic/GridCardComponent";
import OrdersGridCard from "../../common/gridCards/OrdersGridCard";
import OrderPreview from "./OrderPreview";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import icon_pos_front_systems from "../../../assets/images/icon_pos_front_systems.png";
import icon_pos_sitoo from "../../../assets/images/icon_pos_sitoo.png";
import icon_pos_traze from "../../../assets/images/icon_pos_traze.png";

const localizer = momentLocalizer(moment);

export default function OrderCalendar(props) {
    const [selectedRange, setSelectedRange] = useState(null);
    const label = new Date();
    const newFormattedDate = new Date().toLocaleDateString("en-US", {
        weekday: "long",
        month: "short",
        day: "2-digit"
    });

    const formattedDate = label.toLocaleString("en-US", {
        month: "long",
        year: "numeric"
    });
    const [originalShowEvents, setOriginalShowEvents] = useState([]);
    const [Month, setMonth] = useState(label?.getMonth());
    const [showEvents, setShowEvents] = useState([]);
    const { mutate: getData, data, isLoading } = useGetGroupOrdersMutation();
    const [activeLabel, setActiveLabel] = useState(formattedDate);
    const [activeDate, setActiveDate] = useState();
    const [filter, setFilter] = useState("");
    const [shorOrderPreview, setShowOrderPreview] = useState(false);
    const [orderDetails, setOrderDetails] = useState();

    const events = data?.data;

    const fetchData = month => {
        const formData = {
            companyID: localStorage.getItem("currentCompanyID"),
            month: month
        };
        getData(formData);
    };

    useEffect(() => {
        fetchData(Month);
    }, [Month]);

    const [date, setDate] = useState(newFormattedDate);

    useEffect(() => {
        if (!props?.layoutType) return;
        setupHeaders(props);
    }, [props?.layoutType]);

    const [currentView, setCurrentView] = useState("month");

    const { defaultDate, formats } = useMemo(
        () => ({
            defaultDate: new Date(),
            formats: {
                dateFormat: (date, culture, localizer) => localizer.format(date, "MMMM D", culture),
                weekdayFormat: (date, culture, localizer) => localizer.format(date, "dddd", culture)
            }
        }),
        []
    );

    const truncateString = (inputString, maxLength) => {
        const screenWidth = window.innerWidth;
        if (inputString.length <= maxLength || screenWidth >= 1340) {
            return inputString;
        } else {
            return `${inputString.substring(0, maxLength)}...`;
        }
    };

    const handleChangeView = view => {
        setCurrentView(view);
    };
    const isMobile = window.innerWidth <= 992;
    const formatPrice = price => {
        return new Intl.NumberFormat("en-US", { style: "decimal" }).format(price).replace(/,/g, ".");
    };

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isMobile ? "85%" : "calc(100% - 440px)",
        bgcolor: "#1C2530",
        border: "2px solid #000",
        boxShadow: 24,
        height: "90%",
        p: 4,
        overflow: "scroll",
        padding: "0px 32px"
    };
    const handleShowOrderPreview = item => {
        setOrderDetails(item);
        setShowOrderPreview(true);
    };

    const MyMonthEvent = ({ event }) => {
        const start = event?.start;
        const selectedDate = new Date(start);
        const dayOfWeek = new Intl.DateTimeFormat("en", { weekday: "long" }).format(selectedDate);
        const monthAndDay = new Intl.DateTimeFormat("en", { month: "short", day: "2-digit" }).format(selectedDate);
        const completeDate = dayOfWeek + ",  " + monthAndDay;
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "#00AEEF",
                    backgroundColor: "#0060cc1a",
                    padding: "1px 8px",
                    borderRadius: "5px"
                }}
                onClick={() => {
                    dayPropGetter();
                    setShowEvents([event]);
                    setOriginalShowEvents([events]);
                    setDate(completeDate);
                }}>
                <span style={{ fontSize: "0.75rem" }}>{truncateString(event?.botagID, 6)}</span>{" "}
                <span style={{ fontSize: "0.75rem" }}>{formatPrice(event?.totalPrice)} kr</span>
            </div>
        );
    };

    const MyMonthDateHeader = ({ label }) => {
        const searchDate = new Date(label);
        const foundEvent = events?.find(
            event => event?.day === searchDate?.getDate() && event?.month === searchDate?.getMonth() + 1
        );

        return (
            <div style={{ color: "white", fontSize: "12px", fontWeight: "400" }}>
                {label} <br />
                <span
                    style={{
                        fontSize: "10px",
                        fontWeight: "400"
                    }}>
                    {`${foundEvent?.dayOrders || 0} Orders  / Total ${
                        foundEvent?.totalDayAmount ? formatPrice(foundEvent?.totalDayAmount) : 0
                    } kr`}
                </span>
            </div>
        );
    };
    const handleNavigate = (newDate, view) => {
        setSelectedRange(null);
        const date = new Date(view);

        setActiveDate(newDate);

        const formattedDate = date?.toLocaleString("en-US", {
            month: "long",
            year: "numeric"
        });

        const newFormattedDate = date?.toLocaleDateString("en-US", {
            weekday: "long",
            month: "short",
            day: "2-digit"
        });
        setDate(newFormattedDate);
        setActiveLabel(formattedDate);
        setShowEvents([]);
    };
    const handleCalenderNavigate = newDate => {
        setSelectedRange(null);
        const date = new Date(newDate);

        const formattedDate = date?.toLocaleString("en-US", {
            month: "long",
            year: "numeric"
        });

        setActiveLabel(formattedDate);
        const newFormattedDate = date?.toLocaleDateString("en-US", {
            weekday: "long",
            month: "short",
            day: "2-digit"
        });
        setDate(newFormattedDate);
        setActiveLabel(formattedDate);
        setShowEvents([]);
    };
    const Toolbar = toolbar => {
        const goToBack = () => {
            toolbar.onNavigate("PREV");
        };

        const goToNext = () => {
            toolbar.onNavigate("NEXT");
        };
        setActiveLabel(toolbar?.label);
        const monthNumber = toolbar?.date?.getMonth() + 1;
        setMonth(monthNumber);

        return (
            <div className="toolbar-container">
                <div className="first-portion" style={{ display: "flex" }}>
                    <div className="toolbar-element-one-container">
                        <span onClick={goToBack} className="calendar-back-btn">
                            <i class="fa-solid fa-angle-left" style={{ color: "white", fontSize: "12px" }}></i>
                        </span>
                        <span style={{ color: "#FFFFFF", position: "relative" }} className="calendar-toolbar-label">
                            {activeLabel}
                            <DatePicker
                                onChange={handleNavigate}
                                picker="month"
                                value={activeDate}
                                popupStyle={{
                                    position: "absolute",
                                    left: "240px"
                                }}
                                popupClassName="date-popup"
                                style={{ opacity: "0", position: "absolute", right: "0px", left: "0px" }}
                                allowClear={false}
                            />
                            <span>
                                <i
                                    className="fa-solid fa-angle-down"
                                    style={{ color: "#FFFFFF", paddingLeft: "8px", fontSize: "12px" }}></i>
                            </span>
                        </span>
                        <span onClick={goToNext} className="calendar-next-btn">
                            <i class="fa-solid fa-angle-right" style={{ color: "white", fontSize: "12px" }}></i>
                        </span>
                    </div>
                    <div className="toollbar-element-two-container">
                        <p style={{ marginTop: "1px" }}>Total: {formatPrice(data?.monthTotalPrice) || 0} kr</p>
                    </div>
                    <div className="toollbar-element-three-container">
                        <p style={{ color: "#00AEEF", marginTop: "1px" }}>
                            <i className="fa-regular fa-file-lines"></i> &nbsp;{" "}
                            <span style={{ fontSize: "12px", fontWeight: "700" }}>{data?.monthOrders || 0}</span>
                        </p>
                    </div>
                    <div className="toollbar-element-four-container">
                        <p style={{ color: "#00BB00", marginTop: "1px" }}>
                            <i className="fa-solid fa-check"></i> &nbsp;
                            <span style={{ fontSize: "12px", fontWeight: "700" }}>0</span>
                        </p>
                    </div>
                    <div className="toollbar-element-five-container">
                        <p style={{ color: "#FF3636", marginTop: "1px" }}>
                            <i className="fa-regular fa-clock"></i> &nbsp;{" "}
                            <span style={{ fontSize: "12px", fontWeight: "700" }}>0</span>
                        </p>
                    </div>
                </div>
                <div
                    className="portion-two"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="view-dropdown">
                        {/* <select
                            className="menuDropDown"
                            // id="view-select"
                            value={currentView}
                            onChange={e => handleChangeView(e.target.value)}>
                            <option value="day" disabled={true} className="actionOption">
                                Day
                            </option>
                            <option disabled={true} value="week" className="actionOption">
                                Week
                            </option>
                            <option>Month</option>
                            <option value="year" disabled={true} className="actionOption">
                                Year
                            </option>
                        </select> */}
                        <Dropdown />
                    </div>
                    &nbsp;&nbsp;
                    <div
                        className={"filter-section flex-col  justify-content-end "}
                        style={{
                            padding: "15px 0px"
                        }}>
                        <p style={{ margin: "0px", fontSize: "12px", padding: "0px" }}>Filters: &nbsp;</p>

                        <ReactSelect
                            className={"selects disabled"}
                            isDisabled={true}
                            styles={selectDarkBackgroundStyle}
                            placeholder={<div className="italic-placeholder">Select...</div>}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const MyShowMore = (total, event, backgroundEvent) => {
        const start = event?.[0]?.start;
        const selectedDate = new Date(start);
        const dayOfWeek = new Intl.DateTimeFormat("en", { weekday: "long" }).format(selectedDate);
        const monthAndDay = new Intl.DateTimeFormat("en", { month: "short", day: "2-digit" }).format(selectedDate);
        const completeDate = dayOfWeek + ", " + monthAndDay;

        return (
            <div
                className="show-more-btn"
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setDate(completeDate);
                    setShowEvents(backgroundEvent);
                    setOriginalShowEvents(backgroundEvent);
                }}>
                <div>more...</div>

                <div>{total} more...</div>
            </div>
        );
    };
    const handleSelectSlot = slotInfo => {
        setSelectedRange({ start: slotInfo.start, end: slotInfo.start });
        const { start, end } = slotInfo;
        const selectedDate = new Date(start);
        const dayOfWeek = new Intl.DateTimeFormat("en", { weekday: "long" }).format(selectedDate);
        const monthAndDay = new Intl.DateTimeFormat("en", { month: "short", day: "2-digit" }).format(selectedDate);
        const completeDate = dayOfWeek + ", " + monthAndDay;
        setDate(completeDate);
        const selectedEvents = getEvents(events, start, end);
        setShowEvents(selectedEvents);
        setOriginalShowEvents(selectedEvents);
    };

    const getEvents = (allEvents, start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);

        return allEvents?.filter(event => {
            const eventStartDate = new Date(event?.start);
            const eventEndDate = new Date(event?.end);

            return eventStartDate >= startDate && eventEndDate <= endDate;
        });
    };

    const dayPropGetter = useCallback(
        date => {
            if (!selectedRange) {
                return {
                    className: ""
                };
            } else if (selectedRange && date >= selectedRange.start && date <= selectedRange.end) {
                return {
                    className: "selected-day"
                };
            } else {
                return {};
            }
        },
        [selectedRange, activeLabel]
    );

    function getRandomImage(inputNumber) {
        const result = inputNumber % 2;
        const resultTwo = inputNumber % 3;
        const images = [icon_pos_front_systems, icon_pos_sitoo, icon_pos_traze];
        if (result === resultTwo) {
            return images[0];
        } else if (result === 0) {
            return images[1];
        } else {
            return images[2];
        }
    }

    return (
        <>
            {isLoading && !data ? (
                // <PreLoader></PreLoader>
                <ContainerLoader
                    className="calender-loader"
                    isLoading={isLoading || !data === 0}
                    isLoadingOverlay={true}
                    addBlurFilters={false}
                />
            ) : (
                <div style={{ height: "100%" }} className="calendar-component-wrapper">
                    <div style={{ height: "100%", paddingBottom: "21px", width: "100%" }}>
                        <Calendar
                            onNavigate={handleCalenderNavigate}
                            date={activeLabel ? new Date(activeLabel) : new Date()}
                            localizer={localizer}
                            events={events}
                            toolbar={true}
                            view={currentView}
                            selectable={true}
                            onSelectSlot={handleSelectSlot}
                            dayPropGetter={dayPropGetter}
                            views={{
                                day: true,
                                week: true,
                                month: true
                                // year: Year
                            }}
                            components={{
                                month: {
                                    dateHeader: MyMonthDateHeader,
                                    event: MyMonthEvent
                                },
                                toolbar: Toolbar
                            }}
                            formats={formats}
                            defaultDate={newFormattedDate}
                            messages={{
                                showMore: MyShowMore
                            }}
                        />
                    </div>

                    <div className="orders-container">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                backgroundColor: "#141922",
                                position: "fixed",
                                zIndex: "9",
                                padding: "3px 3px 9px 3px"
                            }}>
                            <div
                                style={{
                                    fontSize: "20px",
                                    color: "white",
                                    textAlign: "center",
                                    width: "100%",
                                    marginTop: "16px"
                                }}>
                                {date}
                            </div>
                            <div style={{ color: "#00AEEF", width: "100%", textAlign: "center", fontSize: "10px" }}>
                                {`${showEvents?.length || 0} Orders  / Total ${
                                    showEvents?.[0]?.totalDayAmount ? formatPrice(showEvents?.[0]?.totalDayAmount) : 0
                                } kr`}
                                {/* / QTY ${
                                    showEvents?.[0]?.totalDayQuantity || 0
                                } */}
                            </div>
                            <div style={{ display: "flex", width: "275px", margin: "8px 0px 0px 0px" }}>
                                <input
                                    className="form-control"
                                    style={{
                                        marginRight: "8px",
                                        backgroundColor: "#1E2631",
                                        border: "none",
                                        outline: "none"
                                    }}
                                    type="text"
                                    placeholder="Search orders e.g. #35000"
                                    aria-label="Search"
                                    onChange={event => {
                                        const searchTerm = event.target.value.toLowerCase();
                                        setFilter(searchTerm);
                                    }}
                                />
                                <span className={"filter-btn-with-label"}>
                                    <button
                                        type="button"
                                        className={"btn btn-success filter-btn"}
                                        onClick={() => {}}
                                        style={{ width: "40px" }}>
                                        <IconSearch width={"16px"} />
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div style={{ marginTop: "139px", display: "flex", gap: "8px", flexDirection: "column" }}>
                            {showEvents
                                ?.filter(item => item?.botagID.toLowerCase().includes(filter))
                                ?.map((item, i) => {
                                    return (
                                        <OrdersGridCard
                                            hideImage={true}
                                            className="calendar-card-component"
                                            image={item?.logoURL}
                                            title={item?.orderNR}
                                            posLogo={getRandomImage(i)}
                                            id={item?.botagID}
                                            isCardEnabled={item?.status?.toLowerCase() !== "suspended"}
                                            isCardSuspended={item?.status?.toLowerCase() === "suspended"}
                                            data={{
                                                fromBrand: item?.fromBrand,
                                                toStore: item?.toStore,
                                                total: item?.totalPrice ? formatPrice(item?.totalPrice) + " kr" : "--",
                                                status: item?.status
                                            }}
                                            primaryButton={{
                                                title: props?.primaryButton?.title ?? "Preview",
                                                onClick: item => {
                                                    handleShowOrderPreview(item);
                                                }
                                            }}
                                            cardData={item}
                                        />
                                    );
                                })}
                        </div>
                        <Modal
                            open={shorOrderPreview}
                            onClose={() => setShowOrderPreview(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                            <Box sx={modalStyle}>
                                <OrderPreview
                                    onCloseClick={() => setShowOrderPreview(false)}
                                    orderDetails={orderDetails}
                                />
                            </Box>
                        </Modal>
                    </div>
                </div>
            )}
        </>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([
        { to: "#", disabled: true, active: false, title: "Orders", icon: "fa-magnifying-glass" }
    ]);
    props.setControlBarCenterBtns([{ to: "/orders", active: true, disabled: false, title: "ORDERS" }]);
    props.setControlBarRightBtns([
        { to: "/orders", disabled: false, title: "View", icon: "eye" },
        { to: "/orders/calender", active: true, disable: false, title: "Calender", icon: "fa-calendar" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: false,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
