import { memo, useState, useRef } from "react";
import { useDrop } from "react-dnd";

const style = {
    height: "12rem",
    width: "12rem",
    marginRight: "1.5rem",
    marginBottom: "1.5rem",
    color: "white",
    padding: "1rem",
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "normal",
    float: "left"
};

const newStyle = {};

/**
 * Represents a Dustbin component.
 * @param {string} accept - The accepted item type for dropping.
 * @param {Object} lastDroppedItem - The last dropped item.
 * @param {function} onDrop - The callback function when an item is dropped.
 * @param {string} label - The label for the Dustbin.
 * @param {function} clear - The callback function to clear the Dustbin.
 * @param {function} arrData - The callback function to update the array data.
 * @param {boolean} valids - The validity status of the Dustbin.
 * @param {boolean} isMandatory - The mandatory status of the Dustbin.
 * @returns {JSX.Element} The Dustbin component.
 */
export const Dustbin = memo(function Dustbin({
    accept,
    lastDroppedItem,
    onDrop,
    label,
    clear,
    arrData,
    valids,
    isMandatory
}) {
    //console.log("onDrop",onDrop);
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });
    const [ent, setEnt] = useState(true);
    const disallow = useRef();
    const isActive = isOver && canDrop;
    const backgroundColor = "#222";

    if (lastDroppedItem && ent) {
        arrData(label, lastDroppedItem.name);
    }

    const clearIt = ele => {
        setEnt(false);
    };

    if (valids === false) {
        newStyle["borderColor"] = " ";
        newStyle["borderWidth"] = " ";
    }
    if (valids === true) {
        newStyle["borderColor"] = "red";
        newStyle["borderWidth"] = "1px";
    }

    if (isActive) {
        newStyle["borderColor"] = " ";
        newStyle["borderWidth"] = " ";
    } else if (canDrop) {
        newStyle["borderColor"] = "var(--BOStageG-Red-Outline)";
        newStyle["borderWidth"] = "1px";
    }

    return (
        <div ref={lastDroppedItem && ent ? disallow : drop} className="products-col">
            <div className="form-group">
                {lastDroppedItem && ent ? (
                    <>
                        <div className="perfom-input active">
                            {lastDroppedItem.name}
                            <span className="removethis-entry">
                                <i
                                    className="fa fa-times pts"
                                    aria-hidden="true"
                                    onClick={() => {
                                        clear(lastDroppedItem.name, label);
                                        clearIt(lastDroppedItem.name);
                                    }}></i>
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={!isMandatory ? "perfom-input non-mondate" : "perfom-input blank"}
                            onDrop={() => {
                                setEnt(true);
                            }}
                            style={{ ...newStyle, newStyle }}>
                            Drop <span className="draggerdfield">{label}</span> field here
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});
