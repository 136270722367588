import React from "react";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .card-counter {
        width: 100%;
        height: 181.5px;
        background: var(--backgrounds-lines-n-300);
        padding: 16px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;

        border-radius: 4px;
        border: 1px solid var(--backgrounds-lines-n-100);
    }

    .card-number {
        color: var(--text-icons-blue-500);
        font-family: Roboto;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.6px;
    }

    .card-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .card-title span {
        flex: 1 0 0;
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Heading 4 */
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;

        // one line wrap
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card-button {
        display: flex;
        width: 40px;
        height: 37px;
        justify-content: center;
        align-items: center;
    }

    .Frame-29 {
        display: flex;
        width: 40px;
        height: 37px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;

        border-radius: 4px;
        border: 1px solid var(--backgrounds-lines-n-100, #3c485a);
        background: var(--backgrounds-lines-n-300, #1a222d);
    }

    .card-button.cursor-pointer .Frame-29:hover {
        border: 1px solid var(--Buttons-Red-Hover);
    }
`;
/* #endregion */

/**
 * Renders a card component with a counter and title.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - Additional CSS class name for the component.
 * @param {number} props.total - The total number to be displayed in the counter.
 * @param {string} props.title - The title to be displayed in the card.
 * @param {Object} props.addButton - The title to be displayed in the card.
 * @param {boolean} props.addButton.visible - Indicates if the add button is visible.
 * @param {function} props.addButton.onClick - The function to be called when the add button is clicked.
 * @returns {JSX.Element} The rendered CardCounter component.
 */
export default function CardCounter(props) {
    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className="card-counter">
                <div className="card-number">
                    <span>{props?.total || "--"}</span>
                </div>

                <div className="card-title">
                    <span>{props?.title || "--"}</span>

                    <div
                        className={`card-button ${
                            props?.addButton?.onClick
                                ? "cursor-pointer text-gray-100"
                                : "hover-not-allowed text-gray-300"
                        } ${props?.addButton?.visible ? "visible" : "invisible"}`}
                        onClick={props?.addButton?.onClick}>
                        <div class="Frame-29">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none">
                                <path
                                    d="M9.00098 3.83341C9.00098 3.46522 8.7025 3.16675 8.33431 3.16675C7.96612 3.16675 7.66764 3.46522 7.66764 3.83341L7.66764 7.83341L3.66764 7.83341C3.29945 7.83341 3.00098 8.13189 3.00098 8.50008C3.00098 8.86827 3.29945 9.16675 3.66764 9.16675H7.66764L7.66764 13.1667C7.66764 13.5349 7.96612 13.8334 8.33431 13.8334C8.7025 13.8334 9.00098 13.5349 9.00098 13.1667L9.00098 9.16675L13.001 9.16675C13.3692 9.16675 13.6676 8.86827 13.6676 8.50008C13.6676 8.13189 13.3692 7.83341 13.001 7.83341L9.00098 7.83341V3.83341Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
