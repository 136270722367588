import OrderCalendar from "../components/pages/orders/OrderCalendar";
import OrderList from "../components/pages/orders/OrderList";
import OrdersPage from "../components/pages/orders/OrdersPage";

const routes = [
    {
        path: "/orders",
        RouteComponent: OrdersPage
    },
    {
        path: "/orders/calender",
        RouteComponent: OrderCalendar
    }
];

export default routes;
