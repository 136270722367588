import React from "react";
import { Dropdown, Space } from "antd";
const items = [
    {
        label: "Month",
        key: "0"
    },
    {
        label: "Day",
        key: "1",
        disabled: true
    },

    {
        label: "Week",
        key: "3",
        disabled: true
    },
    {
        label: "Year",
        key: "4",
        disabled: true
    }
];
const App = () => (
    <Dropdown
        menu={{
            items
        }}
        trigger={["click"]}>
        <a onClick={e => e.preventDefault()}>
            <Space>Month</Space>
            <i
                class="fa-light fa-chevron-down"
                style={{
                    position: "absolute",
                    color: "#ffffff",
                    left: "94px",
                    top: "10px",
                    fontSize: "12px",
                    color: "#ffffff",
                    fontWeight: 900
                }}></i>
        </a>
    </Dropdown>
);
export default App;
