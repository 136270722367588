import Pencil01 from "@untitled-ui/icons-react/build/esm/Pencil01";
import React from "react";
import styled from "styled-components";
import noImage from "../../../assets/images/common/no-image-placeholder.png";
import tagIcon from "../../../assets/images/tagIcon.png";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        border-radius: 10px;
    }

    .BO-grid-card-component {
        min-height: 100px;
        padding: 8px;
        background: var(--backgrounds-lines-n-300);
        border-radius: 4px;
        border: 0px solid var(--backgrounds-lines-n-200);
    }

    .BO-grid-card-header {
        display: flex;
        padding: 0px;
        color: var(--text-icons-gray-100);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
        text-transform: uppercase;
    }

    .BO-grid-card-image {
        display: flex;
        height: 465px;
        //object-fit: cover;
        align-items: stretch;
        min-width: 0;
        width: 100%;
        border-radius: 4px;
    }

    .profile-image {
        width: 174px;
        height: 174px;
        min-width: unset !important;
        flex-basis: auto;
    }

    hr {
        border-top: 1px solid var(--backgrounds-lines-n-200);
        margin-top: 16px;
        margin-bottom: 24px;
    }

    .BO-grid-card-body {
        padding: 16px 0 0 0;
        color: var(--text-icons-gray-300);
        display: block;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }

    .card-small-btns > img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
    }

    .BO-grid-card-buttons button {
        margin-top: 8px;
        width: 100%;
    }

    .hr--small {
        width: 100%;
        border-top: 1px solid var(--backgrounds-lines-n-200);
        margin: 0px;
        margin-bottom: 8px;
    }

    .details-separator {
        padding: 16px 0px;
        padding-bottom: 8px;
    }

    .card-enabled {
        color: var(--text-icons-gray-100);
        border-color: var(--text-icons-gray-100);
    }
    .span-text {
        color: var(--text-icons-blue-500);
    }
    .description-text {
        color: var(--text-icons-gray-100);
    }
    .description-text:first-child {
        margin-bottom: 8px;
        font-size: 10px;
        letter-spacing: 0.125px;
    }
    .description-one {
        font-size: 10px;
        font-weight: 500;
    }
    .details {
        font-weight: 500;
        font-size: 8px !important;
    }
    .brand-reference {
        font-size: 10px;
        margin-bottom: 8px;
        color: "red" !important;
    }
    .pencil {
        cursor: not-allowed;
    }

    .tag-icon {
        height: 50px;
        width: 50px;
        position: absolute;
        top: 16px;
        right: 16px;
    }
`;
/* #endregion */

export default function OrderCardComponent(props) {
    const renderValue = (value, key) => {
        if (value?.length && key === "Colors") {
            value.map((val, index) => {
                if (index < 4) {
                    return (
                        <div
                            style={{
                                backgroundColor: `#${val}`,
                                height: "16px",
                                width: "16px",
                                borderRadius: 2
                            }}></div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                backgroundColor: "var(--backgrounds-lines-n-500)",
                                height: "16px",
                                width: "16px",
                                borderRadius: 2
                            }}>
                            +{value?.length - index}
                        </div>
                    );
                }
            });
        }
    };

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div
                className="BO-grid-card-component"
                style={{ position: "relative", minWidth: "255px", width: props.width, margin: props.margin }}>
                {props.image && <img src={tagIcon} className="tag-icon" />}

                <div className="BO-grid-card-header flex-row">
                    {props.image && (
                        <img
                            className="BO-grid-card-image flex-col flex-1"
                            src={props?.image || noImage}
                            alt={props?.title || "Grid main image"}
                            style={{ objectFit: props?.image ? "cover" : "cover" }}
                        />
                    )}
                </div>

                <div className="BO-grid-card-body" style={{ padding: props.padding }}>
                    <div>
                        <div
                            className="BO-grid-card-title"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start"
                            }}>
                            <div>
                                <div className={"description-text one"}>
                                    {props?.orderData ? "ORDER NR.:" : "STYLE NAME:"}{" "}
                                    <span className="span-text">{props?.title || "--"}</span>
                                </div>
                                <div className={"description-text"}>
                                    BOTAG ID: <span className="span-text">{props?.id || "--"}</span>
                                </div>
                            </div>
                            {!props.hideIcon && (
                                <div className="float-right card-small-btns align-items-center">
                                    <Pencil01 height="16px" className={"default-color--green pencil"} />
                                </div>
                            )}
                        </div>

                        <div className={"details-separator flex-row"}>
                            <div className="flex-col flex-basis-auto pr-10px description-text details">DETAILS</div>
                            <div className="flex-col flex-1">
                                {" "}
                                <hr className={"hr--small"} />{" "}
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div>
                    {props?.values?.map((item, i) => {
                        return (
                            <div
                                key={item + i}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                <div className={"description-text"}>{item?.key}:</div>
                                {item?.key === "Colors" ? (
                                    <div style={{ display: "flex", gap: "5px" }}>
                                        {item?.value?.map((val, i) => {
                                            return (
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            i < 4 ? `#${val}` : "var(--backgrounds-lines-n-500)",
                                                        height: "16px",
                                                        width: "16px",
                                                        borderRadius: 2,
                                                        color: "white",
                                                        display: i > 4 ? "none" : "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        fontSize: "8px"
                                                    }}>
                                                    {i < 4 ? "" : `+${item?.value?.length - i}`}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : item?.key === "Sizes" ? (
                                    <div style={{ display: "flex", gap: "5px" }}>
                                        {item?.value?.map((val, i) => {
                                            return (
                                                <div
                                                    style={{
                                                        backgroundColor: "var(--backgrounds-lines-n-500)",
                                                        height: "16px",
                                                        width: "16px",
                                                        borderRadius: 2,
                                                        color: "var(--text-icons-blue-500)",
                                                        display: i > 4 ? "none" : "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        fontSize: "8px"
                                                    }}>
                                                    {i < 4 ? (
                                                        val
                                                    ) : (
                                                        <span className="text-gray-100">{`+${
                                                            item?.value?.length - i
                                                        }`}</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <span
                                        className={"span-text brand-reference"}
                                        style={{
                                            color:
                                                i === props?.values?.length - 1 ? "var(--Buttons-Orange-Outline)" : ""
                                        }}>
                                        {item.value || "--"}
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className="BO-grid-card-buttons">
                    <div className="flex-row">
                        {props?.primaryButton && (
                            <div className="flex-col flex-1" style={{ width: "100%" }}>
                                <button
                                    type="button"
                                    className={`btn BO-main-btn ${props?.primaryButton?.onClick ? "" : "disabled"}`}>
                                    {props?.primaryButton?.title || "--"}
                                </button>
                            </div>
                        )}

                        {props?.secondaryButton && (
                            <div className="flex-col flex-1" style={{ width: "100%" }}>
                                <button
                                    type="button"
                                    className={`btn BO-main-btn ${props?.primaryButton?.onClick ? "" : "disabled"}`}>
                                    {props?.secondaryButton?.title || "--"}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}

function createDetails(array, isLeft) {
    const length = array.length;
    const halfCount = isLeft ? Math.ceil(length / 2) : Math.floor(length / 2);
    const startIndex = isLeft ? 0 : length - halfCount;
    const endIndex = isLeft ? halfCount : length;

    const result = [];
    for (let i = startIndex; i < endIndex; i++) {
        result.push(array[i]);
    }
    return result;
}
