import React from "react";
import { Menu } from "antd";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const StyledMenu = styled.div`
    .ant-dropdown-menu {
        padding: 8px;
        border-radius: 4px;
        background: var(--backgrounds-lines-n-500, #141922);

        /* drop-shadow-100 */
        box-shadow: 0px 0px 10px 0px #111620;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item:hover {
        background: var(--backgrounds-lines-n-400) !important;
    }

    .menuDropDown {
        background: var(--backgrounds-lines-n-500);
        width: 130px;
        z-index: 999999;
        position: static;
    }

    .ant-dropdown-menu-item {
        height: 32px;
    }

    .menuDropDown .line-container {
        display: flex;
        align-items: center;
        color: var(--text-icons-gray-100);
        font-size: 0.75rem;
        justify-content: flex-start;
    }

    .ant-dropdown-menu-item {
        padding: 8px !important;
    }
`;

/**
 * Renders a dropdown menu for table list actions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.tableRowData - The data beloging to the table row.
 * @param {Object} props.edit - The edit action object.
 * @param {Boolean} props.edit.visible - The flag to enable or disable the edit action.
 * @param {Function} props.edit.onClick - The onClick event handler for the edit action.
 * @param {Object} props.suspend - The suspend action object.
 * @param {Boolean} props.suspend.visible - The flag to enable or disable the suspend action.
 * @param {Function} props.suspend.onClick - The onClick event handler for the suspend action.
 * @param {Boolean} props.suspend.isReenable - The flag to enable or disable the reenable action.
 * @param {Object} props.remove - The remove action object.
 * @param {Boolean} props.remove.visible - The flag to enable or disable the remove action.
 * @param {Function} props.remove.onClick - The onClick event handler for the remove action.
 * @param {Array} props.extraActions - The extra actions array.
 * @param {Object} props.extraActions.icon - The extra action icon.
 * @param {String} props.extraActions.title - The extra action title.
 * @param {Function} props.extraActions.onClick - The onClick event handler for the extra action.
 *
 * @returns {JSX.Element} The rendered TableListActionDropdown component.
 */
export default function TableListActionDropdown(props) {
    return (
        <StyledMenu>
            <Menu className="menuDropDown" width={130}>
                {props?.edit?.visible && (
                    <Menu.Item className={"actionOption"} key="edit" onClick={props?.edit?.onClick}>
                        <div
                            className={`line-container ${
                                props?.edit?.onClick ? "text-gray-100" : "text-gray-300 hover-not-allowed"
                            } `}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none">
                                <path
                                    d="M1.66664 14.3334L5.36615 12.9105C5.60278 12.8195 5.72109 12.774 5.83179 12.7146C5.93011 12.6618 6.02384 12.6009 6.112 12.5324C6.21126 12.4554 6.30089 12.3658 6.48016 12.1865L14 4.66671C14.7364 3.93033 14.7364 2.73642 14 2.00004C13.2636 1.26366 12.0697 1.26366 11.3333 2.00004L3.8135 9.51985C3.63423 9.69912 3.54459 9.78876 3.46757 9.88801C3.39916 9.97617 3.33824 10.0699 3.28546 10.1682C3.22605 10.2789 3.18054 10.3972 3.08953 10.6339L1.66664 14.3334ZM1.66664 14.3334L3.03872 10.766C3.13691 10.5107 3.186 10.3831 3.2702 10.3246C3.34379 10.2735 3.43484 10.2542 3.52284 10.271C3.62353 10.2902 3.72023 10.3869 3.91363 10.5803L5.41969 12.0864C5.61309 12.2798 5.70979 12.3765 5.72902 12.4772C5.74582 12.5652 5.7265 12.6562 5.6754 12.7298C5.61694 12.814 5.4893 12.8631 5.23402 12.9613L1.66664 14.3334Z"
                                    stroke={`${
                                        props?.edit?.onClick
                                            ? "var(--text-icons-blue-500)"
                                            : "var(--text-icons-gray-300)"
                                    }`}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="ml-8px">Edit</div>
                        </div>
                    </Menu.Item>
                )}

                {props?.suspend?.visible && (
                    <Menu.Item className={"actionOption"} key="suspend" onClick={props?.suspend?.onClick}>
                        <div
                            className={`line-container ${
                                props?.suspend?.onClick ? "text-gray-100" : "text-gray-300 hover-not-allowed"
                            }`}>
                            {props?.suspend?.isReenable !== true ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M9.33334 2.33325V7.33325V2.99992C9.33334 2.44763 9.78105 1.99992 10.3333 1.99992C10.8856 1.99992 11.3333 2.44763 11.3333 2.99992V7.33325V4.99992C11.3333 4.44763 11.7811 3.99992 12.3333 3.99992C12.8856 3.99992 13.3333 4.44763 13.3333 4.99992V10.6666C13.3333 12.8757 11.5425 14.6666 9.33334 14.6666H8.58179C7.56555 14.6666 6.58741 14.2798 5.84603 13.5847L2.5403 10.4856C2.06243 10.0376 2.05026 9.28299 2.51344 8.81981C2.96626 8.367 3.70042 8.367 4.15323 8.81981L5.33334 9.99992V4.33325C5.33334 3.78097 5.78105 3.33325 6.33334 3.33325C6.88562 3.33325 7.33334 3.78097 7.33334 4.33325V7.33325V2.33325C7.33334 1.78097 7.78105 1.33325 8.33334 1.33325C8.88562 1.33325 9.33334 1.78097 9.33334 2.33325Z"
                                        stroke={`${
                                            props?.suspend?.onClick
                                                ? "var(--text-icons-orange-500)"
                                                : "var(--text-icons-gray-300)"
                                        }`}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none">
                                    <path
                                        d="M4.56072 4.23023C5.5267 3.26359 6.85966 2.66683 8.33326 2.66683C11.2788 2.66683 13.6666 5.05464 13.6666 8.00016C13.6666 10.9457 11.2788 13.3335 8.33326 13.3335C5.90277 13.3335 3.8504 11.7072 3.20831 9.482C3.10623 9.12824 2.7367 8.92422 2.38294 9.0263C2.02919 9.12838 1.82516 9.49791 1.92724 9.85166C2.72961 12.6322 5.29296 14.6668 8.33326 14.6668C12.0152 14.6668 14.9999 11.6821 14.9999 8.00016C14.9999 4.31826 12.0152 1.3335 8.33326 1.3335C6.49167 1.3335 4.82341 2.08109 3.61759 3.28775C3.19354 3.71209 2.7372 4.23864 2.33333 4.72972V2.66683C2.33333 2.29864 2.03486 2.00016 1.66667 2.00016C1.29848 2.00016 1 2.29864 1 2.66683V6.66683C1 7.03502 1.29848 7.3335 1.66667 7.3335H5.66667C6.03486 7.3335 6.33333 7.03502 6.33333 6.66683C6.33333 6.29864 6.03486 6.00016 5.66667 6.00016H3.02049C3.47655 5.42779 4.05123 4.74008 4.56072 4.23023Z"
                                        fill={`${
                                            props?.suspend?.onClick
                                                ? "var(--text-icons-blue-500)"
                                                : "var(--text-icons-gray-300)"
                                        }`}
                                    />
                                </svg>
                            )}

                            <div className="ml-8px">{props?.suspend?.isReenable ? "Re-Activate" : "Suspend"}</div>
                        </div>
                    </Menu.Item>
                )}

                {props?.remove?.visible && (
                    <Menu.Item className={"actionOption"} key="remove" onClick={props?.remove?.onClick}>
                        <div
                            className={`line-container ${
                                props?.remove?.onClick ? "text-gray-100" : "text-gray-300 hover-not-allowed"
                            }`}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none">
                                <path
                                    d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M6.66667 7.66659V10.9999M9.33333 7.66659V10.9999M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992"
                                    stroke={`${
                                        props?.remove?.onClick
                                            ? "var(--text-icons-red-500)"
                                            : "var(--text-icons-gray-300)"
                                    }`}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="ml-8px">{props?.remove?.removeIsDelete ? "Delete" : "Remove"}</div>
                        </div>
                    </Menu.Item>
                )}

                {props?.extraActions?.length > 0 &&
                    (props?.edit?.visible !== false ||
                        props?.suspend?.visible !== false ||
                        props?.remove?.visible !== false) && (
                        <Menu.Divider
                            style={{
                                marginTop: "0",
                                marginBottom: "8px",
                                background: "var(--backgrounds-lines-n-100)"
                            }}
                        />
                    )}

                {props?.extraActions?.map(item => (
                    <Menu.Item
                        className={"actionOption"}
                        key={uuidv4()}
                        onClick={
                            item?.onClick
                                ? () => {
                                      item.onClick(props?.tableRowData);
                                  }
                                : undefined
                        }>
                        <div
                            className={`line-container ${
                                item?.onClick ? "text-gray-100" : "text-gray-300 hover-not-allowed"
                            }`}>
                            {item?.icon ?? ""}
                            <div className={`${item?.icon ? "ml-8px" : ""}`}>{item?.title}</div>
                        </div>
                    </Menu.Item>
                ))}
            </Menu>
        </StyledMenu>
    );
}
