import { useInfiniteQuery, useMutation } from "react-query";
import { useQuery } from "react-query";
import { axiosInstance } from "../axios";
import { useEffect } from "react";

export const useGetGroupCategoriesQuery = (payload, onSuccess) => {
    return useInfiniteQuery({
        queryKey: ["grid"],
        queryFn: async ({ pageParam = 1 }) => {
            const { data } = await axiosInstance.get(
                `getGroupProductsData?companyID=${payload}&&limit=10&&page=${pageParam}`
            );
            return data;
        },
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.nextPage;
        },
        onSuccess: onSuccess
    });
};

export const useGetGroupProductsQuery = (payload, perpage, onSuccess) => {
    return useInfiniteQuery({
        queryKey: ["table", perpage],
        queryFn: async ({ pageParam = 1 }) => {
            const { data } = await axiosInstance.get(
                `getGroupProductsData?companyID=${payload}&&limit=${perpage}&&page=${pageParam}`
            );
            return data;
        },
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.nextPage;
        },
        onSuccess: onSuccess
    });
};

export const useGetGroupCategoryTagsMutation = () => {
    return useMutation({
        mutationKey: ["category-tag"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`generateGroupCategoryTagsWithAI`, payload);
            return data;
        }
    });
};

export const useCreateGroupCategoryMutation = onSuccess => {
    return useMutation({
        mutationKey: ["create-category-tag"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`createGroupCategory`, payload);
            return data;
        },
        onSuccess: onSuccess
    });
};

export const useGetGroupCategoryMutation = () => {
    return useMutation({
        mutationKey: ["group-category-data"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`getGroupCategoryData`, payload);
            return data;
        }
    });
};

export const useUpdateGroupCategoryMutation = () => {
    return useMutation({
        mutationKey: ["update-group-category-data"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`updateGroupCategory`, payload);
            return data;
        }
    });
};

export const useGetProductMutation = () => {
    return useMutation({
        mutationKey: ["get-product"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`getProduct`, payload);
            return data;
        }
    });
};
export const useGetCategoryNamesMutation = () => {
    return useMutation({
        mutationKey: ["get-names"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`getGroupCategoryNames`, payload);
            return data;
        }
    });
};

export const useUpdateProductGroupCategory = onSuccess => {
    return useMutation({
        mutationKey: ["update-product-group-category"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`updateProductGroupCategory`, payload);
            return data;
        },
        onSuccess: onSuccess
    });
};
