import ProductCategoryPage from "../components/pages/products/ProductCategoryPage";
import ProductCategoryList from "../components/pages/products/ProductCategoryList";
import ProductPage from "../components/pages/products/ProductPage";
import ProductsEditPage from "../components/pages/products/ProductsEditPage";

const routes = [
    {
        path: "/products",
        RouteComponent: ProductPage
    },
    {
        path: "/products/edit/:id?",
        RouteComponent: ProductsEditPage
    },
    {
        path: "/products/categories",
        RouteComponent: ProductCategoryList
    },
    {
        path: "/products/categories/:id?",
        RouteComponent: ProductCategoryList
    },
    {
        path: "/products/cluster",
        RouteComponent: ProductCategoryPage
    }
];

export default routes;
