const ItemVariationComponent = ({ colorName, bgColor, keyValues }) => {
    return (
        <div className="item-variation-container">
            <div className="item-variation-header">
                <div style={{ backgroundColor: bgColor }}></div>
                <h3>{colorName}</h3>
            </div>
            <div className="item-variation-headings">
                <p>SIZE</p>
                <div style={{ width: "83px", margin: "0px 12px" }}></div>
                <p>EAN CODE</p>
                <div style={{ width: "55px", margin: "0px 0px 0px 12px" }}></div>
            </div>
            <div className="item-variation-content-container">
                {keyValues?.map(item => {
                    return (
                        <div className="item-variation-content-wrapper">
                            <p>{item?.size}</p>
                            <p>{item?.barcode}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ItemVariationComponent;
