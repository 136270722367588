import { useMutation } from "react-query";
import { axiosInstance } from "../axios";

export const useGetGroupOrdersMutation = () => {
    return useMutation({
        mutationKey: ["grid"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`getGroupOrdersData`, payload);
            const arr = [];

            data?.data?.result?.map(data => {
                data?.data?.map(item => {
                    const totalDayAmount = data?.totalDayPrice;
                    const totalDayQuantity = data?.totalDayQuantity;
                    const monthTotalQuantity = data?.monthTotalQuantity;
                    const dayOrders = data?.data.length;
                    arr?.push({ ...item, totalDayAmount, totalDayQuantity, monthTotalQuantity, dayOrders });
                });
            });
            const res = {
                data: [...arr],
                monthTotalQuantity: data?.data?.monthTotalQuantity,
                monthTotalPrice: data?.data?.monthTotalPrice,
                monthOrders: data?.data?.monthOrders
            };
            return res;
        }
    });
};
