import MemberList from "../components/pages/members/MemberList";
import CreateMember from "../components/pages/members/CreateMember";
import MemberImporter from "../components/pages/members/MemberImporter";
import MemberBrandImporter from "../components/pages/members/MemberBrandImporter";
import StoresPage from "../components/pages/stores/StoresPage";
import StoresInvite from "../components/pages/stores/StoresInvite";

const routes = [
    {
        path: "/stores",
        RouteComponent: StoresPage
    },
    {
        path: "/stores/invite",
        RouteComponent: StoresInvite
    },
    {
        path: "/members",
        RouteComponent: MemberList
    },
    {
        path: "/members/create",
        RouteComponent: CreateMember
    },
    {
        path: "/members/importer",
        RouteComponent: MemberImporter
    },
    {
        path: "/members/brandimporter",
        RouteComponent: MemberBrandImporter
    }
];

export default routes;
