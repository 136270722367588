import React from "react";
import styled from "styled-components";
import BtnLargeSolidInverted02 from "../buttons/BtnLargeSolidInverted02";
import { ReactComponent as Pencil01 } from "../../../assets/icons/pencil-01.svg";
import { ReactComponent as Hand } from "../../../assets/icons/hand.svg";
import { ReactComponent as Trash01 } from "../../../assets/icons/trash-01.svg";
import { ReactComponent as RefreshCCW01 } from "../../../assets/icons/refresh-ccw-01.svg";
import { Tooltip } from "antd";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .UiCardStyle02M {
        position: relative;
        overflow: hidden;

        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 13px;
        flex: 1 0 0;

        border-radius: 4px;
        background: var(--backgrounds-lines-n-400, #1e2631);
    }

    .Frame340 {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
    }

    .Frame343 {
        display: flex;
        height: 174px;
        //padding: 70px 118px 66px 117px;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;

        border-radius: 4px;
        background: var(--backgrounds-lines-n-500, #141922);
        overflow: hidden;
    }

    .Category {
        color: var(--text-icons-blue-500, #00aeef);
        text-align: center;
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.48px;

        margin: 0 16px;
    }

    .Frame160 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        overflow: hidden;
    }

    .Frame172 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .Frame161 {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .TitleLeft {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 140% */
        letter-spacing: 0.125px;
        text-transform: uppercase;

        margin-right: 8px;
        flex-shrink: 0;
    }

    .TitleRight {
        /* Label/Small - Uppercase */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.125px;
        text-transform: uppercase;

        overflow: hidden;
    }

    .TitleLeft.smaller,
    .TitleRight.smaller {
        font-size: 0.625rem;
    }

    .Frame162 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
    }

    .Frame162-description {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        width: 100%;
        height: 12px;
        overflow: hidden;
        flex: auto;
    }

    .Frame171 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .Frame144 {
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .Description {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/XS - Uppercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: uppercase;
    }

    .Line1 {
        flex: 1 1 0;
        height: 1px;

        background: #3c485a;
    }

    .Frame204 {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .Frame169 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        width: 100%;
    }

    .DescriptionText {
        flex: 1 0 0;

        color: var(--text-icons-blue-500, #00aeef);

        /* Subtitle 3 */
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame205 {
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .ProductsIncluding {
        color: var(--text-icons-gray-100, #d1d1d1);

        /* Label/XS - Uppercase */
        font-family: Roboto;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: uppercase;
    }

    .Frame392 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .Chip {
        display: flex;
        padding: 4px 8px 4px 8px;
        align-items: center;
        gap: 4px;
        text-transform: capitalize;

        border-radius: 4px;
        background: var(--BOStageA-Blue-Active, #0155b3);
    }

    .Tag {
        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .XClose {
        width: 12px;
        height: 12px;
    }

    .Solid {
        display: flex;
        padding: 4px;
        align-items: center;
        gap: 4px;

        border-radius: 4px;
        background: var(--backgrounds-lines-n-500, #141922);
    }

    .ChipCounter {
        display: flex;
        padding: 4px;
        align-items: center;
        gap: 4px;

        border-radius: 4px;
        background: var(--backgrounds-lines-n-500, #141922);
    }

    .TagCounter {
        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame164 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .border-suspended {
        border: 1px solid var(--text-icons-orange-500);
    }
`;
/* #endregion */

/**
 * Renders a product card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {string} props.image - The image URL for the product.
 * @param {string} props.title - The title of the product.
 * @param {Object} props.values - The values object containing product details.
 * @param {Object} props.values.key - The key of the value.
 * @param {Object} props.values.value - The value of the key.
 * @param {string} props.id - The product ID.
 * @param {Object[]} props.data - The data to be displayed in the card details section.
 * @param {string} props.data[].key - The key of the detail.
 * @param {string} props.data[].value - The value of the detail.
 * @param {boolean} props.isCardEnabled - Indicates whether the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {Function} props.tagOnclick - The tag click event.
 * @param {Object} props.edit - The edit icon object.
 * @param {Function} props.edit.onClick - The edit icon click event.
 * @param {Boolean} props.edit.active - The edit icon active status.
 * @param {Boolean} props.edit.hidden - The edit icon hidden status.
 * @param {Object} props.suspend - The suspend icon object.
 * @param {Function} props.suspend.onClick - The suspend icon click event.
 * @param {Boolean} props.suspend.active - The suspend icon active status.
 * @param {Boolean} props.suspend.hidden - The suspend icon hidden status.
 * @param {Boolean} props.suspend.isReenable - The suspend icon reenable status.
 * @param {Object} props.delete - The delete icon object.
 * @param {Function} props.delete.onClick - The delete icon click event.
 * @param {Boolean} props.delete.active - The delete icon active status.
 * @param {Boolean} props.delete.hidden - The delete icon hidden status.
 * @param {Object} props.primaryButton - The primary button object.
 * @param {string} props.primaryButton.title - The primary button title.
 * @param {Function} props.primaryButton.onClick - The primary button click event.
 * @param {Object} props.secondaryButton - The secondary button object.
 * @param {string} props.secondaryButton.title - The secondary button title.
 * @param {Function} props.secondaryButton.onClick - The secondary button click event.
 *
 * @returns {JSX.Element} The rendered product category card component.
 */
export default function ProductCategoryGridCard(props) {
    const Capitalize = s => {
        if (!s || typeof s !== "string") return "";
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className={`UiCardStyle02M ${props?.isCardSuspended ? "border-suspended" : ""}`}>
                <div className="Frame340">
                    <div className="Frame343">
                        <div
                            className={`Category text-one-line ${
                                props?.title === "--" || props?.title === "-" || !props?.isCardEnabled
                                    ? "text-gray-300"
                                    : ""
                            }`}>
                            {Capitalize(props?.title)}
                        </div>
                    </div>
                </div>
                <div className="Frame160">
                    <div className="Frame172">
                        <div className="Frame161">
                            <div style={{ height: "16px", overflow: "hidden", display: "inline-flex" }}>
                                <span
                                    className={`TitleLeft ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
                                    CATEGORY:
                                </span>
                                <span
                                    className={`TitleRight text-one-line ${
                                        props?.title && props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                    }`}>
                                    {props?.title || "--"}
                                </span>
                            </div>
                            <div className="Frame162">
                                <Tooltip
                                    title="Edit"
                                    trigger="hover"
                                    defaultOpen={false}
                                    overlayClassName="custom-tooltip">
                                    <Pencil01
                                        onClick={props?.edit?.onClick}
                                        width="16px"
                                        height="16px"
                                        className={`${
                                            props?.edit?.onClick || props?.edit?.active
                                                ? "cursor-pointer text-green-500"
                                                : "text-inactive hover-not-allowed"
                                        } ${props?.edit?.hidden ? "hidden" : ""}`}
                                    />{" "}
                                </Tooltip>
                                {props?.suspend?.isReenable !== true ? (
                                    <Tooltip
                                        title="Suspend"
                                        trigger="hover"
                                        defaultOpen={false}
                                        overlayClassName="custom-tooltip">
                                        <Hand
                                            onClick={props?.suspend?.onClick}
                                            width="16px"
                                            height="16px"
                                            className={`${
                                                props?.suspend?.onClick || props?.suspend?.active
                                                    ? "text-orange-500 cursor-pointer"
                                                    : "text-inactive hover-not-allowed"
                                            } ${props?.suspend?.hidden ? "hidden" : ""}`}
                                        />
                                    </Tooltip>
                                ) : (
                                    <RefreshCCW01
                                        onClick={props?.suspend?.onClick}
                                        width="16px"
                                        height="16px"
                                        className={`${
                                            props?.suspend?.onClick || props?.suspend?.active
                                                ? "text-blue-500 cursor-pointer"
                                                : "text-inactive hover-not-allowed"
                                        } ${props?.suspend?.hidden ? "hidden" : ""}`}
                                    />
                                )}
                                <Tooltip
                                    title="Delete"
                                    trigger="hover"
                                    defaultOpen={false}
                                    overlayClassName="custom-tooltip">
                                    <Trash01
                                        onClick={props?.delete?.onClick}
                                        width="16px"
                                        height="16px"
                                        className={`${
                                            props?.delete?.onClick || props?.suspend?.active
                                                ? "cursor-pointer text-red-500"
                                                : "text-inactive hover-not-allowed"
                                        } ${props?.suspend?.hidden ? "hidden" : ""}`}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            style={{
                                height: "14px",
                                flexWrap: "nowrap",
                                display: "flex",
                                overflow: "hidden",
                                width: "100%"
                            }}>
                            <span
                                className={`TitleLeft smaller ${
                                    props?.isCardEnabled ? "text-gray-100" : "text-gray-300"
                                }`}>
                                BOTAG ID:
                            </span>
                            <span
                                className={`TitleRight text-one-line smaller ${
                                    props?.id && props?.isCardEnabled ? "text-blue-500" : "text-gray-300"
                                }`}>
                                {props?.id || "--"}
                            </span>
                        </div>
                    </div>
                    <div className="Frame171">
                        <div className="Frame144">
                            <div
                                className={`Description ${props?.isCardEnabled ? "text-gray-100" : "text-gray-300"}`}
                                style={{ height: "9px" }}>
                                DESCRIPTION
                            </div>
                            <div className="Line1"></div>
                        </div>
                        <div className="Frame204">
                            <div className="Frame169">
                                <div className="Frame162-description" style={{ height: "12px" }}>
                                    <div
                                        className={`DescriptionText text-one-line ${
                                            props?.description && props?.isCardEnabled
                                                ? "text-blue-500"
                                                : "text-gray-300"
                                        }`}>
                                        {Capitalize(props?.description) || "--"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Frame205" style={{ height: "9px" }}>
                            <div
                                className={`ProductsIncluding ${
                                    props?.isCardEnabled ? "text-gray-100" : "text-gray-300"
                                }`}>
                                PRODUCTS INCLUDING
                            </div>
                            <div className="Line1"></div>
                        </div>

                        {getTagsView(props?.data, props?.isCardEnabled, 28)}
                    </div>
                    <div className="Frame164">
                        <BtnLargeSolidInverted02
                            disabled={props?.primaryButton?.onClick === undefined}
                            text="DETAILS"
                            onClick={props?.primaryButton?.onClick}
                            style={{ flexGrow: "1" }}
                        />
                        {/* <BtnLargeSolidInverted02
                            disabled={props?.secondaryButton?.onClick === undefined}
                            text="PRODUCTS"
                            onClick={props?.secondaryButton?.onClick}
                            style={{ flexGrow: "1" }}
                            /> */}
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}

const getTagsIndexLessThanChars = (tags, maxCharacters) => {
    let totalTags = 0;
    let indexTagNotExceddingCharacters = 0;

    for (let i = 0; i < tags?.length; i++) {
        totalTags += tags[i]?.length;
        if (totalTags > maxCharacters) {
            indexTagNotExceddingCharacters = i - 1;
            break;
        }
    }

    if (totalTags <= maxCharacters) indexTagNotExceddingCharacters = tags?.length;
    if (indexTagNotExceddingCharacters < 1) indexTagNotExceddingCharacters = 1;

    return indexTagNotExceddingCharacters;
};

const getTagsViewMoreThanChars = (tags, isCardEnabled, maxCharacters) => {
    const indexTagNotExceddingCharacters = getTagsIndexLessThanChars(tags, maxCharacters);
    const tagsFilter = tags?.slice(indexTagNotExceddingCharacters);

    const totalCount = tagsFilter?.length;

    return (
        <div
            className={`ChipCounter ${totalCount > 0 ? "" : "hidden"} ${
                isCardEnabled ? "text-gray-100" : "text-gray-300 btn-gray-500"
            }`}>
            <div className="TagCounter">+{totalCount}</div>
        </div>
    );
};

const getTagsView = (tags, isCardEnabled, maxCharacters = 50) => {
    const indexTagNotExcedding50 = getTagsIndexLessThanChars(tags, maxCharacters);
    let tagsFilter = tags?.slice(0, indexTagNotExcedding50);

    //if (tags.length === 2) console.log("tags", tags, indexTagNotExcedding50, tagsFilter);

    if (tags?.length === 0) {
        tags = ["--"];
        tagsFilter = ["--"];
    }

    return (
        <div className={`Frame392 ${isCardEnabled ? "text-gray-100" : "text-gray-300"}`}>
            {tagsFilter?.map(name => (
                <div
                    className={`Chip ${
                        isCardEnabled && name !== "--" ? "text-gray-100" : "text-gray-300 btn-gray-500"
                    }`}>
                    <div className="Tag">{name}</div>
                </div>
            ))}

            {getTagsViewMoreThanChars(tags, isCardEnabled, maxCharacters)}
        </div>
    );
};

const getValues = (arrayValues, key) => {
    return arrayValues?.find(it => it?.key === key)?.value;
};

const getHexColor = color => {
    return "#" + color?.replace("#", "");
};

function XIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
                d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z"
                fill="currentColor"
            />
        </svg>
    );
}
