import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant.js";
import { read, utils } from "xlsx";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Box } from "../../common/importer/Box.js";
import { Dustbin } from "../../common/importer/Dustbin.js";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .profile-div {
        height: 120px !important;
    }

    .datatable-section h4 {
        color: var(--text-icons-gray-100);
        margin-right: 10px;
        font-size: 14px;
    }

    .datatable-section .search-bar {
        margin: 0;
        border-bottom: 2px solid var(--backgrounds-lines-n-400);
    }

    .datatable-section .search-bar > span {
        display: flex;
        line-height: 20px;
        color: var(--text-icons-gray-300);
        align-items: center;
        font-size: 9pt;
    }

    .filter-section > span {
        line-height: 35px;
        font-size: 10pt;
        margin-right: 10px;
        border-bottom: 2px;
    }

    .filter-section > .search-input {
        border-radius: 0;
        background: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-400);
        box-shadow: none;
        height: 35px;
    }

    .creators-section .cardsection {
        margin-bottom: 10px;
    }

    #fileImport {
        display: none;
    }

    .fileImport-name {
        text-align: left;
    }

    .product-importer-filebtn > .form-group {
        display: flex;
        justify-content: center;
    }

    .inputfile-6 + label {
        background-color: var(--backgrounds-lines-n-600);
        border: 1px solid #333f50;
        border-left: 5px solid var(--text-icons-orange-500) !important;
        border-radius: 5px;
        float: left;
        height: 37px;
        margin: 0;
        max-width: 350px;
        padding: 0;
        width: 100%;
    }

    .inputfile-6 + label span {
        color: var(--text-icons-gray-100);
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        min-height: 2em;
        overflow: hidden;
        padding: 9px 12px;
        text-overflow: ellipsis;
        vertical-align: top;
        white-space: nowrap;
        width: 280px;
    }

    .stromngclass {
        background-color: var(--BOStageG-Red-Outline);
        border: 1px solid var(--backgrounds-lines-n-400);
        border-radius: 4px;
        color: var(--text-icons-gray-100);
        cursor: pointer;
        display: inline-block;
        font-size: 11px;
        font-weight: 400;
        height: 37px;
        line-height: 37px;
        margin-bottom: 0;
        margin-left: 15px;
        text-align: center;
        text-transform: uppercase;
        width: 110px;
    }

    .container-checkbox {
        position: relative;
        padding-left: 15px;
        cursor: pointer;
    }

    .container-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        border: 1px solid var(--text-icons-gray-200);
        background-color: transparent;
    }

    .container-checkbox input:checked ~ .checkmark {
        ackground-color: transparent;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container-checkbox input:checked ~ .checkmark:after {
        display: block;
    }

    .container-checkbox .checkmark:after {
        border-color: var(--text-icons-gray-200) !important;
    }

    .container-checkbox .checkmark:after {
        border: solid #fff;
        border-width: 0 3px 3px 0;
        height: 10px;
        left: 4px;
        top: 1px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 5px;
    }

    .checkmark:after {
        content: "";
        display: none;
        position: absolute;
    }

    .file-text {
        color: var(--text-icons-gray-200);
        font-size: 12px;
        font-weight: 400;
        margin-top: 0;
        padding-left: 12px;
        text-transform: none;
    }

    /* #region Progress bar */
    .info-products-form .showprogress {
        margin: 10px 0 25px;
    }

    .showprogress .progress {
        background-color: var(--backgrounds-lines-n-600);
        border-radius: 0;
        height: 10px;
    }

    .progress {
        background-color: #f5f5f5;
        border-radius: 4px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        height: 20px;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .showprogress .progress-bar {
        background-color: var(--text-icons-blue-500);
    }
    .progress-bar {
        background-color: #337ab7;
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
        color: var(--text-icons-gray-100);
        float: left;
        font-size: 12px;
        height: 100%;
        line-height: 20px;
        text-align: center;
        transition: width 0.6s ease;
        width: 0;
    }

    /* #endregion */

    /* #region FIELDS DETECTED */
    .fieldfouds {
        background: var(--Buttons-All-Platforms-Gray-500);
        color: var(--text-icons-gray-100);
        font-family: Roboto;
        height: 526px;
        overflow-y: scroll;
        padding: 15px;
    }

    .products-col h4 {
        color: var(--text-icons-gray-100);
        font-size: 14px;
        margin: 0 0 10px;
        text-transform: uppercase;
    }

    /* #endregion */

    .with-placeholder {
        position: relative;
    }

    .with-placeholder > label.placeholder {
        position: absolute;
        top: 33px;
        width: 100%;
        text-align: center;
        opacity: 0.7;
        pointer-events: none;
        color: var(--text-icons-gray-100);
    }

    .bold {
        font-weight: bold;
    }
`;
/* #endregion */

export default function SupplierBrandImporter(props) {
    document.title = `${APP_TITLE} - Brand Importer`;

    /* #region STATES */
    const { allUserInfo } = useSelector(state => state.userData);

    const [buttonName, setButtonName] = useState("SAVE");
    const [buttonGray, setButtonGray] = useState(true);

    const [preLoader, setPreLoader] = useState(true);
    const [submitLoader, setSubmitLoader] = useState(false);

    const [fileName, setFileName] = useState("");
    const [progressBar, setProgressBar] = useState(0);
    const [temD, setTemD] = useState([]);

    var percentage = progressBar + "%";

    const [detectedFields, setDetectedFields] = useState([]);
    const [productList, setProductList] = useState([]);
    const [resPop, setResPop] = useState(false);
    const [droppedBoxNames, setDroppedBoxNames] = useState([]);

    const [draggedData, setDraggedData] = useState([]);
    const [dataS, setDataS] = useState([]);

    const [dustbins1, setDustbins1] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Brand" }]);
    const [dustbins2, setDustbins2] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Industry" }]);
    const [dustbins3, setDustbins3] = useState([
        { accepts: ["text"], lastDroppedItem: null, label: "Design/Lifestyle" }
    ]);
    const [dustbins4, setDustbins4] = useState([
        { accepts: ["text"], lastDroppedItem: null, label: "Main product category" }
    ]);
    const [dustbins5, setDustbins5] = useState([
        { accepts: ["text"], lastDroppedItem: null, label: "Other categories" }
    ]);
    const [dustbins6, setDustbins6] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Since" }]);
    const [dustbins7, setDustbins7] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Country" }]);

    const [fieldBrand, setFieldBrand] = useState(false);
    const [fieldIndustry, setFieldIndustry] = useState(false);
    const [fieldDesignLifestyle, setFieldDesignLifestyle] = useState(false);
    const [fieldMainProductCategory, setFieldMainProductCategory] = useState(false);
    const [fieldOtherCategories, setFieldOtherCategories] = useState(false);
    const [fieldSince, setFieldSince] = useState(false);
    const [fieldCountry, setFieldCountry] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        //setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);

    useEffect(() => {
        if (!allUserInfo) return;
    }, [allUserInfo]);

    /* #endregion */

    /* #region METHODS */
    const handleSubmit = () => {};

    const handleDrop1 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }));
            fileList(index, item, label);
            setDustbins1(
                update(dustbins1, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item
                        }
                    }
                })
            );
        },
        [droppedBoxNames, dustbins1]
    );

    const handleDrop2 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }));
            fileList(index, item, label);
            setDustbins2(
                update(dustbins2, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item
                        }
                    }
                })
            );
        },
        [droppedBoxNames, dustbins2]
    );

    const handleDrop3 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }));
            fileList(index, item, label);
            setDustbins3(
                update(dustbins3, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item
                        }
                    }
                })
            );
        },
        [droppedBoxNames, dustbins3]
    );

    const handleDrop4 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }));
            fileList(index, item, label);
            setDustbins4(
                update(dustbins4, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item
                        }
                    }
                })
            );
        },
        [droppedBoxNames, dustbins4]
    );

    const handleDrop5 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }));
            fileList(index, item, label);
            setDustbins5(
                update(dustbins5, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item
                        }
                    }
                })
            );
        },
        [droppedBoxNames, dustbins5]
    );

    const handleDrop6 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }));
            fileList(index, item, label);
            setDustbins6(
                update(dustbins6, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item
                        }
                    }
                })
            );
        },
        [droppedBoxNames, dustbins6]
    );

    const handleDrop7 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }));
            fileList(index, item, label);
            setDustbins7(
                update(dustbins7, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item
                        }
                    }
                })
            );
        },
        [droppedBoxNames, dustbins7]
    );

    const fileList = (index, item, label) => {
        setTemD(temD => [...temD, { leftItemIndex: index, boxName: label, itemName: item.name }]);

        const filterDataS = dataS.filter(r => {
            return r.boxName != label;
        });
        filterDataS.push({ leftItemIndex: index, boxName: label, itemName: item.name });
        setDataS(filterDataS);
    };

    const arr = [];

    const clear = (ele, lbl) => {
        setResPop(false);
        const item = ele;
        const index = droppedBoxNames.indexOf(item);
        droppedBoxNames.splice(index, 1);

        setDroppedBoxNames(update(droppedBoxNames, item ? { $push: [""] } : { $push: [] }));
        // console.log("previousArray - "+ JSON.stringify(arr));
        let lblRm = lbl.split(" ").join("_");
        lblRm = lblRm.split("/").join("_");

        const inx = arr.findIndex(ar => ar[lblRm]);
        arr.splice(inx, 1);
        //console.log("AfterDelArray - "+ JSON.stringify(arr));

        rmvData(lbl);
    };

    const arrData = (inx, val) => {
        console.log("arrData", inx, val);
        const ob = cunstObj(inx, val);
        arr.push(ob);
        setDraggedData(JSON.stringify(arr));
        setResPop(true);
    };

    const cunstObj = (inx, val) => {
        //console.log("val",typeof val);
        let value = val?.toString()?.split(" ")?.join("_");
        value = value?.split("/")?.join("_");
        let indx = inx?.split(" ")?.join("_");
        indx = indx?.split("/")?.join("_");
        return { [indx]: value };
    };

    const rmvData = label => {
        const dat = dataS.filter(e => {
            if (e.boxName != label) {
                return e;
            }
        });
        setDataS(dat);
    };

    const readImporterFile = e => {
        //setPreviousPosData([])
        const files = e.target.files;
        if (files.length) {
            setTemD([]);

            const file = files[0];
            setFileName(file.name);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = event => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const didectedHeaders = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        header: 1,
                        defval: "",
                        blankrows: false
                    });
                    const didectedData = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        defval: "-",
                        blankrows: true
                    });
                    const customHeader = [];
                    didectedHeaders[0].map((value, index) => {
                        customHeader.push({ name: value, type: "text" });
                    });
                    setDetectedFields(customHeader);
                    setProductList(didectedData);
                    /*setFilePreviewButtonDisable(false);
                    setAppPreviewButtonDisable(true);
                    setSaveButtonDisablee(false);*/

                    //console.log("Detected Fields", detectedFields);
                }
            };
        }
    };

    const isDropped = boxName => {
        return droppedBoxNames.indexOf(boxName) > -1;
    };

    const fullScreenCheck = e => {
        /*let fulls = localStorage.getItem('fullscreen');
        if (fulls === 'full') {
            document.body.requestFullscreen();
        }*/
    };
    /* #endregion */

    /* #region CONSTANTS */

    /* #endregion */

    /* #region COMPONENTS */

    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className="filtertopcombo" style={{ marginTop: "20px", textAlign: "right" }}>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6" style={{ display: "inline-flex", placeContent: "center" }}>
                        <div className="product-importer-filebtn" style={{ maxWidth: "610px", width: "100%" }}>
                            <div className="form-group">
                                <input
                                    type="file"
                                    name="fileImport"
                                    id="fileImport"
                                    className="inputfile inputfile-6"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={e => {
                                        readImporterFile(e);
                                        fullScreenCheck(e);
                                    }}
                                    onClick={e => {
                                        e.target.value = null;
                                    }}
                                />
                                <label className="fileImport-name" htmlFor="fileImport">
                                    <span>{fileName}</span>
                                </label>
                                <label
                                    htmlFor="fileImport"
                                    className={fileName ? "stromngclass active" : "stromngclass active"}>
                                    Upload File
                                </label>
                            </div>
                            <div className="form-group padd-top hidden">
                                <label className="container-checkbox">
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                </label>
                                <span className="file-text">
                                    {" "}
                                    File includes URLs for product images <i className="fas fa-info-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="filtersection creatorsbtnset enroll-brand ">
                            <span
                                className={
                                    buttonGray
                                        ? "customeset textinside next-new-btn"
                                        : "customeset textinside next-new-btn disabledbtn"
                                }>
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="btn btn-success main-btn"
                                    disabled={buttonGray ? true : ""}>
                                    {buttonName}
                                    {submitLoader ? <i className="fas fa-spinner fa-spin"></i> : ""}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <section className="new-section" style={{ marginTop: "0px" }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="cardsection">
                            <div className="card-header">
                                <h3 className="card-title">
                                    BRANDS FILE
                                    <span className="formcautions">
                                        <ul>
                                            <li>
                                                <span className="makesqaure mandatorythis"></span>mandatory fields
                                            </li>
                                            <li>
                                                <span className="makesqaure lockedthis"></span>locked fields
                                            </li>
                                        </ul>
                                    </span>
                                </h3>
                            </div>

                            <div className="card-body">
                                <form>
                                    <div className="showprogress">
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow={progressBar}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: percentage }}>
                                                <span className="sr-only">{progressBar}% Complete</span>
                                            </div>
                                        </div>
                                    </div>

                                    <DndProvider backend={HTML5Backend}>
                                        <div className="formdivisions">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="fieldsarea">
                                                        <div className="form-group">
                                                            <label htmlFor="tobrand">
                                                                <i className="fas fa-info-circle"></i> Fields detected{" "}
                                                            </label>
                                                        </div>
                                                        <div className="fieldfouds">
                                                            {detectedFields?.map(({ name, type }, index) => {
                                                                return (
                                                                    <Box
                                                                        name={name}
                                                                        type={type}
                                                                        isDropped={isDropped(name)}
                                                                        key={index}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="products-col">
                                                        <h4
                                                            style={{
                                                                color: "var(--text-icons-gray-100)",
                                                                marginTop: "0"
                                                            }}>
                                                            BRAND ID
                                                        </h4>
                                                        <div className="form-group activeborder with-placeholder">
                                                            <label htmlFor="tobrand">
                                                                <i className="fas fa-info-circle"></i> Brand
                                                            </label>

                                                            {dustbins1.map(
                                                                ({ accepts, lastDroppedItem, label }, index) => (
                                                                    <Dustbin
                                                                        accept={accepts}
                                                                        lastDroppedItem={lastDroppedItem}
                                                                        onDrop={item => handleDrop1(index, item, label)}
                                                                        key={index}
                                                                        label={label}
                                                                        clear={clear}
                                                                        arrData={arrData}
                                                                        valids={fieldBrand}
                                                                        isMandatory={true}
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="products-col">
                                                        <div className="form-group activeborder with-placeholder">
                                                            <label htmlFor="fieldCategoryName">
                                                                <i
                                                                    className="fas fa-info-circle"
                                                                    aria-hidden="true"></i>{" "}
                                                                Industry
                                                            </label>

                                                            {dustbins2.map(
                                                                ({ accepts, lastDroppedItem, label }, index) => (
                                                                    <Dustbin
                                                                        accept={accepts}
                                                                        lastDroppedItem={lastDroppedItem}
                                                                        onDrop={item => handleDrop2(index, item, label)}
                                                                        key={index}
                                                                        label={label}
                                                                        clear={clear}
                                                                        arrData={arrData}
                                                                        valids={fieldIndustry}
                                                                        isMandatory={true}
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="products-col">
                                                        <div className="form-group activeborder with-placeholder">
                                                            <label htmlFor="fieldCategoryName">
                                                                <i
                                                                    className="fas fa-info-circle"
                                                                    aria-hidden="true"></i>{" "}
                                                                Design/Lifestyle
                                                            </label>

                                                            {dustbins3.map(
                                                                ({ accepts, lastDroppedItem, label }, index) => (
                                                                    <Dustbin
                                                                        accept={accepts}
                                                                        lastDroppedItem={lastDroppedItem}
                                                                        onDrop={item => handleDrop3(index, item, label)}
                                                                        key={index}
                                                                        label={label}
                                                                        clear={clear}
                                                                        arrData={arrData}
                                                                        valids={fieldDesignLifestyle}
                                                                        isMandatory={false}
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="products-col">
                                                        <div className="form-group with-placeholder">
                                                            <label htmlFor="fieldCategoryName">
                                                                <i
                                                                    className="fas fa-info-circle"
                                                                    aria-hidden="true"></i>{" "}
                                                                Main product category
                                                            </label>

                                                            {dustbins4.map(
                                                                ({ accepts, lastDroppedItem, label }, index) => (
                                                                    <Dustbin
                                                                        accept={accepts}
                                                                        lastDroppedItem={lastDroppedItem}
                                                                        onDrop={item => handleDrop4(index, item, label)}
                                                                        key={index}
                                                                        label={label}
                                                                        clear={clear}
                                                                        arrData={arrData}
                                                                        valids={fieldMainProductCategory}
                                                                        isMandatory={false}
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="products-col">
                                                        <div className="form-group with-placeholder">
                                                            <label htmlFor="fieldCategoryName">
                                                                <i
                                                                    className="fas fa-info-circle"
                                                                    aria-hidden="true"></i>{" "}
                                                                Other categories
                                                            </label>

                                                            {dustbins5.map(
                                                                ({ accepts, lastDroppedItem, label }, index) => (
                                                                    <Dustbin
                                                                        accept={accepts}
                                                                        lastDroppedItem={lastDroppedItem}
                                                                        onDrop={item => handleDrop5(index, item, label)}
                                                                        key={index}
                                                                        label={label}
                                                                        clear={clear}
                                                                        arrData={arrData}
                                                                        valids={fieldOtherCategories}
                                                                        isMandatory={false}
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="products-col">
                                                        <div className="form-group with-placeholder">
                                                            <label htmlFor="fieldCategoryName">
                                                                <i
                                                                    className="fas fa-info-circle"
                                                                    aria-hidden="true"></i>{" "}
                                                                Since
                                                            </label>

                                                            {dustbins6.map(
                                                                ({ accepts, lastDroppedItem, label }, index) => (
                                                                    <Dustbin
                                                                        accept={accepts}
                                                                        lastDroppedItem={lastDroppedItem}
                                                                        onDrop={item => handleDrop6(index, item, label)}
                                                                        key={index}
                                                                        label={label}
                                                                        clear={clear}
                                                                        arrData={arrData}
                                                                        valids={fieldSince}
                                                                        isMandatory={false}
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="products-col">
                                                        <div className="form-group with-placeholder">
                                                            <label htmlFor="fieldCategoryName">
                                                                <i
                                                                    className="fas fa-info-circle"
                                                                    aria-hidden="true"></i>{" "}
                                                                Country
                                                            </label>

                                                            {dustbins7.map(
                                                                ({ accepts, lastDroppedItem, label }, index) => (
                                                                    <Dustbin
                                                                        accept={accepts}
                                                                        lastDroppedItem={lastDroppedItem}
                                                                        onDrop={item => handleDrop7(index, item, label)}
                                                                        key={index}
                                                                        label={label}
                                                                        clear={clear}
                                                                        arrData={arrData}
                                                                        valids={fieldCountry}
                                                                        isMandatory={false}
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DndProvider>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Suppliers", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/suppliers", active: false, disabled: false, title: "Suppliers" },
        { to: "/suppliers/create", active: false, disabled: false, title: "New Supplier" },
        { to: "/suppliers/importer", active: true, disabled: false, title: "Importer" }
    ]);
    props.setControlBarRightBtns([
        { to: "/suppliers/importer", active: false, title: "Company", icon: "fa-sharp fa-building" },
        { to: "/suppliers/brandimporter", active: true, disabled: false, title: "Brands", icon: "fa-tags" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: false,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
