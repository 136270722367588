import React from "react";

/**
 * Renders a tag component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.index - The index of the tag.
 * @param {string} props.bgColor - The background color of the tag.
 * @param {string} props.tagName - The name of the tag.
 * @param {function} props.onClick - The click event handler for the tag.
 * @returns {JSX.Element} The rendered tag component.
 */
const TagComponent = ({ index, bgColor, tagName, onClick }) => {
    return (
        <div className="category-tag-main">
            <div
                className="category-tag-container"
                key={index}
                style={{
                    backgroundColor: bgColor
                }}>
                <div style={{ fontSize: "12px" }}>{tagName}</div>
                <div onClick={onClick}>
                    <i
                        className="fa-solid fa-xmark"
                        style={{
                            fontSize: "13px",
                            fontWeight: "500"
                        }}></i>
                </div>
            </div>
        </div>
    );
};

export default TagComponent;
