import "../../../assets/css/components/UnitSold.css";
import unitSoldShirt from "../../../assets/images/unit-sold-shirt.png";

const UnitSold = () => {
    return (
        <div className="unit-sold-container">
            <div className="header">
                <p>
                    TOP PRODUCTS BY UNITS SOLD &nbsp; <i class="fa-light fa-circle-info"></i>
                </p>
                <p>SEE MORE...</p>
            </div>
            <div className="unit-sold-main-data">
                <div className="unit-sold-single-data">
                    <div className="unit-sold-img-portion">
                        <div className="unit-sold-img-holder">
                            <img src={unitSoldShirt} alt="" width={"100%"} height={"100%"} />
                        </div>
                        <div className="unit-sold-single-brand-data">
                            <p>Luan J Shirt 14826</p>
                            <p>1.500 kr</p>
                        </div>
                    </div>
                    <div className="unit-sold-single-last-portion">
                        <p>78</p>
                        <p>sold</p>
                    </div>
                </div>
                {/* _________ */}
                <div className="unit-sold-single-data">
                    <div className="unit-sold-img-portion">
                        <div className="unit-sold-img-holder">
                            <img src={unitSoldShirt} alt="" width={"100%"} height={"100%"} />
                        </div>
                        <div className="unit-sold-single-brand-data">
                            <p>Luan J Shirt 14826</p>
                            <p>1.500 kr</p>
                        </div>
                    </div>
                    <div className="unit-sold-single-last-portion">
                        <p>78</p>
                        <p>sold</p>
                    </div>
                </div>
                {/* _________ */}
                <div className="unit-sold-single-data">
                    <div className="unit-sold-img-portion">
                        <div className="unit-sold-img-holder">
                            <img src={unitSoldShirt} alt="" width={"100%"} height={"100%"} />
                        </div>
                        <div className="unit-sold-single-brand-data">
                            <p>Luan J Shirt 14826</p>
                            <p>1.500 kr</p>
                        </div>
                    </div>
                    <div className="unit-sold-single-last-portion">
                        <p>78</p>
                        <p>sold</p>
                    </div>
                </div>
                {/* _________ */}
                <div className="unit-sold-single-data">
                    <div className="unit-sold-img-portion">
                        <div className="unit-sold-img-holder">
                            <img src={unitSoldShirt} alt="" width={"100%"} height={"100%"} />
                        </div>
                        <div className="unit-sold-single-brand-data">
                            <p>Luan J Shirt 14826</p>
                            <p>1.500 kr</p>
                        </div>
                    </div>
                    <div className="unit-sold-single-last-portion">
                        <p>78</p>
                        <p>sold</p>
                    </div>
                </div>
                {/* _________ */}
                <div className="unit-sold-single-data">
                    <div className="unit-sold-img-portion">
                        <div className="unit-sold-img-holder">
                            <img src={unitSoldShirt} alt="" width={"100%"} height={"100%"} />
                        </div>
                        <div className="unit-sold-single-brand-data">
                            <p>Luan J Shirt 14826</p>
                            <p>1.500 kr</p>
                        </div>
                    </div>
                    <div className="unit-sold-single-last-portion">
                        <p>78</p>
                        <p>sold</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnitSold;
