import React, { useState, useEffect, useRef } from "react";
import {
    useGetCategoryNamesMutation,
    useGetProductMutation,
    useUpdateProductGroupCategory
} from "../../../api/products/products.query";
import useDebounce from "../../../hooks/useDebouncs";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormSimpleComponent from "../../common/forms/FormSimpleComponent";
import ItemVariationComponent from "../../common/productsEdit/ItemVariationComponent";
import imageLoadingErr from "../../../assets/images/imageLoadingErr.jpg";
import ProductInfoInputs from "./productEditInputs/ProductInfoInputs";
import MaterialAndCareInputs from "./productEditInputs/MaterialAndCareInputs";
import OtherFieldInputs from "./productEditInputs/OtherFieldInputs";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import NotificationHandler from "../../common/notificationHandler/NotificationHandler";
import SearchableDropdown from "../../common/editableDropdown/EditableDropdown";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";

toast.configure();

const StoresInvite = props => {
    const navigate = useNavigate();

    const handleSuccess = (responseData, setSelectedLabelBrand) => {
        const failText = "Please Select a Category";
        const successTextHeader = "Product Updated";
        const successTextBody = "New category assigned to product.";
        const navigateTo = `/products/edit/${id}`;

        NotificationHandler(
            responseData,
            setSelectedLabelBrand,
            failText,
            successTextHeader,
            successTextBody,
            navigate
        );
    };

    let id = useParams();
    id = id?.id;
    const wrapperRef = useRef(null);
    const [confirmPopUp, setConfirmPopUp] = useState(false);
    const [showBackwardButton, setShowBackwardButton] = useState(false);
    const [showForwardButton, setShowForwardButton] = useState(true);
    const [selectedLabelBrand, setSelectedLabelBrand] = useState("");
    const [brandName, setBrandName] = useState("");
    const [reference, setReference] = useState("");
    const [maintenance, setMaintenance] = useState("");
    const [composition, setComposition] = useState("");
    const [description, setDescription] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [countryOfOrigin, setCountryOfOrigin] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [styleNumber, setStyleNumber] = useState("");
    const [storeName, setStoreName] = useState("");
    const [labelStoreName, setlabelStoreName] = useState("");
    const [buttonGray, setButtonGray] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [prevBrandData, setPrevBrandData] = useState(null);
    const { mutate: getProduct, data: productData, isLoading: productLoadinng } = useGetProductMutation();
    const { mutate: getNames, data: categoryNameData, isLoading: namesLoading } = useGetCategoryNamesMutation();
    console.log(productData?.data?.product?.productType);
    // console.log(productCategory);
    const [value, setValue] = useState("");
    const {
        mutate: updateProduct,
        data: updatedData,
        isLoading: updateLoading
    } = useUpdateProductGroupCategory(handleSuccess);
    const categoryName = useDebounce(storeName, 100);

    // getting store names for select input
    const getstoresData = () => {
        getNames({
            groupCategoryName: categoryName,
            companyID: localStorage.getItem("currentCompanyID")
        });
    };
    useEffect(() => {
        if (categoryName?.length > 2) {
            getstoresData();
        }
    }, [categoryName]);

    // getting the product data using id
    const getProductData = () => {
        getProduct({
            productID: id
        });
    };

    useEffect(() => {
        getProductData();
    }, []);
    const product = productData?.data?.product;

    useEffect(() => {
        setReference(product?.referenceCode || "-");
        setBrandName(product?.name || "-");
        setStyleNumber("-");
        setDescription(product?.description);
        setShortDescription(product?.shortDescription);
        setCountryOfOrigin(product?.countryOfOrigin);
        setComposition(product?.composition);
        setMaintenance(product?.maintenance);
        setProductCategory(product?.productType);
    });

    useEffect(() => {
        setValue(productCategory);
    }, [productCategory]);

    //updating the product
    const getUpdateProduct = () => {
        updateProduct({
            companyObjID: localStorage.getItem("currentCompanyID"),
            productObjID: id,
            groupCategoryID: selectedLabelBrand
        });
    };

    //getting gtins
    const gTins = productData?.data?.product?.GTINsByColor;

    useEffect(() => {
        if (categoryNameData) {
            setPrevBrandData(categoryNameData?.data?.result);
        }
    }, [categoryNameData]);

    // scroll logic start

    useEffect(() => {
        const wrapper = wrapperRef.current;

        let isMouseDown = false;
        let startX;
        let scrollLeftAtStart;

        const handleMouseDown = e => {
            isMouseDown = true;
            startX = e.pageX - wrapper.offsetLeft;
            scrollLeftAtStart = wrapper.scrollLeft;
        };

        const handleMouseMove = e => {
            if (!isMouseDown) return;
            const x = e.pageX - wrapper.offsetLeft;
            const scrollOffset = x - startX;
            wrapper.scrollLeft = scrollLeftAtStart - scrollOffset;
        };

        const handleMouseUp = () => {
            isMouseDown = false;
        };

        const handleScroll = () => {
            if (wrapper) {
                setShowBackwardButton(wrapper.scrollLeft > 0);
                setShowForwardButton(wrapper.scrollLeft < wrapper.scrollWidth - wrapper.clientWidth);
            }
        };

        if (wrapper) {
            wrapper.addEventListener("scroll", handleScroll);
            wrapper.addEventListener("mousedown", handleMouseDown);
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
            handleScroll(); // Call it initially to set the initial state
        }

        return () => {
            if (wrapper) {
                wrapper.removeEventListener("scroll", handleScroll);
                wrapper.removeEventListener("mousedown", handleMouseDown);
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
            }
        };
    });

    const scrollWrapper = scrollOffset => {
        const wrapper = wrapperRef.current;
        if (wrapper) {
            wrapper.scrollBy({
                left: scrollOffset,
                behavior: "smooth"
            });
        }
    };

    //scroll logic end

    const inputWidth = window.innerWidth <= 1200 ? "100%" : "calc(100% - 0px)";

    useEffect(() => {
        if (!props?.layoutType) return;
        setupHeaders(props);
    }, [props?.layoutType]);

    const data = categoryNameData?.data?.result;

    return (
        <>
            {confirmPopUp && (
                <div
                    style={{
                        position: "fixed",
                        zIndex: "9999999",
                        top: "452px",
                        left: "44%"
                    }}>
                    <FullScreenOverlay
                        children={
                            <DialogBoxComponent
                                heading="JUST CHECKING..."
                                headingColor="#00AEEF"
                                title="Ready To Update?"
                                message={`Product will be updated`}
                                onClickYes={() => {
                                    getUpdateProduct();
                                    setConfirmPopUp(false);
                                }}
                                onClickNo={() => {
                                    setConfirmPopUp(false);
                                }}
                            />
                        }
                    />
                </div>
            )}
            {updateLoading ? (
                <div style={{ position: "fixed", zIndex: "9999999", top: "452px", left: "44%" }}>
                    <FullScreenOverlay
                        children={<DialogBoxComponent heading="Saving" headingColor="#00BB00" loading="true" />}
                    />
                </div>
            ) : (
                ""
            )}
            {productLoadinng ? <FullScreenLoader /> : null}
            <div style={{ marginTop: "-27px" }}>
                <div className="filtertopcombo" style={{ marginTop: "20px", textAlign: "right" }}>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">
                            <div className="filtersection creatorsbtnset enroll-brand ">
                                <span
                                    className={
                                        buttonGray
                                            ? "customeset textinside next-new-btn"
                                            : "customeset textinside next-new-btn disabledbtn"
                                    }>
                                    <BtnLargeSolid
                                        type="submit"
                                        className="float-right"
                                        onClick={() => {
                                            setConfirmPopUp(true);
                                        }}
                                        text="SAVE"

                                        // disabled={!brandName || !styleNumber || !reference || !labelStoreName}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="new-section" style={{ marginTop: "30px", borderRadius: "4px" }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cardsection">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <div style={{ paddingLeft: "16px", display: "inline-block" }}> PRODUCT</div>
                                    </h3>
                                </div>

                                <div className="card-body" style={{ paddingBottom: "40px" }}>
                                    <form>
                                        <div className="row">
                                            <div className="row-one-first-container">
                                                <div
                                                    className="image-carousel-container"
                                                    style={{
                                                        position: "relative",
                                                        zIndex: 100
                                                    }}>
                                                    <div className="image-carousel-wrapper" ref={wrapperRef}>
                                                        {gTins?.map(item => {
                                                            return item?.gtins?.[0]?.images?.map(it => {
                                                                return (
                                                                    <div
                                                                        className="carousel-image-container"
                                                                        style={{
                                                                            position: "relative",
                                                                            zIndex: -1
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                !it?.URL || imageError
                                                                                    ? imageLoadingErr
                                                                                    : it.URL
                                                                            }
                                                                            alt="link broken"
                                                                            style={{
                                                                                borderRadius: "6px",
                                                                                height: "100%",
                                                                                minWidth: "200px"
                                                                            }}
                                                                            onError={e => {
                                                                                setImageError(true);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            });
                                                        })}
                                                    </div>
                                                    {showBackwardButton && (
                                                        <>
                                                            <div
                                                                className="btns forward"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    scrollWrapper(-300);
                                                                }}
                                                                style={{ cursor: "pointer" }}>
                                                                <i class="fa-regular fa-chevron-left"></i>
                                                            </div>
                                                            <div className="product-edit-btn-shawdow forward-shawdow"></div>
                                                        </>
                                                    )}
                                                    {showForwardButton && (
                                                        <>
                                                            <div
                                                                className="btns backward"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    scrollWrapper(300);
                                                                }}
                                                                style={{ cursor: "pointer" }}>
                                                                <i class="fa-regular fa-angle-right"></i>
                                                            </div>
                                                            <div className="product-edit-btn-shawdow backward-shawdow"></div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="inputs-container">
                                                    <div className=" first-portion-input-one invite-input-two">
                                                        <FormSimpleComponent
                                                            titleName="Brand"
                                                            titleColor="#7C868B"
                                                            backgroundColor="#10141B"
                                                            border="true"
                                                            inputColor="#00BB00"
                                                            iconDisplay="none"
                                                            disabledValue={true}
                                                            onChange={e => {
                                                                setBrandName(e.target.value);
                                                            }}
                                                            value={brandName}
                                                            style={{
                                                                borderLeft:
                                                                    brandName?.trim() !== ""
                                                                        ? "5px solid #141922"
                                                                        : "5px solid #4F5B6D",
                                                                backgroundColor: "#10141B",
                                                                color: "#4F5B6D",
                                                                width: inputWidth
                                                            }}
                                                            maxWidth="1000px"
                                                        />
                                                    </div>
                                                    <div className=" first-portion-input-one invite-input-two">
                                                        <FormSimpleComponent
                                                            titleName="Reference"
                                                            titleColor="#7C868B"
                                                            backgroundColor="#10141B"
                                                            border="true"
                                                            inputColor="#00BB00"
                                                            iconDisplay="none"
                                                            disabledValue={true}
                                                            onChange={e => {
                                                                setReference(e.target.value);
                                                            }}
                                                            value={reference}
                                                            style={{
                                                                borderLeft:
                                                                    reference?.trim() !== ""
                                                                        ? "5px solid #141922"
                                                                        : "5px solid #4F5B6D",
                                                                backgroundColor: "#10141B",
                                                                color: "#4F5B6D",
                                                                width: inputWidth
                                                            }}
                                                            maxWidth="1000px"
                                                        />
                                                    </div>
                                                    <div
                                                        className="first-portion-input-one invite-input-one"
                                                        style={{
                                                            color: "#7C868B",
                                                            position: "relative",
                                                            // width: "394px",
                                                            maxWidth: "100%"
                                                        }}>
                                                        <label
                                                            htmlFor="fieldCategoryName"
                                                            style={{ marginBottom: "4px", fontSize: "0.75rem" }}>
                                                            <i
                                                                className="fas fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ fontSize: "9px", fontWeight: "500" }}></i>
                                                            &nbsp; Category
                                                        </label>
                                                        {/* <Select
                                                            options={options}
                                                            className="custom-select"
                                                            styles={customStyles}
                                                            placeholder=""
                                                            isSearchable
                                                            isClearable
                                                            onInputChange={e => {
                                                                setStoreName(e);
                                                            }}
                                                            onChange={e => {
                                                                setlabelStoreName(e?.value);
                                                            }}
                                                            inputValue={storeName}
                                                            menuIsOpen={menuIsOpen}
                                                            noOptionsMessage={customNoOptionsMessage}
                                                            onBlur={() => {
                                                                setMenuIsOpen(false);
                                                            }}
                                                        /> */}

                                                        <SearchableDropdown
                                                            options={data || prevBrandData}
                                                            label="name"
                                                            id="id"
                                                            selectedVal={value}
                                                            handleChange={val => {
                                                                setValue(val);
                                                            }}
                                                            handleInputChange={e => {
                                                                setStoreName(e);
                                                            }}
                                                            setLabelId={setSelectedLabelBrand}
                                                            NoOptionsMessage="No Stores Available"
                                                            isLoading={namesLoading}
                                                        />
                                                        <div className="edit-select-base">TEXCON</div>
                                                    </div>
                                                    <div className=" first-portion-input-one invite-input-two">
                                                        <FormSimpleComponent
                                                            titleName="Style number"
                                                            titleColor="#7C868B"
                                                            backgroundColor="#10141B"
                                                            border="true"
                                                            inputColor="#00BB00"
                                                            iconDisplay="none"
                                                            disabledValue={true}
                                                            onChange={e => {
                                                                setStyleNumber(e.target.value);
                                                            }}
                                                            value={styleNumber}
                                                            style={{
                                                                borderLeft:
                                                                    styleNumber?.trim() !== ""
                                                                        ? "5px solid #141922"
                                                                        : "5px solid #4F5B6D",
                                                                backgroundColor: "#10141B",
                                                                color: "#4F5B6D",
                                                                width: inputWidth
                                                            }}
                                                            maxWidth="1000px"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="item-variation-wrapper-container">
                                        <h5>ITEM VARIATIONS</h5>
                                        <div className="item-variation-component-wrapper">
                                            {gTins?.map(item => {
                                                return (
                                                    <div className="item-variation-wrapper">
                                                        <ItemVariationComponent
                                                            bgColor={`#${item?.gtins?.[0]?.colorCode}`}
                                                            colorName={item?.colorName}
                                                            keyValues={item?.gtins?.map(it => {
                                                                return it;
                                                            })}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-section" style={{ borderRadius: "4px" }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cardsection">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <div style={{ paddingLeft: "16px", display: "inline-block" }}>PRODUCT INFO</div>
                                    </h3>
                                </div>

                                <div className="card-body" style={{ paddingBottom: "40px" }}>
                                    <ProductInfoInputs
                                        reference={reference}
                                        labelStoreName={labelStoreName}
                                        description={description}
                                        shortDescription={shortDescription}
                                        countryOfOrigin={countryOfOrigin}
                                        brandName={brandName}
                                        productCategory={productCategory}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-section" style={{ borderRadius: "4px" }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cardsection">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <div style={{ paddingLeft: "16px", display: "inline-block" }}>
                                            MATERIAL & CARE
                                        </div>
                                    </h3>
                                </div>

                                <div className="card-body" style={{ paddingBottom: "40px" }}>
                                    <MaterialAndCareInputs composition={composition} maintenance={maintenance} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-section" style={{ borderRadius: "4px" }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cardsection">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <div style={{ paddingLeft: "16px", display: "inline-block" }}>OTHER FIELDS</div>
                                    </h3>
                                </div>

                                <div className="card-body" style={{ paddingBottom: "40px" }}>
                                    <OtherFieldInputs />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StoresInvite;

function setupHeaders(props) {
    props.setControlBarLeftBtns([
        { to: "#", disabled: true, active: false, title: "BRANDS", icon: "fa-magnifying-glass" }
    ]);
    props.setControlBarCenterBtns([
        { to: "#", active: true, disabled: false, title: "PRODUCTS" },
        { to: "products/cluster", active: false, disabled: false, title: "CATEGORIES" }
    ]);
    props.setControlBarRightBtns([
        { to: "/products", disabled: false, title: "Back", icon: "fa-angle-left" },
        { to: "#", active: true, disable: false, title: "Edit", icon: "fa-pen-line" }
    ]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: false,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
