export const CommonContexts = {
    ACCOUNTS: "Accounts",
    COMPANY: "Company",
    COURIERS: "Couriers",
    POS: "POS",
    STORES: "Stores",
    ORGANIZATION: "Organization"
};

export const CommonAccountsEndpoints = {
    getStaffFromCompanies: "getStaffFromCompanies",
    getStaffInvites: "getStaffInvites",
    inviteStaff: "inviteStaff",
    inviteStaffStoreUser: "inviteStaffStoreUser",
    resendLoginCredentials: "resendLoginCredentials",
    updateUserStatus: "updateUserStatus",
    verifyOTP: "verifyOTP"
};

export const CommonCompanyEndpoints = {
    companyEnrollment: "companyEnrollment",
    createCompany: "createCompany",
    createCompanyData: "createCompanyData",
    deleteCompanyAccount: "deleteCompanyAccount",
    getCompanyInfo: "getCompanyInfo",
    getCompanyInfoData: "getCompanyInfoData",
    getCompanyUserList: "getCompanyUserList",
    updateCompanyInfo: "updateCompanyInfo",
    updateCompanyLogo: "updateCompanyLogo"
};

export const CommonCouriersEndpoints = {
    orderCreation: "orderCreation",
    processCourier: "processCourier"
};

export const CommonPOSEndpoints = {
    clientOrderCreation: "clientOrderCreation",
    clientOrderValidation: "clientOrderValidation",
    fetchProducts: "fetchProducts",
    processStoreStockAndPrices: "processStoreStockAndPrices",
    supplierOrderCreation: "supplierOrderCreation"
};

export const CommonStoresEndpoints = {
    deleteStore: "deleteStore",
    getStoreAllRoles: "getStoreAllRoles",
    getStoreData: "getStoreData",
    getStoreProducts: "getStoreProducts",
    getStoresConnectedFFCByBrand: "getStoresConnectedFFCByBrand",
    getStoresFromCompanies: "getStoresFromCompanies",
    updateStoreInfo: "updateStoreInfo",
    updateStorePOS: "updateStorePOS",
    updateStorePOSProducts: "updateStorePOSProducts",
    updateStoreStaffStatus: "updateStoreStaffStatus"
};

export const CommonOrganizationEndpoints = {
    getOrganizationAccount: "getOrganizationAccount",
    getOrganizationData: "getOrganizationData",
    getOrganizationUsers: "getOrganizationUsers"
};
