import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import Swal from "sweetalert2";
import PreLoader from "../../common/loaders/PreLoader";
import "./../../../assets/css/components/Checkbox.css";
import errorWarning from "./../../../assets/images/error-icon.png";
import errorSuccess from "./../../../assets/images/icons8-check-64.png";
import { APP_TITLE } from "../../../config/constant";
import * as Library from "../../../utils/Library";

createTheme(
    "solarized",
    {
        text: {
            primary: "#D1D1D1",
            secondary: "#D1D1D1"
        },
        background: {
            default: "rgba(0, 0, 0, 0)"
        },
        context: {
            background: "#cb4b16",
            text: "#D1D1D1"
        },
        divider: {
            default: "#333F50"
        },
        action: {
            button: "rgba(0,0,0,.54)",
            hover: "rgba(0,0,0,.08)",
            disabled: "rgba(0,0,0,.12)"
        }
    },
    "dark"
);

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .table-export-buttons {
        float: right;
        margin: 10px 0px;
    }

    .filter-section .form-control {
        width: 100%;
        border-color: #333f50;
        background: var(--Buttons-All-Platforms-Gray-500);
        border-width: 1px;
        height: 40px;
        border-radius: 6px;
        max-width: 300px;
        font-style: italic;
        display: inline-flex;
        color: var(--text-icons-gray-100);
    }

    .filter-section .form-control::placeholder {
        color: var(--text-icons-gray-200);
    }

    .filter-section .form-control:hover,
    .filter-section btn:hover {
        border-color: var(--BOStageG-Red-Outline);
    }

    .filter-btn {
        /* width: 40px; */
        height: 36px;
        font-size: 16px;
        background-color: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-400);
        color: var(--text-icons-gray-100);
    }

    .filter-btn-with-label {
        display: inline-grid;
        width: auto;
        text-align: center;
        font-size: 10px;
        margin-left: 8px;
        top: -1px;
        color: var(--text-icons-gray-300) !important;
        position: relative;
    }

    .filter-btn-with-label > span {
        text-align: center;
        font-size: 10px;
    }

    .filter-btn-with-label > .btn,
    .filter-btn-with-label > .btn:hover,
    .filter-btn-with-label.disabled > .btn,
    .filter-btn-with-label.disabled > .btn:hover {
        opacity: 1;
        background-color: var(--backgrounds-lines-n-400) !important;
        color: var(--text-icons-gray-100);
        border-color: var(--backgrounds-lines-n-400);
    }

    .filter-btn-with-label.disabled > .btn,
    .filter-btn-with-label.disabled > .btn:hover {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .filter-btn-with-label > .btn:hover,
    .filter-btn-with-label.disabled > .btn:hover {
        border-color: var(--BOStageG-Red-Outline);
    }

    .filter-section {
        display: flex;
    }

    .filter-section.right {
        float: right;
    }

    .filter-section > .selects {
        width: 290px;
        margin-right: 40px;
    }

    .filter-section > .selects > div {
        height: 40px;
    }

    .filter-section > p {
        margin-right: 15px;
        padding: 9px 0px;
        color: var(--text-icons-gray-100);
        font-family: "Roboto";
        font-size: 14px;
    }

    .filter-section span {
        color: var(--text-icons-gray-200);
    }

    .showItems .sc-hHLeRK,
    .showItems .sc-hHLeRK.fhIruZ {
        display: block !important;
    }

    .filter-container {
        display: contents;
    }

    // STATUS WILL BE STICKY
    .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2),
    .rdt_TableRow .rdt_TableCell:nth-last-child(2) {
        position: sticky !important;
        background-color: var(--backgrounds-lines-n-600);
        padding: 0 !important;
        right: 80px;
        z-index: 99990;
        justify-content: center;
    }
`;

const NestedCols = styled.tr`
    display: flex;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px !important;
    font-weight: 400;
    font-family: Roboto;
    border-bottom: 1px solid #333f50 !important;
    min-height: 48px;

    .nested-col {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;

        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        line-height: normal;
        padding-left: 16px;
        padding-right: 16px;
        word-break: break-word;
        max-width: 100%;
        min-width: 100px;
    }

    .nested-col > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        color: var(--text-icons-gray-200) !important;
    }

    .nested-col.status-sticky {
        position: sticky !important;
        background-color: var(--backgrounds-lines-n-600);
        padding: 0 !important;
        right: 222px;
        z-index: 99990;
        justify-content: center;
    }

    .nested-col.action-menu {
        position: sticky;
        right: 0;
        padding: 0 !important;
        justify-content: center;
        background-color: var(--backgrounds-lines-n-600);
    }
`;
/* #endregion */

export default function SupplierList(props) {
    document.title = `${APP_TITLE} - Suppliers`;

    /* #region STATES */
    const [currentGroupID, setCurrentGroupID] = useState("");
    const [tooltipImg, setTooltip] = useState(true);
    const [tooltipText, setTooltipText] = useState(true);
    const [supplierList, setSupplierList] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [preLoader, setPreLoader] = useState(true);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const groupID = localStorage.getItem("currentGroupID");
        if (groupID) {
            setCurrentGroupID(groupID);
            getSupplierList(groupID);
        } else {
            setPreLoader(false);
            setDataLoading(false);
        }
    }, [localStorage.getItem("currentGroupID")]);

    useEffect(() => {
        //setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region CONSTANTS */

    const columns = [
        {
            name: "ID",
            selector: row => (row.ourID ? row.ourID : "-"),
            sortable: true,
            width: "130px"
        },
        {
            name: "Name",
            selector: row => (row.name ? row.name : "-"),
            sortable: true
        },
        {
            name: "Contact Email",
            selector: row => (row.contactEmail ? row.contactEmail : "-"),
            sortable: true
        },
        {
            name: "Contact Name",
            selector: row => (row.contactName ? row.contactName : "-"),
            sortable: true,
            width: "180px"
        },
        {
            name: "Brands",
            selector: row => (row?.totalBrands ? row.totalBrands : "-"),
            sortable: true,
            width: "110px"
        },
        {
            name: "Country",
            selector: row => (row?.country ? row.country : "-"),
            sortable: true,
            width: "130px"
        },
        {
            name: "Org. Nr.",
            selector: row => (row.orgNr ? row.orgNr : "-"),
            sortable: true,
            width: "110px"
        },
        {
            name: "Tax Nr.",
            selector: row => (row.taxNr ? row.taxNr : "-"),
            sortable: true,
            width: "110px"
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: false,
            width: "80px",
            cell: row =>
                Number(row.status) === 1 || row.status === "Active" ? (
                    <img src={errorSuccess} alt="sucess" className="exclamation-triangle" />
                ) : (
                    <img src={errorWarning} alt="error" className="exclamation-triangle" />
                )
        },
        {
            name: "Action",
            //selector: row => row.action,
            sortable: false,
            cell: row => (
                <>
                    <Link className="btn btn-success button-not-active" to="#">
                        VIEW
                    </Link>
                    <Link className="btn btn-success button-not-active hidden" to="#">
                        SALES
                    </Link>
                    <Link className="btn btn-success button-not-active hidden" to="#">
                        STOCK
                    </Link>
                </>
            ),
            width: "80px",
            style: {
                justifyContent: "center",
                flexDirection: "row",
                minWidth: "80px"
            }
        }
    ];
    /* #endregion */

    /* #region METHODS */
    const getSupplierList = groupID => {
        setSupplierList([]);
        setPreLoader(false);
        setDataLoading(false);

        const formData = {
            groupID: groupID
        };

        Library.makePostRequest("getSupplierList", formData, false, setPreLoader).then(res => {
            if (res.data.status !== 200) return;

            const data = res.data.data?.tableItems;
            setSupplierList(data);
            setDataLoading(false);

            /* TODO: CHECK WHAT THIS DOES?
            let gtinStatus = [];
            data.map((i) => {
                (i.gtins).map((c) => {
                    if (c.status) {
                        gtinStatus.push(c.status);
                    }
                })
            });*/
        });
    };

    const deleteProduct = (type, ID) => {
        if (type || ID) return;
        Swal.fire({
            text: "Are you sure want to delete this " + type + "?",
            icon: "success",
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#205380",
            denyButtonColor: "#205380",
            confirmButtonText: "Yes",
            denyButtonText: "No",
            background: "#1d2530",
            color: "var(--text-icons-gray-100)",
            iconColor: "#0B0",
            width: "500px",
            padding: "10px"
        }).then(result => {
            if (result.isConfirmed) {
                const formData = {
                    type: type,
                    ID: ID
                };
                Library.makePostRequest("remove-product", formData).then(res => {
                    if (res.data.status === 200) {
                        Library.showSuccessMessage(res.data.message);
                        getSupplierList(currentGroupID);
                    }
                });
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    };

    /*const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);
*/

    /* #endregion */

    /* #region COMPONENTS */
    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
                    <span className="checkmark"></span>
                </label>
            </>
        );
    });

    const ExpandedComponent = ({ data }) => {
        return (
            <table className="table child-table">
                <tbody>
                    {data?.gtins?.map((v, i) => {
                        return (
                            <NestedCols className="nested-row" key={i}>
                                <td
                                    className="nested-col"
                                    style={{ minWidth: "96px", maxWidth: "96px", justifyContent: "right" }}>
                                    <label className="container-checkbox" style={{ float: "right" }}>
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label>
                                </td>
                                <td className="nested-col" style={{ minWidth: "180px", maxWidth: "180px" }}>
                                    <span title={data.productType}>{data.productType}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: "125px", maxWidth: "125px" }}>
                                    <span title={data.brand.name}>{data.brand.name}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: "125px", maxWidth: "125px" }}>
                                    <span title={data.referenceCode}>{data.referenceCode}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: "110px", maxWidth: "110px" }}>
                                    <span title={data.name}>{data.name}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: "110px", maxWidth: "110px" }}>
                                    <span>{data?.category ? data.category : "-"}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: "130px", maxWidth: "130px" }}>
                                    <span title={v?.barcode}>{v?.barcode}</span>
                                </td>
                                <td className="nested-col">
                                    {data?.description ? (
                                        <>
                                            <span
                                                onMouseEnter={() => setTooltipText(true)}
                                                onMouseLeave={() => {
                                                    setTooltipText(false);
                                                    setTimeout(() => setTooltipText(true), 5);
                                                }}
                                                data-tip={data?.description}
                                                data-for="tooltip-text">
                                                <span className="topboadanc tooltip-text" to="#">
                                                    {data?.description}
                                                </span>
                                            </span>
                                        </>
                                    ) : (
                                        <span>-</span>
                                    )}
                                </td>
                                <td className="nested-col" style={{ minWidth: "110px", maxWidth: "110px" }}>
                                    <span>{v?.size}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: "100px", maxWidth: "100px" }}>
                                    <span title={v?.colorCode}>{v?.colorCode}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: "100px", maxWidth: "100px" }}>
                                    <span title={v?.color}>{v?.color}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: "100px", maxWidth: "100px" }}>
                                    <span title={v?.colorCode}>{v?.colorCode}</span>
                                </td>
                                <td className="nested-col status-sticky" style={{ minWidth: "80px", maxWidth: "80px" }}>
                                    {Number(v?.status) === 1 ? (
                                        <img src={errorSuccess} alt="success" className="exclamation-triangle" />
                                    ) : (
                                        <img src={errorWarning} alt="error" className="exclamation-triangle" />
                                    )}
                                </td>
                                <td className="nested-col action-menu" style={{ minWidth: "222px", maxWidth: "222px" }}>
                                    {/*
                                    <div style={{minWidth: "80px", maxWidth: "80px", textAlign: "center"}}>
                                        {(Number(v?.status) === 1) ? <img src={errorSuccess} alt="success" className="exclamation-triangle" /> : <img src={errorWarning} alt="error" className="exclamation-triangle" />}
                                    </div>
                                    */}
                                    <Link className="btn btn-success button-not-active" to="#">
                                        VIEW
                                    </Link>
                                    <Link className="btn btn-success button-not-active" to="#">
                                        SALES
                                    </Link>
                                    <Link className="btn btn-success button-not-active" to="#">
                                        STOCK
                                    </Link>
                                </td>
                            </NestedCols>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const CustomLoader = () => (
        <div className="loaderclass">
            <i className="fa fa-spinner fa-spin"></i>
        </div>
    );
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            {preLoader ? (
                <PreLoader></PreLoader>
            ) : (
                <>
                    {/*
                    <div className="filtersarea">
                        <div className="container-fluid filter-container">
                            <div className="row" style={{ paddingTop: "15px" }}>

                                <div className="col-md-6">
                                    <div className="filter-section">
                                        <input className="form-control" style={{ marginRight: "8px" }} type="text" placeholder="Search list" aria-label="Search" disabled='disabled' />

                                        <span className="filter-btn-with-label disabled">
                                            <button type="button" className="btn btn-success filter-btn disabled">
                                                <i className="fa-thin fa-magnifying-glass"></i>
                                            </button>
                                            <span className="disabled">Go</span>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="filter-section right">
                                        <p>Filters:</p>

                                        <Select className={"selects"}
                                            options={productStylesOptions}
                                            styles={colourStyles}
                                            placeholder={<div className="italic-placeholder">Select...</div>}
                                        />

                                        <span className="filter-container">
                                            <span className="filter-btn-with-label">
                                                <button type="button" className="btn btn-success filter-btn" onClick={() => getSupplierList(currentGroupID)}><i className="fa-thin fa-rotate-left"></i></button>
                                                <span className="">Reset</span>
                                            </span>
                                            <span className="filter-btn-with-label disabled">
                                                <button type="button" className="btn btn-success filter-btn"><i className="fa-thin fa-arrow-right"></i></button>
                                                <span className="disabled">Go</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-export-buttons">
                        <div className="btn-group">
                            <button className="btn main-btn--smaller disabled">Copy</button>
                            <button className="btn main-btn--smaller disabled">CSV</button>
                            <button className="btn main-btn--smaller disabled">PDF</button>
                            <button className="btn main-btn--smaller disabled">Print</button>
                        </div>

                        <span className="filter-btn-with-label">
                            <button type="button" className="btn btn-success filter-btn" onClick={() => getSupplierList(currentGroupID)}><i className="fa-thin fa-arrows-rotate"></i></button>
                            <span className="">Refresh</span>
                        </span>

                        <button type="button" className="btn btn-success main-btn disabled hidden" style={{ marginLeft: "8px" }}>SALES</button>
                        <button type="button" className="btn btn-success main-btn disabled hidden" style={{ marginLeft: "8px" }}>STOCK</button>
                    </div>
                    */}

                    <div className="tablestructure sticky-action">
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Suppliers List</h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <DataTable
                                    columns={columns}
                                    data={supplierList}
                                    theme="solarized"
                                    sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                                    progressPending={dataLoading}
                                    selectableRows
                                    selectableRowsComponent={Checkbox}
                                    defaultSortFieldId={1}
                                    defaultSortAsc={true}
                                    responsive={true}
                                    //expandableRows={true}
                                    expandableRowsComponent={ExpandedComponent}
                                    progressComponent={<CustomLoader />}
                                    //actions={actionsMemo}
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            {tooltipImg ? (
                <ReactTooltip
                    id="tooltip-img"
                    place="top"
                    effect="solid"
                    getContent={dataTip => <img src={dataTip} style={{ height: "250px", width: "auto" }} />}
                />
            ) : (
                ""
            )}
            {tooltipText ? (
                <ReactTooltip
                    className="tooltip-text"
                    html={true}
                    id="tooltip-text"
                    place="top"
                    effect="solid"
                    getContent={dataTip => `${dataTip}`}
                />
            ) : (
                ""
            )}
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Suppliers", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/suppliers", active: true, disabled: false, title: "Suppliers" },
        { to: "/suppliers/create", active: false, disabled: false, title: "New Supplier" },
        { to: "/suppliers/importer", active: false, disabled: false, title: "Importer" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, title: "List", icon: "fa-list" },
        { to: "#", active: false, disabled: true, title: "Grid", icon: "fa-grid" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: false,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
