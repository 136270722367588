import "../../../assets/css/components/MapComponent.css";
import swedenMap from "../../../assets/images/sweden-map.png";
import norwayMap from "../../../assets/images/norway-map.png";
import norwayFlag from "../../../assets/images/norway-flag.png";
import swedenFlag from "../../../assets/images/sweden-flag.png";

const MapComponent = () => {
    return (
        <div className="dashboard-map-container">
            <div className="map-header">
                SALES BY COUNTRY &nbsp; <i class="fa-light fa-circle-info"></i>
            </div>
            <div className="map-wrapper">
                <img src={swedenMap} alt="" className="map-test-images norwary" />
                <img src={norwayMap} alt="" className="map-test-images sweden" />
            </div>

            <div className="images-container">
                <div
                    style={{
                        padding: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",

                        minWidth: "316px"
                    }}>
                    <div style={{ display: "flex", gap: "8px" }}>
                        <img src={norwayFlag} alt="" className="flag-images-test" />
                        <p>Norway</p>
                    </div>

                    <div>
                        <p>60.20%</p>
                    </div>
                </div>
                <div
                    style={{
                        padding: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minWidth: "316px"
                    }}>
                    <div style={{ display: "flex", gap: "8px" }}>
                        <img src={swedenFlag} alt="" className="flag-images-test" />
                        <p>Sweden</p>
                    </div>

                    <div>
                        <p>39.60%</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapComponent;
