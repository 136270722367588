/* eslint-disable jsx-a11y/anchor-is-valid */
import DotsHorizontal from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useQuery } from "react-query";
import styled from "styled-components";
import "../../../assets/css/components/CheckboxDark.css";
import * as Library from "../../../utils/Library";
import CardComponentContainer from "../cardContainers/CardComponentContainer";
import FullScreenLoader from "../loaders/FullScreenLoader";
//import { AppQueryClient } from "../../api/queryClient";
import { Dropdown } from "antd";
import TableListActionDropdown from "../menus/TableListActionDropdown";
import ContainerLoader from "../loaders/ContainerLoader";

const StyledPage = styled.div`
    //ListComponent CSS

    .ListComponent ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    .ListComponent {
        margin-top: -8px;
        //background: var(--backgrounds-lines-n-200);
        //border-radius: 4px;

        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        //padding-right: 16px; //Fixes the horizontal scrollbar
    }

    .ListComponent div {
        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .rdt_Table {
        background: transparent;
    }

    .rdt_TableHead {
        height: 47px;
    }

    .rdt_TableHeadRow {
        background: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-100);
    }

    .rdt_TableHeadRow > div.rdt_TableCol:first-child,
    .rdt_TableRow > div.rdt_TableCell:first-child {
        width: 30px;
        flex: 0 0 30px;
        min-width: 30px;
        margin-left: 8px;
    }

    .rdt_TableBody {
        margin-top: 16px;
        //margin-bottom: 16px;
    }

    .rdt_TableRow {
        background: transparent;
        border: 0;
    }

    .rdt_TableRow:hover {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-600) !important;
    }

    .rdt_TableRow:nth-child(even) {
        border-radius: 4px;
        background: var(--backgrounds-lines-n-300);
    }

    .rdt_TableRow:nth-child(odd) {
        border-radius: 4px;
        background: transparent;
    }

    .rdt_Pagination {
        color: var(--text-icons-gray-100);
        background: transparent;
        border-color: var(--backgrounds-lines-n-100);
    }

    .rdt_TableCol:first-child,
    .rdt_TableCol:last-child,
    .rdt_TableCell:first-child,
    .rdt_TableCell:last-child {
        padding: 0px;
        justify-content: start;
    }

    .rdt_TableCol:first-child,
    .rdt_TableCell:first-child {
        margin-left: 0px;
        margin-right: 8px;
    }

    .rdt_TableCol:not(:first-child),
    .rdt_TableCell:not(:first-child) {
        padding-left: 8px;
        padding-right: 0px;
    }

    .table-structure.sticky-actions .rdt_TableHeadRow .rdt_TableCol:last-child,
    .table-structure.sticky-actions .rdt_TableRow .rdt_TableCell:last-child {
        position: sticky !important;
        right: 0;
        background-color: var(--backgrounds-lines-n-400);
    }

    .table-structure.sticky-actions .rdt_TableRow:nth-child(even) .rdt_TableCell:last-child {
        background: var(--backgrounds-lines-n-300);
    }

    .table-structure.sticky-actions .rdt_TableRow:hover .rdt_TableCell:last-child {
        //border-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        background: var(--backgrounds-lines-n-600) !important;
    }

    .table-structure.sticky-status .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2),
    .table-structure.sticky-status .rdt_TableRow .rdt_TableCell:nth-last-child(2) {
        position: sticky !important;
        right: 36px;
        background-color: var(--backgrounds-lines-n-400);
        width: 87px;
    }

    .table-structure.sticky-status .rdt_TableRow:nth-child(even) .rdt_TableCell:nth-last-child(2) {
        background: var(--backgrounds-lines-n-300);
    }

    .table-structure.sticky-status .rdt_TableRow:hover .rdt_TableCell:nth-last-child(2) {
        background: var(--backgrounds-lines-n-600) !important;
    }

    .table-structure.sticky-status .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2) > div.rdt_tableCol_Sortable,
    .table-structure.sticky-status .rdt_TableRow .rdt_TableCell:nth-last-child(2) > div.rdt_tableCol_Sortable {
        place-content: center;
    }

    .BO-card-body {
        //padding: 0;
    }

    .BO-card-component {
        margin-bottom: 0px !important;
    }
`;

/**
 * A reusable list component that fetches data from an API and displays it in a table format.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - Additional CSS class name for the component.
 * @param {string} props.title - The title of the list component.
 * @param {string} props.title2 - The second title of the list component (opcional).
 * @param {number} props.pageLimit - The number of rows per page.
 * @param {Object} props.api - The API configuration object.
 * @param {string} props.api.endpoint - The API endpoint to fetch data from.
 * @param {Object} props.api.formData - The form data to be sent with the API request.
 * @param {boolean} props.api.useCommonPostRequest - Flag indicating whether to use a common post request or not.
 * @param {Object} props.api.commonContext - The common context for the common post request.
 * @param {Function} props.api.onSuccess - The callback function to be executed on successful API response.
 * @param {string} props.queryKey - The query key for the useQuery hook.
 * @param {Array} props.columns - The columns configuration for the table.
 * @param {Object} props.includeStatus - The configuration for including status column in the table.
 * @param {string} props.includeStatus.statusFromPath - The path to the status value in the data object.
 * @param {Array} props.includeStatus.statusColors - The colors configuration for different status values.
 * @param {boolean} props.includeStatus.stickyStatus - Flag indicating whether to make the status column sticky.
 * @param {string} props.includeStatus.defaultStatusValue - The default status value to be used if the status value is not found.
 * @param {string} props.tableClassName - Additional CSS class name for the table.
 * @param {Object} props.actions - The actions configuration object.
 * @param {boolean} props.actions.includeActions - Flag indicating whether to include actions column in the table.
 * @param {Function} props.actions.onClickRemove - The onClick event handler for the remove action.
 * @param {Function} props.actions.onClickSuspend - The onClick event handler for the suspend action.
 * @param {Function} props.actions.onClickEdit - The onClick event handler for the edit action.
 * @param {boolean} props.actions.editIsVisible - Flag indicating whether to include the edit action or not.
 * @param {boolean} props.actions.suspendIsVisible - Flag indicating whether to include the suspend action or not.
 * @param {boolean} props.actions.removeIsVisible - Flag indicating whether to include the remove action or not.
 * @returns {JSX.Element} The rendered ListComponent.
 */
export default function ListComponent(props) {
    /* #region STATES */
    //const [currentPage, setCurrentPage] = useState(1);
    //const [rowsPerPage, setRowsPerPage] = useState(props?.pageLimit ?? 10);
    const currentPage = 1;
    const rowsPerPage = props?.pageLimit ?? 20;

    const [totalRows, setTotalRows] = useState(0);

    const fetchApiData = async (currentPage, rowsPerPage) => {
        const paginationData = {
            page: currentPage,
            limit: rowsPerPage
        };

        const apiEndpoint = props?.api?.endpoint;
        const apiFormData = { ...paginationData, ...props?.api?.formData };
        const useCommonPostRequest = props?.api?.useCommonPostRequest;
        const apiCommonContext = props?.api?.commonContext;

        let tmp = undefined;
        if (useCommonPostRequest) tmp = await Library.makeCommonPostRequest(apiCommonContext, apiEndpoint, apiFormData);
        else tmp = await Library.makePostRequest(apiEndpoint, apiFormData);

        const res = tmp?.data;
        if (res?.status !== 200) {
            if (![undefined, "ok", "success", ""].includes(res?.message?.toLowerCase())) return undefined;
        }

        let data = res?.data;
        if (props?.api?.onSuccess) data = await props?.api?.onSuccess(data);

        setTotalRows(data.totalCount);
        //setCurrentPage(data.currentPage);

        //console.log("returned", data);
        return data;
    };

    const { data, /*status, error, */ isFetching, refetch, isPreviousData } = useQuery({
        queryKey: [props?.queryKey, rowsPerPage, currentPage], //We need to pass the rowsPerPage and currentPage as queryKey to invalidate the query
        keepPreviousData: true, //Show cached data while fetching new data
        queryFn: async () => fetchApiData(currentPage, rowsPerPage)
    });

    const isFetchingNewData = isFetching && isPreviousData;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!currentPage || !rowsPerPage) return;
        refetch();
    }, [currentPage, rowsPerPage]);
    /* #endregion */

    /* #region METHODS */

    const getColumns = (columns, includeStatusFromValue, statusColors, includeActions) => {
        const includeStatusWithArrayFields = includeStatusFromValue?.split(".");
        if (includeStatusWithArrayFields && statusColors)
            columns = [
                ...columns,
                {
                    name: "Status",
                    selector: row => getStatusColumn(row, includeStatusWithArrayFields, statusColors),
                    minWidth: "87px",
                    maxWidth: "87px"
                }
            ];

        if (includeActions)
            columns = [
                ...columns,
                {
                    name: "",
                    cell: row => (
                        <Dropdown
                            overlay={TableListActionDropdown({
                                edit: {
                                    visible: props?.actions?.editIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickEdit
                                        ? () => props?.actions?.onClickEdit(row)
                                        : undefined
                                },
                                suspend: {
                                    visible: props?.actions?.suspendIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickSuspend
                                        ? () => props?.actions?.onClickSuspend(row)
                                        : undefined,
                                    isReenable: getStatusValue(row)?.toLowerCase() === "suspended" ? true : false
                                },
                                remove: {
                                    visible: props?.actions?.removeIsVisible === false ? false : true,
                                    onClick: props?.actions?.onClickRemove
                                        ? () => props?.actions?.onClickRemove(row)
                                        : undefined
                                }
                            })}
                            trigger={["click"]}>
                            <a className="ant-dropdown-link" style={{ color: "white", cursor: "pointer" }}>
                                <DotsHorizontal height="16" color="#D1D1D1" />
                            </a>
                        </Dropdown>
                    ),
                    width: "36px"
                }
            ];

        return columns;
    };

    const getStatusValue = row => {
        const statusFromPath = props?.includeStatus?.statusFromPath;
        const status = getArrayFieldValue(row, statusFromPath.split(".") || "Unknown");
        //console.log("status", status);
        return status;
    };

    const getStatusColumn = (row, includeStatusWithArrayFields, statusColors) => {
        const statusValue =
            getArrayFieldValue(row, includeStatusWithArrayFields) ||
            props?.includeStatus?.defaultStatusValue ||
            "Unknown";
        let colorValue = "text-orange-500";
        let found = false;

        for (const color of statusColors) {
            for (const value of color.forValues) {
                if (value.toLowerCase() === statusValue.toLowerCase()) {
                    found = true;
                    colorValue = color.colorClass;
                    break;
                }
            }
            if (found) break;
        }

        return (
            <div>
                <div className="flex align-items-center">
                    <div className={`status-dot bg-${colorValue}`}></div>
                    <span className={colorValue}>{statusValue}</span>
                </div>
            </div>
        );
    };

    const getArrayFieldValue = (row, array) => {
        if (!Array.isArray(array)) return "Unknown";

        let res = row;
        for (let i = 0; i < array.length; i++) {
            res = res?.[array[i]];
        }
        return res;
    };

    /* #endregion */

    /* #region COMPONENTS */
    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox" ref={ref} onClick={onClick} {...rest} />
                    <span className="checkmark"></span>
                </label>
            </>
        );
    });

    createTheme("dark", {
        text: {
            primary: "#D1D1D1",
            secondary: "#D1D1D1"
        },
        background: {
            default: "var(--backgrounds-lines-n-400)"
        },
        context: {
            background: "var(--backgrounds-lines-n-400)",
            text: "#D1D1D1"
        },
        divider: {
            default: "var(--backgrounds-lines-n-400)"
        },
        action: {
            button: "var(--backgrounds-lines-n-400)",
            hover: "var(--backgrounds-lines-n-500)",
            disabled: "var(--backgrounds-lines-n-400)"
        }
    });
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <>
                <ContainerLoader
                    className="w-100"
                    isLoading={isFetchingNewData || totalRows === 0}
                    isLoadingOverlay={true}
                    addBlurFilters={false}>
                    <CardComponentContainer
                        className="mb-0"
                        title={`${props?.title || "--"}`}
                        title2={props?.title2 || "(Last 10 Invited)"}
                        loading={isFetching}>
                        <div
                            className={`table-structure
                            ${props?.actions?.includeActions && "sticky-actions"} 
                            ${props?.includeStatus?.stickyStatus && "sticky-status"}`}>
                            <DataTable
                                //onSort={e => console.log("sort", e)} //TODO: This is not implemented yet
                                //defaultSortFieldId={1}
                                //defaultSortAsc={true}
                                columns={getColumns(
                                    props?.columns,
                                    props?.includeStatus?.statusFromPath,
                                    props?.includeStatus?.statusColors,
                                    props?.actions?.includeActions
                                )}
                                className={`ListComponent ${props?.tableClassName || ""}`}
                                theme="dark"
                                data={data}
                                fixedHeader
                                //fixedHeaderScrollHeight="496px"
                                fixedHeaderScrollHeight="calc(100vh - 450px)"
                                selectableRows
                                selectableRowsComponent={Checkbox}
                                responsive={true}
                                dataLoading={isFetchingNewData}
                                pagination={false}
                            />
                        </div>
                    </CardComponentContainer>
                </ContainerLoader>
            </>
        </StyledPage>
    );
}
