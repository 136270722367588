import moment from "moment/moment";

export const formatPrice = (price, coinSymbol = "kr", defaultError = "--") => {
    if (price === null || isNaN(price)) return defaultError;
    price = typeof price === "string" ? price.replace(",", ".") : price.toString().replace(",", ".");

    //split decimal and integer from price
    const priceNonDecimal = price.split(".")[0];
    let priceDecimal = price.split(".")[1];

    //fill the priceDecimal with 0s if it's less than 2 characters
    if (priceDecimal && priceDecimal.length < 2) {
        priceDecimal += "0";
    }

    //format the price to use a dot as a thousand separator
    let formattedPrice = priceNonDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    //if there is a decimal, add it to the formatted price
    formattedPrice = priceDecimal ? formattedPrice + "," + priceDecimal : formattedPrice;

    return formattedPrice + " " + coinSymbol;
};

export const formatPersonFullName = (firstName, lastName, defaultValue = "--") => {
    let result = defaultValue;
    if (firstName && lastName) result = firstName + " " + lastName;
    else if (firstName) result = firstName;
    else if (lastName) result = lastName;
    else result = defaultValue;

    return result;
};

export const formatDefaultDateTime = date => {
    try {
        if (!date || date === "-") return "--";
        const res = moment(date)?.format("DD/MM/YYYY HH:mm") ?? "--";
        if (res === "Invalid date") return "--";
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return "--";
    }
};

export const formatDefaultDate = date => {
    try {
        if (!date || date === "-") return "--";
        const res = moment(date)?.format("DD/MM/YYYY") ?? "--";
        if (res === "Invalid date") return "--";
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return "--";
    }
};
