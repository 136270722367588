import { useInfiniteQuery, useMutation } from "react-query";
import { axiosInstance } from "../axios";

export const useGetStoresNameMutation = onSuccess => {
    return useMutation({
        mutationKey: ["get-label-brands"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`getStoresToInvite`, payload);
            return data;
        },
        onSuccess: onSuccess
    });
};
export const useInviteStoreMutation = onSuccess => {
    return useMutation({
        mutationKey: ["invite-label"],
        mutationFn: async payload => {
            const { data } = await axiosInstance.post(`inviteStoreToGroup`, payload);
            return data;
        },
        onSuccess: onSuccess
    });
};

export const useGetGroupInvitedMutation = () => {
    return useMutation({
        mutationKey: ["invite-label"],
        mutationFn: async payload => {
            const { data, status } = await axiosInstance.post(`getGroupInvitedStoresData`, payload);
            return { data, status };
        }
    });
};

export const useGetStoreContactInfoMutation = () => {
    return useMutation({
        mutationKey: ["store-info"],
        mutationFn: async payload => {
            const { data, status } = await axiosInstance.post(`getStoreContactInfo`, payload);
            return { data, status };
        }
    });
};
