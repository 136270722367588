import CreateSupplier from "../components/pages/suppliers/CreateSupplier";
import SupplierBrandImporter from "../components/pages/suppliers/SupplierBrandImporter";
import SupplierList from "../components/pages/suppliers/SupplierList";
import SupplierImporter from "../components/pages/suppliers/SupplierImporter";
import BrandsPage from "../components/pages/brands/BrandsPage";
import Invite from "../components/pages/brands/Invite";

const routes = [
    {
        path: "/brands",
        RouteComponent: BrandsPage
    },
    {
        path: "/brands/invite",
        RouteComponent: Invite
    },
    {
        path: "/suppliers",
        RouteComponent: SupplierList
    },
    {
        path: "/suppliers/create",
        RouteComponent: CreateSupplier
    },
    {
        path: "/suppliers/importer",
        RouteComponent: SupplierImporter
    },
    {
        path: "/suppliers/brandimporter",
        RouteComponent: SupplierBrandImporter
    }
];

export default routes;
