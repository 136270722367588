import "../../../assets/css/components/GrossMargin.css";
import GrossMarginGraphComponent from "../../pages/dashboardGraphs/GrossMarginGraphComponent";
import { GrossMarginChartOptions } from "../../../utils/utilHelpers";

const GrossMargin = ({ GrossMarginChartSeries }) => {
    return (
        <div className="gross-margin-main-container">
            <p className="gross-margin-header">
                OVERALL GROSS MARGIN &nbsp; <i class="fa-light fa-circle-info"></i>
            </p>

            <div className="gross-margin-graph-container">
                <div className="gross-margin-content-container">
                    <h2>{Math.ceil(Math.random() * 100)}%</h2>
                    <p>--% compared to set period</p>
                </div>
                <div>
                    <GrossMarginGraphComponent
                        type="line"
                        height="28px"
                        options={GrossMarginChartOptions}
                        series={GrossMarginChartSeries}
                    />
                </div>
            </div>

            <div className="gross-margin-hr-container">
                <div className="gross-margin-hr"></div>
            </div>
        </div>
    );
};

export default GrossMargin;
