// import { Tooltip } from "antd";
import { useState } from "react";
import Tooltips from "../tooltip/Tooltips";

/**
 * A simple form component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.titleColor - The color of the title.
 * @param {string} props.placeholder - The placeholder text for the input.
 * @param {boolean} props.border - Whether to display a border around the input.
 * @param {string} props.borderColor - The color of the border.
 * @param {string} props.assistiveText - The text for the assistive message.
 * @param {string} props.assistiveColor - The color of the assistive message.
 * @param {string} props.placeholderColor - The color of the input placeholder.
 * @param {string} props.titleName - The name of the title.
 * @param {boolean} props.padding - Whether to add padding to the input.
 * @param {string} props.backgroundColor - The background color of the input.
 * @param {string} props.inputColor - The color of the input text.
 * @param {string} props.maxWidth - The maximum width of the input.
 * @param {string} props.iconDisplay - The display style for the clear button icon.
 * @param {function} props.onChange - The event handler for input change.
 * @param {string} props.value - The value of the input.
 * @param {Object} props.style - Additional inline styles for the input.
 * @returns {JSX.Element} The rendered form component.
 */
const FormSimpleComponent = ({
    titleColor,
    placeholder,
    border,
    borderColor,
    assistiveText,
    assistiveColor,
    placeholderColor,
    titleName,
    padding,
    backgroundColor,
    inputColor,
    maxWidth = "394px",
    iconDisplay,
    onChange,
    value,
    style,
    disabledValue,
    tooltipTitle
}) => {
    //color of placeholder and border color is same
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = event => {
        setInputValue(event.target.value);
    };

    const clearInput = () => {
        setInputValue("");
    };

    return (
        <div>
            <div className="form-container-main" style={{ width: "100%", maxWidth: maxWidth }}>
                <div className={"form-container-first"} style={{ width: "100%", maxWidth: maxWidth }}>
                    <Tooltips
                        title={tooltipTitle}
                        children={
                            <i
                                className="fas fa-info-circle"
                                aria-hidden="true"
                                style={{ fontSize: "10px", fontWeight: "500", color: titleColor }}></i>
                        }></Tooltips>
                    &nbsp;
                    <span
                        style={{
                            color: titleColor,
                            display: "inline-block",
                            width: "100%",
                            maxWidth: maxWidth
                        }}>
                        <span style={{ fontSize: "0.75rem" }}>{titleName}</span>
                    </span>
                </div>

                <div className={"form-container-second"}>
                    <input
                        type="text"
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                        disabled={disabledValue}
                        style={style}
                    />
                    {inputValue && (
                        <button className="clear-btn" onClick={clearInput}>
                            <i className="fa-regular fa-x" style={{ display: iconDisplay }}></i>
                        </button>
                    )}
                </div>
                {assistiveText && (
                    <div className={"form-container-third"}>
                        {assistiveText === "true" ? (
                            <small style={{ color: assistiveColor }}>ASSISTIVE TEXT</small>
                        ) : (
                            ""
                        )}
                    </div>
                )}
            </div>
            <style>
                {`
          input::placeholder {
            color: ${placeholderColor}; 
          }
          input:focus{
            border:1px solid ${placeholderColor}
          }
          .form-container-second > input{
            padding:${padding === "true" ? "15px 5px 55px 15px" : ""}
          }
        `}
            </style>
        </div>
    );
};

export default FormSimpleComponent;
