import { Navigate } from "react-router-dom";
import { APP_HOMEPAGE_ROUTE } from "../config/constant";

export default function PublicRoute({ children }) {
    const auth = localStorage.getItem("token");
    const status = localStorage.getItem("status");

    return auth ? (
        <Navigate to={status === "Completed" || parseInt(status) === 100 ? APP_HOMEPAGE_ROUTE : "/"} />
    ) : (
        children
    );
}
