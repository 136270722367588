import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useInfiniteQuery } from "react-query";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import ProductCategoryGridCard from "../gridCards/ProductCategoryGridCard";
import * as Library from "../../../utils/Library";
import { useNavigate } from "react-router";
import moment from "moment/moment";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/* #endregion */

/**
 * Renders an infinite grid of brand cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - The additional CSS class name for the component.
 * @param {number} [props.limit=10] - The maximum number of cards to load per page.
 * @param {string} [props.queryKey] - The query key for fetching data.
 * @param {number} [props.minimumCardsFill] - The minimum number of cards to fill the grid.
 * @param {number} [props.minColumnWidth] - The minimum width of each grid column.
 * @param {Function} [props.onEditAction] - The callback function for the edit action.
 * @param {Function} [props.onDeleteAction] - The callback function for the delete action.
 * @param {Function} [props.onSuspendAction] - The callback function for the suspend action.
 * * @param {string} [props.statusFromPath] - The status from the path.
 * @returns {JSX.Element} The rendered component.
 */

export default function InfinityGridProductsCategoryCards(props) {
    const pageLimit = props?.limit || 8;

    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [queryKey, setQueryKey] = useState(props?.queryKey ?? uuidv4());
    const [minimumCardsFill, setminimumCardsFill] = useState(props?.minimumCardsFill || pageLimit);

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getGroupCategoriesData",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            limit: pageLimit
        },
        () => {
            setScroll(true);
        }
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        //console.log("scrolling");
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            //console.log("fetching next page");
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    const formatIsoToDateTime = isoDate => {
        if (!isoDate) return "--";
        const res = moment(isoDate).format("DD/MM/YYYY HH:mm");
        if (res === "Invalid date") return "--";
        return res;
    };

    const getStatusValue = row => {
        const statusFromPath = props?.statusFromPath;
        const status = getArrayFieldValue(row, statusFromPath.split(".") || "Unknown");
        //console.log("status", status);
        return status;
    };

    const getArrayFieldValue = (row, array) => {
        if (!Array.isArray(array)) return "Unknown";

        let res = row;
        for (let i = 0; i < array.length; i++) {
            res = res?.[array[i]];
        }
        return res;
    };

    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: 8 }, () => (
                        <CardGridSkeleton
                            key={uuidv4()}
                            height="411px"
                            minColumnWidth={props?.minColumnWidth || "395px"}
                            imageHeight="174px"
                        />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            return (
                                <ProductCategoryGridCard
                                    key={uuidv4()}
                                    image={item?.image}
                                    title={item?.category}
                                    id={item?.botagID}
                                    _id={item?._id}
                                    isCardEnabled={getStatusValue(item)?.toLowerCase() !== "suspended"}
                                    isCardSuspended={getStatusValue(item)?.toLowerCase() === "suspended"}
                                    description={item?.description}
                                    data={item?.productsIncluding}
                                    edit={{
                                        active: "active",
                                        onClick: () => navigate(`/products/categories/${item?._id}`)
                                    }}
                                    suspend={{
                                        isReenable: getStatusValue(item)?.toLowerCase() === "suspended",
                                        onClick: () => (props?.onSuspendAction ? props?.onSuspendAction(item) : null)
                                    }}
                                    delete={
                                        props?.onDeleteAction ? { onClick: () => props?.onDeleteAction(item) } : null
                                    }
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, () => (
                            <ProductCategoryGridCard key={uuidv4()} isCardEnabled={false} />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, () => (
                            <CardGridSkeleton
                                key={uuidv4()}
                                height="411px"
                                minColumnWidth={props?.minColumnWidth || "395px"}
                                imageHeight="174px"
                            />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
