/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { APP_TITLE } from "../../../config/constant";
import * as Library from "../../../utils/Library";
import CardComponent from "../../common/cardContainers/CardComponentContainer";
import CardCounter from "../../common/gridCards/generic/CardCounter";
import BrandsGridCard from "../../common/gridCards/BrandsGridCard";
import CompanyGridCard from "../../common/gridCards/CompanyGridCard";
import FFCGridCard from "../../common/gridCards/FFCGridCard";
import StoresGridCard from "../../common/gridCards/StoresGridCard";
import UsersGridCard from "../../common/gridCards/UsersGridCard";
import AccountDetailsCard from "../../common/gridCards/AccountDetailsCard";
import { useNavigate } from "react-router";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import { defaultStatusColors, getSpanWithColor, getStatusValue } from "../../../utils/CardUtils";
import { formatDefaultDateTime, formatPersonFullName } from "../../../utils/Formatter";
import { CommonContexts, CommonOrganizationEndpoints } from "../../../config/commonEnum";
import CardGridSkeleton from "../../common/gridCards/animations/CardGridSkeleton";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .dropdownLink:hover {
        background: var(--Buttons-All-Platforms-Gray-300);
        border: 1px solid var(--BOStageG-Red-Outline);
        border-radius: 4px;
    }

    .dropdownLink {
        margin: 0px 8px;
        border: 1px solid transparent;
    }
`;

const StyledCards = styled.div`
    & {
        overflow-y: hidden;
        //height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    &.page-no-scroll {
        overflow-y: hidden;
        //height: 100%;
    }
`;

/* #endregion */

export default function AccountPageDetails(props) {
    document.title = `${APP_TITLE} - Account Details`;

    /* #region STATES */
    const navigate = useNavigate();
    const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [organizationData, setOrganizationData] = useState([]);
    const [preLoader, setPreLoader] = useState(false);

    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [loadingBrands, setLoadingBrands] = useState(false);
    const [loadingStores, setLoadingStores] = useState(false);
    const [loadingFFCs, setLoadingFFCs] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const [companiesData, setCompaniesData] = useState([]);
    const [brandsData, setBrandsData] = useState([]);
    const [storesData, setStoresData] = useState([]);
    const [FFCsData, setFFCsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const userID = localStorage.getItem("token");
        const organizationID = localStorage.getItem("currentOrganizationID");
        if (!userID || !viewLayoutStyle || !organizationID) return;

        getOrganizationData(userID);
    }, [localStorage.getItem("currentOrganizationID"), localStorage.getItem("token"), viewLayoutStyle]);

    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);
    /* #endregion */

    /* #region METHODS */
    const getOrganizationData = userID => {
        const formData = {
            id: userID
        };

        Library.makePostRequest("getOrganizationData", formData, false, setPreLoader).then(res => {
            if (res.data.status !== 200) return;

            const data = res.data?.data;
            setOrganizationData(data);
            setPreLoader(false);

            requestRemainingData();
        });
    };

    const requestRemainingData = () => {
        const formDataLimit10 = {
            organizationID: localStorage.getItem("currentOrganizationID"),
            companyID: localStorage.getItem("currentCompanyID"),
            limit: 10,
            page: 1
        };

        const formDataLimit12 = {
            organizationID: localStorage.getItem("currentOrganizationID"),
            companyID: localStorage.getItem("currentCompanyID"),
            limit: 12,
            page: 1
        };

        Library.makePostRequest("getCompaniesData", formDataLimit10, false, setLoadingCompanies).then(res => {
            if (res.data.status !== 200) return;

            const data = res.data?.data?.result;
            setCompaniesData(data);
        });

        Library.makePostRequest("getGroupLabelBrandsData", formDataLimit10, false, setLoadingBrands).then(res => {
            if (res.data.status !== 200) return;

            const data = res.data?.data?.result;
            setBrandsData(data);
        });

        Library.makePostRequest("getGroupStoresData", formDataLimit10, false, setLoadingStores).then(res => {
            if (res.data.status !== 200) return;

            const data = res.data?.data?.result;
            setStoresData(data);
        });

        Library.makePostRequest("getFulfillmentCentersData", formDataLimit12, false, setLoadingFFCs).then(res => {
            if (res.data.status !== 200) return;

            const data = res.data?.data?.result;
            setFFCsData(data);
        });

        Library.makeCommonPostRequest(
            CommonContexts.ORGANIZATION,
            CommonOrganizationEndpoints.getOrganizationUsers,
            formDataLimit12,
            false,
            setLoadingUsers
        ).then(res => {
            if (res.data.status !== 200) return;

            const data = res.data?.data?.result;
            setUsersData(data);
        });
    };
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`} style={{ marginTop: " 18px" }}>
            <>
                <ContainerLoader
                    isLoading={preLoader}
                    isLoadingOverlay={false}
                    className="w-100"
                    loadingPositionFromTop="300px">
                    <div className="w-100">
                        <CardComponent title="Account" spacing="24px">
                            {getAccountDetailsUI(organizationData, navigate)}
                        </CardComponent>

                        <CardComponent
                            spacing="24px"
                            title="Companies"
                            seeMore={{ visible: true, to: "/companies" }}
                            loading={loadingCompanies}>
                            {getCompaniesUI(companiesData, loadingCompanies)}
                        </CardComponent>

                        <CardComponent
                            spacing="24px"
                            title="Brands"
                            seeMore={{ visible: true, to: "/brands" }}
                            loading={loadingBrands}>
                            {getBrandsUI(brandsData, loadingBrands)}
                        </CardComponent>

                        <CardComponent
                            spacing="24px"
                            title="Stores"
                            seeMore={{ visible: true, to: "/stores" }}
                            loading={loadingStores}>
                            {getStoresUI(storesData, loadingStores)}
                        </CardComponent>

                        <CardComponent
                            spacing="24px"
                            title="FFCenters"
                            seeMore={{ visible: true, to: "/ffcenters" }}
                            loading={loadingFFCs}>
                            {getFFCsUI(FFCsData, loadingFFCs)}
                        </CardComponent>

                        <CardComponent
                            spacing="24px"
                            title="Users"
                            seeMore={{ visible: true, to: "/account/users" }}
                            loading={loadingUsers}>
                            {getUsersUI(usersData, loadingUsers)}
                        </CardComponent>
                    </div>
                </ContainerLoader>
            </>
        </StyledPage>
    );
}

/* #region OTHER COMPONENTS */
const getAccountDetailsUI = (organizationData, navigate) => {
    return (
        <div className="flex-row gap16">
            <div className="flex-col col-1 w-100">
                {/* <AccountDetailsCard /> */}
                <AccountDetailsCard
                    isCardEnabled={true}
                    title={organizationData?.OrganizationInfo?.name}
                    id={organizationData?.OrganizationInfo?.ourID}
                    image={organizationData?.OrganizationInfo?.mainImageUrl}
                    profileImage={organizationData?.OrganizationInfo?.compactImageUrl}
                    details={[
                        {
                            key: "Owner:",
                            value: formatPersonFullName(
                                organizationData?.OrganizationOwnerAccount?.BOstagesAccountID?.firstName,
                                organizationData?.OrganizationOwnerAccount?.BOstagesAccountID?.lastName
                            )
                        },
                        {
                            key: "Email:",
                            value: organizationData?.OrganizationOwnerAccount?.BOstagesAccountID?.email || "--"
                        },
                        {
                            key: "Mobile:",
                            value: organizationData?.OrganizationOwnerAccount?.BOstagesAccountID?.phoneNumber || "--"
                        },
                        {
                            key: "Last login:",
                            value: "--"
                        },
                        {
                            key: "Admin:",
                            value: formatPersonFullName(
                                organizationData?.OrganizationAdminAccount?.BOstagesAccountID?.firstName,
                                organizationData?.OrganizationAdminAccount?.BOstagesAccountID?.lastName
                            )
                        },
                        {
                            key: "Email:",
                            value: organizationData?.OrganizationAdminAccount?.BOstagesAccountID?.email || "--"
                        },
                        {
                            key: "Mobile:",
                            value: organizationData?.OrganizationAdminAccount?.BOstagesAccountID?.phoneNumber || "--"
                        },
                        {
                            key: "Last login:",
                            value: "--"
                        },

                        {
                            key: "2FA:",
                            value: organizationData?.OrganizationOwnerAccount?.BOstagesAccountID?.twoFA || (
                                <span className="text-orange-500">Disabled</span>
                            )
                        },
                        {
                            key: "Timezone:",
                            value: organizationData?.OrganizationInfo?.timeZoneID?.name || "--"
                        },
                        {
                            key: "Country:",
                            value: organizationData?.OrganizationInfo?.countryID?.name || "--"
                        },
                        {
                            key: "Status:",
                            value: getSpanWithColor(
                                organizationData?.OrganizationOwnerAccount?.BOstagesAccountID?.statusEnum,
                                defaultStatusColors
                            )
                        }
                    ]}
                />
            </div>

            <div className="flex-col col-1 w-100">
                <div className="flex-container gap16">
                    <div className="flex-row h-50">
                        <div className="flex-col col-1 w-100">
                            <CardCounter
                                title="Companies"
                                //total={organizationData?.CompaniesList?.length || "0"}
                                total={localStorage.getItem("activeCompanies") || "--"}
                            />
                        </div>
                        <div className="flex-col col-1 w-100">
                            <CardCounter
                                title="Stores"
                                addButton={{
                                    visible: true,
                                    onClick: () => {
                                        navigate("/stores/invite");
                                    }
                                }}
                                //total={organizationData?.StoresList?.length || "0"}
                                total={localStorage.getItem("activeStores") || "--"}
                            />
                        </div>
                        <div className="flex-col col-1 w-100">
                            <CardCounter
                                title="Brands"
                                addButton={{
                                    visible: true,
                                    onClick: () => {
                                        navigate("/brands/invite");
                                    }
                                }}
                                //total={organizationData?.BrandsList?.length || "0"}
                                total={localStorage.getItem("activeBrands") || "--"}
                            />
                        </div>
                    </div>
                    <div className="flex-row h-50">
                        <div className="flex-col col-1 w-100">
                            <CardCounter
                                title="Retailers"
                                //total={organizationData?.RetailBrandsList?.length || "0"}
                                total={organizationData?.Counters?.RetailBrands || "--"}
                            />
                        </div>
                        <div className="flex-col col-1 w-100">
                            <CardCounter
                                title="FFCenters"
                                //total={organizationData?.FFCList?.length || "0"}
                                total={localStorage.getItem("activeFFCenters") || "--"}
                            />
                        </div>
                        <div className="flex-col col-1 w-100">
                            <CardCounter
                                title="Users"
                                addButton={{ visible: true }}
                                //total={organizationData?.UsersList?.length || "0"}
                                total={organizationData?.Counters?.Users || "--"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const getCompaniesUI = (data, isLoading) => {
    try {
        const layoutData = data || [];
        const length = layoutData.length;
        const placeholderCount = 10 - length;
        const placeHolderArray = Array(placeholderCount).fill("");

        const minColumnWidth = "370px";
        const gridLayoutStyle = {
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`,
            gap: "24px",
            height: "424px"
        };

        return (
            <StyledCards className={"page-no-scroll"}>
                {isLoading ? (
                    <div style={gridLayoutStyle}>
                        {Array.from({ length: 10 }, () => (
                            <CardGridSkeleton key={uuidv4()} className="borders" height="420.28px" hasBorders={true} />
                        ))}
                    </div>
                ) : (
                    <div className="" style={gridLayoutStyle}>
                        {layoutData?.map(item => {
                            return (
                                <div className="flex-col flex-1" key={uuidv4()}>
                                    <CompanyGridCard
                                        className="w-100"
                                        hasBorders={true}
                                        key={uuidv4()}
                                        image={item?.logoURL}
                                        titlePrefix="COMPANY"
                                        title={item?.name}
                                        id={item?.ourID}
                                        isCardEnabled={getStatusValue(item, "status")?.toLowerCase() !== "suspended"}
                                        isCardSuspended={getStatusValue(item, "status")?.toLowerCase() === "suspended"}
                                        //isCardSuspended={status?.toLowerCase() === "suspended"} //TODO: needed to check suspended status?
                                        data={{
                                            registrationNumber: item?.registrationNumber,
                                            taxNr: item?.taxNr,
                                            phoneNr: item?.phoneNr,
                                            address: item?.address,
                                            companyAdmin: item?.companyAdmin,
                                            nrOfBrands: item?.nrOfBrands,
                                            nrOfStores: item?.nrOfStores,
                                            nrOfUsers: item?.nrOfUsers
                                        }}
                                        primaryButton={{
                                            title: "DETAILS"
                                        }}
                                        secondaryButton={{
                                            title: "ADD USERS"
                                            /* onClick: () => {
                                            navigate("/companies/users");
                                        } */
                                        }}
                                    />
                                </div>
                            );
                        })}

                        {!isLoading &&
                            placeHolderArray?.map(() => {
                                return (
                                    <div className="flex-col flex-1" key={uuidv4()}>
                                        <CompanyGridCard className="w-100" hasBorders={true} />
                                    </div>
                                );
                            })}
                    </div>
                )}
            </StyledCards>
        );
    } catch (e) {
        return "";
    }
};

const getBrandsUI = (data, isLoading) => {
    try {
        const layoutData = data || [];
        const length = layoutData.length;
        const placeholderCount = 10 - length;
        const placeHolderArray = Array(placeholderCount).fill("");

        const minColumnWidth = "370px";
        const gridLayoutStyle = {
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`,
            gap: "24px",
            height: "424px"
        };

        return (
            <StyledCards className={"page-no-scroll"}>
                {isLoading ? (
                    <div style={gridLayoutStyle}>
                        {Array.from({ length: 10 }, () => (
                            <CardGridSkeleton key={uuidv4()} className="borders" height="420.28px" hasBorders={true} />
                        ))}
                    </div>
                ) : (
                    <div className="" style={gridLayoutStyle}>
                        {layoutData?.map(item => {
                            return (
                                <div className="flex-col flex-1" key={uuidv4()}>
                                    <BrandsGridCard
                                        className="w-100"
                                        hasBorders={true}
                                        image={item?.logoURL}
                                        title={item?.name}
                                        id={item?.ourID}
                                        isCardEnabled={getStatusValue(item, "status")?.toLowerCase() !== "suspended"}
                                        isCardSuspended={getStatusValue(item, "status")?.toLowerCase() === "suspended"}
                                        data={{
                                            cityCountry: (item?.city ?? "--") + " , " + (item?.country ?? "--"),
                                            invitedBy: item?.invitedBy,
                                            joinedOn: formatDefaultDateTime(item?.joinedOn),
                                            ffcentersInGroup: (item?.FFCenters ?? "--") + "/" + (item?.InGroup ?? "--"),
                                            status: item?.status,
                                            contactPerson: item?.contactPerson,
                                            phoneNumber: item?.phoneNumber,
                                            contactEmail: item?.contactEmail,
                                            country: item?.country
                                        }}
                                        /* edit={props?.onEditAction ? { onClick: () => props?.onEditAction(item) } : null}
                                        suspend={{
                                            isReenable: item?.status?.toLowerCase() === "suspended",
                                            onClick: () => (props?.onSuspendAction ? props?.onSuspendAction(item) : null)
                                        }}
                                        delete={
                                            props?.onDeleteAction ? { onClick: () => props?.onDeleteAction(item) } : null
                                        } */
                                        primaryButton={{ title: "Details" }}
                                        secondaryButton={{ title: "Fulfillment Centers" }}
                                    />
                                </div>
                            );
                        })}

                        {!isLoading &&
                            placeHolderArray?.map(() => {
                                return (
                                    <div className="flex-col flex-1" key={uuidv4()}>
                                        <BrandsGridCard className="w-100" hasBorders={true} />
                                    </div>
                                );
                            })}
                    </div>
                )}
            </StyledCards>
        );
    } catch (e) {
        return "";
    }
};

const getStoresUI = (data, isLoading) => {
    try {
        const layoutData = data || [];
        const length = layoutData.length;
        const placeholderCount = 10 - length;
        const placeHolderArray = Array(placeholderCount).fill("");

        const minColumnWidth = "370px";
        const gridLayoutStyle = {
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`,
            gap: "24px",
            height: "424px"
        };

        return (
            <StyledCards className={"page-no-scroll"}>
                {isLoading ? (
                    <div style={gridLayoutStyle}>
                        {Array.from({ length: 10 }, () => (
                            <CardGridSkeleton key={uuidv4()} className="borders" height="420.28px" hasBorders={true} />
                        ))}
                    </div>
                ) : (
                    <div className="" style={gridLayoutStyle}>
                        {layoutData?.map(item => {
                            return (
                                <div className="flex-col flex-1" key={uuidv4()}>
                                    <StoresGridCard
                                        className="w-100"
                                        hasBorders={true}
                                        key={uuidv4()}
                                        image={item?.logoURL}
                                        title={item?.name}
                                        id={item?.ourID}
                                        isCardEnabled={getStatusValue(item, "status")?.toLowerCase() !== "suspended"}
                                        isCardSuspended={getStatusValue(item)?.toLowerCase() === "suspended"}
                                        data={{
                                            cityCountry: (item?.city ?? "--") + " , " + (item?.country ?? "--"),
                                            invitedBy: item?.invitedBy,
                                            joinedOn: formatDefaultDateTime(item?.joinedOn),
                                            brandsInGroup: (item?.Brands ?? "--") + "/" + (item?.InGroup ?? "--"),

                                            POSsystem: item?.POSsystem,
                                            POSstatus: item?.POSstatus,
                                            LastPing: formatDefaultDateTime(item?.LastPing),
                                            status: item?.status
                                        }}
                                        /* edit={props?.onEditAction ? { onClick: () => props?.onEditAction(item) } : null}
                                    delete={
                                        props?.onDeleteAction ? { onClick: () => props?.onDeleteAction(item) } : null
                                    }
                                    suspend={{
                                        isReenable: item?.status?.toLowerCase() === "suspended",
                                        onClick: () => (props?.onSuspendAction ? props?.onSuspendAction(item) : null)
                                    }} */
                                        primaryButton={{ title: "Details" }}
                                        secondaryButton={{ title: "Fulfillment Centers" }}
                                    />
                                </div>
                            );
                        })}

                        {!isLoading &&
                            placeHolderArray?.map(() => {
                                return (
                                    <div className="flex-col flex-1" key={uuidv4()}>
                                        <StoresGridCard className="w-100" hasBorders={true} />
                                    </div>
                                );
                            })}
                    </div>
                )}
            </StyledCards>
        );
    } catch (e) {
        return "";
    }
};

const getFFCsUI = (data, isLoading) => {
    try {
        const layoutData = data || [];
        const length = layoutData.length;
        const placeholderCount = 12 - length;
        const placeHolderArray = Array(placeholderCount).fill("");

        const minColumnWidth = "285px";
        const gridLayoutStyle = {
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`,
            gap: "24px",
            height: "424px"
        };

        return (
            <StyledCards className={"page-no-scroll"}>
                {isLoading ? (
                    <div style={gridLayoutStyle}>
                        {Array.from({ length: 12 }, () => (
                            <CardGridSkeleton key={uuidv4()} className="borders" height="420.28px" hasBorders={true} />
                        ))}
                    </div>
                ) : (
                    <div className="" style={gridLayoutStyle}>
                        {layoutData?.map(item => {
                            return (
                                <div className="flex-col flex-1" key={uuidv4()}>
                                    <FFCGridCard
                                        className="w-100"
                                        hasBorders={true}
                                        key={uuidv4()}
                                        image={item?.storePictureURL}
                                        imageOverlay={item?.labelBrandPictureURL}
                                        title={item?.storeName}
                                        id={item?.BOtagID}
                                        isCardEnabled={getStatusValue(item, "status")?.toLowerCase() !== "suspended"}
                                        isCardSuspended={item?.status?.toLowerCase() === "suspended"}
                                        data={{
                                            retailBrand: item?.retailBrand,
                                            affiliatedTo: item?.affiliatedTo,
                                            acceptedOn: item?.acceptedOn,
                                            status: item?.status
                                        }}
                                        primaryButton={{ title: "Details" }}
                                        secondaryButton={{ title: "Stock" }}
                                    />
                                </div>
                            );
                        })}

                        {!isLoading &&
                            placeHolderArray?.map(() => {
                                return (
                                    <div className="flex-col flex-1" key={uuidv4()}>
                                        <FFCGridCard className="w-100" hasBorders={true} />
                                    </div>
                                );
                            })}
                    </div>
                )}
            </StyledCards>
        );
    } catch (e) {
        return "";
    }
};

const getUsersUI = (data, isLoading) => {
    try {
        const layoutData = data || [];
        const length = layoutData.length;
        const placeholderCount = 12 - length;
        const placeHolderArray = Array(placeholderCount).fill("");

        const minColumnWidth = "285px";
        const gridLayoutStyle = {
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`,
            gap: "24px",
            height: "424px"
        };

        return (
            <StyledCards className={"page-no-scroll"}>
                {isLoading ? (
                    <div style={gridLayoutStyle}>
                        {Array.from({ length: 12 }, () => (
                            <CardGridSkeleton key={uuidv4()} className="borders" height="420.28px" hasBorders={true} />
                        ))}
                    </div>
                ) : (
                    <div className="" style={gridLayoutStyle}>
                        {layoutData?.map(item => {
                            return (
                                <div className="flex-col flex-1" key={uuidv4()}>
                                    <UsersGridCard
                                        className="w-100"
                                        hasBorders={true}
                                        image={item?.BOstagesAccountID?.profilePhotoUrl}
                                        title={formatPersonFullName(
                                            item?.BOstagesAccountID?.firstName,
                                            item?.BOstagesAccountID?.lastName
                                        )}
                                        id={item?.BOstagesAccountID?.ourID}
                                        isCardEnabled={getStatusValue(item, "status")?.toLowerCase() !== "suspended"}
                                        isCardSuspended={getStatusValue(item, "status")?.toLowerCase() === "suspended"}
                                        data={{
                                            role: item?.role,
                                            account: item?.organizationID?.name,
                                            enrolledOn: formatDefaultDateTime(item?.BOstagesAccountID?.createdAt),
                                            currentStatus: item?.status
                                        }}
                                    />
                                </div>
                            );
                        })}

                        {!isLoading &&
                            placeHolderArray?.map(() => {
                                return (
                                    <div className="flex-col flex-1" key={uuidv4()}>
                                        <UsersGridCard className="w-100" hasBorders={true} />
                                    </div>
                                );
                            })}
                    </div>
                )}
            </StyledCards>
        );
    } catch (e) {
        return "";
    }
};
/* #endregion */

function setupHeaders(props) {
    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([
        { to: "/account", active: true, disabled: false, title: "Account" },
        { to: "/account/users", active: false, disabled: false, title: "Users" }
    ]);
    props.setControlBarRightBtns([
        //{ to: "/account/", active: false, disable: false, title: "View", icon: "eye" },
        { to: "/account/details", active: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: true,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
