import React, { useEffect, useRef, useState } from "react";
import FormSimpleComponent from "../../common/forms/FormSimpleComponent";

// import { Tooltip } from "antd";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    useGetGroupInvitedMutation,
    useGetStoreContactInfoMutation,
    useGetStoresNameMutation,
    useInviteStoreMutation
} from "../../../api/stores/stores.query";
import useDebounce from "../../../hooks/useDebouncs";
import { formatDefaultDateTime } from "../../../utils/Formatter";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import SearchableDropdown from "../../common/editableDropdown/EditableDropdown";
import ListComponentLast10Entries from "../../common/lists/ListComponentLast10Entries";
import NotificationHandler from "../../common/notificationHandler/NotificationHandler";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import Tooltips from "../../common/tooltip/Tooltips";

toast.configure();

const StoresInvite = props => {
    const navigate = useNavigate();
    const [width, setWidth] = useState("");
    const [firstName, setFirstName] = useState("");
    const [selectedLabelBrand, setSelectedLabelBrand] = useState("");
    const [value, setValue] = useState("");
    const [prevBrandData, setPrevBrandData] = useState(null);
    const handleSuccess = responseData => {
        const failText = `${labelStoreName} Already Invited`;
        const successTextHeader = "Invitation Sent!";
        const successTextBody = `${firstName} will get an invitation by email with the guidelines for ${labelStoreName} to join Texcon group.`;
        const navigateTo = "/stores";

        NotificationHandler(responseData, null, failText, successTextHeader, successTextBody, navigate, navigateTo);
    };
    const { mutate: getStoreInfo, data: storeInfo, isLoading: storeInfoLoading } = useGetStoreContactInfoMutation();
    const { mutate: getData, data: storesData, isLoading } = useGetStoresNameMutation();
    const { mutate: getGroupData, data: tableData, isLoading: tableDataLoading } = useGetGroupInvitedMutation();
    const { mutate: inviteStore, data: inviteData, isLoading: inviteLoading } = useInviteStoreMutation(handleSuccess);
    const inputRef = useRef();
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [storeName, setStoreName] = useState("");
    const [labelStoreName, setlabelStoreName] = useState("");
    const [memberNr, setMemberNr] = useState("");
    const [buttonGray, setButtonGray] = useState(false);
    const [confirmPopUp, setConfirmPopUp] = useState(false);
    const brand = useDebounce(storeName, 100);

    useEffect(() => {
        getGroupData({
            companyID: localStorage.getItem("currentCompanyID")
        });
    }, []);

    const getstoresData = () => {
        getData({
            storeName: brand,
            companyID: localStorage.getItem("currentCompanyID")
        });
    };
    useEffect(() => {
        if (brand.length > 2) {
            getstoresData();
        }
    }, [brand]);

    useEffect(() => {
        if (storesData) {
            setPrevBrandData(storesData?.data);
        }
    }, [storesData]);

    useEffect(() => {
        if (selectedLabelBrand) {
            getStoreInfo({
                storeObjID: selectedLabelBrand
            });
        }
    }, [selectedLabelBrand]);

    useEffect(() => {
        setEmail(storeInfo?.data?.data?.email || "");
        setFirstName(storeInfo?.data?.data?.firstName || "");
        setLastName(storeInfo?.data?.data?.lastName || "");
    }, [storeInfo]);

    const columns = [
        {
            name: "Store",
            selector: row => row?.store ?? "-"
            //sortable: true
        },
        {
            name: "Member nr",
            selector: row => row?.memberNr ?? "-"
        },
        {
            name: "Contact Person",
            selector: row => row?.contactPerson ?? "-"
        },
        {
            name: "Contact email",
            selector: row => row?.contactEmail ?? "-"
        },
        {
            name: "Invited By",
            selector: row => row?.invitedBy ?? "-"
        },
        {
            name: "Invited On",
            selector: row => formatDefaultDateTime(row?.invitedOn)
        },
        {
            name: "Joined On",
            selector: row => formatDefaultDateTime(row?.affiliatedOn)
        }
    ];

    const handleSubmit = () => {
        const formData = {
            companyID: localStorage.getItem("currentCompanyID"),
            BOstagesAccountID: localStorage.getItem("token"),
            contactEmail: email,
            contactFirstName: firstName,
            storeID: selectedLabelBrand || undefined,
            contactLastName: lastName,
            storename: !value ? storeName : undefined,
            id: !value ? "1" : "2"
        };

        inviteStore(formData);
    };

    const data = storesData?.data;

    useEffect(() => {
        const updateWidth = () => {
            const inputWidth = inputRef.current.offsetWidth;
            setWidth(inputWidth);
        };

        updateWidth();

        window.addEventListener("resize", updateWidth);

        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    });

    useEffect(() => {
        if (!props?.layoutType) return;
        setupHeaders(props);
    }, [props?.layoutType]);

    return (
        <div style={{ marginTop: "-24px", marginBottom: "24px" }}>
            <div className="filtertopcombo" style={{ marginTop: "15px", paddingBottom: "15px", textAlign: "right" }}>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <div className="filtersection creatorsbtnset enroll-brand ">
                            <span
                                className={
                                    buttonGray
                                        ? "customeset textinside next-new-btn"
                                        : "customeset textinside next-new-btn disabledbtn"
                                }>
                                <BtnLargeSolid
                                    type="submit"
                                    onClick={() => {
                                        setConfirmPopUp(true);
                                    }}
                                    className="float-right"
                                    disabled={!firstName || (!value && !storeName) || !email}
                                    text="INVITE"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="new-section" style={{ marginTop: "24px", borderRadius: "4px" }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="cardsection" style={{ marginBottom: "24px" }}>
                            <div className="card-header">
                                <h3 className="card-title">
                                    <div style={{ paddingLeft: "0px", display: "inline-block" }}> INVITE STORE</div>
                                    <span className="formcautions">
                                        <ul>
                                            <li>
                                                <span className="makesqaure mandatorythis"></span>
                                                <span>recommended</span>
                                            </li>
                                            <li>
                                                <span className="makesqaure mandatorythis"></span>
                                                mandatory
                                            </li>
                                            <li>
                                                <span className="makesqaure lockedthis"></span>
                                                locked
                                            </li>
                                        </ul>
                                    </span>
                                </h3>
                            </div>

                            <div className="card-body" style={{ paddingBottom: "32px" }}>
                                <form>
                                    <div className="row first-portion-container">
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "16px",
                                                alignItems: "center",
                                                height: "100%"
                                            }}>
                                            <div
                                                className="first-portion-input-one invite-input-one"
                                                style={{ paddingLeft: "16px", color: "#7C868B" }}>
                                                <label
                                                    htmlFor="fieldCategoryName"
                                                    style={{ marginBottom: "4px", fontSize: "0.75rem" }}>
                                                    <Tooltips
                                                        title="Insert the name of the store you want to invite to join your group. If the store is already in the BOtag network, you'll see it in the dropdown. If not, insert the name of the store, complete the rest of the form and your contact person will get the invitation to enroll on BOtag and join your group."
                                                        children={
                                                            <i
                                                                className="fas fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ fontSize: "10px", fontWeight: "500" }}></i>
                                                        }></Tooltips>
                                                    <span style={{ marginLeft: "4px" }}>Store</span>
                                                </label>

                                                <SearchableDropdown
                                                    // inputRef={inputRef}
                                                    options={data || prevBrandData}
                                                    label="name"
                                                    id="id"
                                                    selectedVal={value}
                                                    handleChange={val => {
                                                        setValue(val);
                                                    }}
                                                    handleInputChange={e => {
                                                        setStoreName(e);
                                                    }}
                                                    setLabelId={setSelectedLabelBrand}
                                                    NoOptionsMessage="No Stores Available"
                                                    isLoading={isLoading}
                                                />
                                            </div>
                                            <div
                                                className="first-portion-input-one invite-input-two"
                                                style={{ color: "#7C868B" }}>
                                                <label
                                                    htmlFor="fieldCategoryName"
                                                    style={{ marginBottom: "4px", fontSize: "0.75rem" }}>
                                                    <Tooltips
                                                        title="The contact person can be anyone you know, responsible to take decisions on this store. Insert their email here. If your contact person is not the decision maker, no worries, they will be able to redirect the invitation to the right person."
                                                        children={
                                                            <i
                                                                className="fas fa-info-circle"
                                                                aria-hidden="true"
                                                                style={{ fontSize: "10px", fontWeight: "500" }}></i>
                                                        }></Tooltips>
                                                    <span style={{ marginLeft: "4px" }}>Contact Email</span>
                                                </label>

                                                <br />
                                                <div>
                                                    <i className="fa-regular fa-envelope email-icon"></i>
                                                    <div className="line"></div>
                                                    <input
                                                        ref={inputRef}
                                                        value={email}
                                                        type="email"
                                                        className="invite-email-input"
                                                        onChange={e => {
                                                            setEmail(e.target.value);
                                                        }}
                                                        style={{
                                                            borderLeft:
                                                                email?.trim() !== ""
                                                                    ? "5px solid #141922"
                                                                    : "5px solid #ff9900",
                                                            maxWidth: "100%"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" first-portion-input-one invite-input-two">
                                                <FormSimpleComponent
                                                    titleName="Contact first name"
                                                    titleColor="#7C868B"
                                                    backgroundColor="#10141B"
                                                    border="true"
                                                    inputColor="#00BB00"
                                                    // borderColor="#ff9900"
                                                    width="100%"
                                                    maxWidth="100%"
                                                    iconDisplay="none"
                                                    onChange={e => {
                                                        setFirstName(e.target.value);
                                                    }}
                                                    value={firstName}
                                                    style={{
                                                        borderLeft:
                                                            firstName?.trim() !== ""
                                                                ? "5px solid #141922"
                                                                : "5px solid #ff9900",
                                                        backgroundColor: "#10141B",
                                                        color: "#00BB00",
                                                        maxWidth: "100%",
                                                        width: "100%"
                                                    }}
                                                    tooltipTitle="Insert the first name of the contact person. The contact person can be anyone you know, responsible to take decisions on this store. If your contact person is not the decision maker, no worries, they will be able to redirect the invitation to the right person."
                                                />
                                            </div>

                                            <div
                                                className="first-portion-input-one invite-input-two"
                                                style={{ paddingRight: "20px" }}>
                                                <FormSimpleComponent
                                                    titleName="Contact last name"
                                                    titleColor="#7C868B"
                                                    backgroundColor="#10141B"
                                                    border="true"
                                                    inputColor="#00BB00"
                                                    // borderColor="#ff9900"
                                                    width="100%"
                                                    maxWidth="100%"
                                                    iconDisplay="none"
                                                    onChange={e => {
                                                        setLastName(e.target.value);
                                                    }}
                                                    value={lastName}
                                                    style={{
                                                        borderLeft:
                                                            lastName?.trim() !== ""
                                                                ? "5px solid #141922"
                                                                : "5px solid var(--text-icons-blue-500)",
                                                        backgroundColor: "#10141B",
                                                        color: "#00BB00",
                                                        maxWidth: "100%",
                                                        width: "100%"
                                                    }}
                                                    tooltipTitle="Insert the last name of the contact person. The contact person can be anyone you know, responsible to take decisions on this store. If your contact person is not the decision maker, no worries, they will be able to redirect the invitation to the right person."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row first-portion-container">
                                        <div
                                            className="first-portion-input-one invite-input-two"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "start",
                                                marginTop: "24px",
                                                paddingLeft: "16px"
                                            }}>
                                            <FormSimpleComponent
                                                titleName="Member nr."
                                                titleColor="#7C868B"
                                                backgroundColor="#10141B"
                                                border="true"
                                                inputColor="#00BB00"
                                                // borderColor="#ff9900"

                                                iconDisplay="none"
                                                onChange={e => {
                                                    setMemberNr(e.target.value);
                                                }}
                                                value={memberNr}
                                                style={{
                                                    borderLeft:
                                                        memberNr?.trim() !== ""
                                                            ? "5px solid #141922"
                                                            : "5px solid var(--text-icons-blue-500)",
                                                    backgroundColor: "#10141B",
                                                    color: "#00BB00",
                                                    width: width - 4
                                                }}
                                                tooltipTitle="If you are inviting a store that has already a membership identification code (or number), insert that ID here."
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="col-md-12 "
                style={{ backgroundColor: "#1E2631", paddingLeft: "-10px", position: "relative", borderRadius: "4px" }}>
                {confirmPopUp && (
                    <div style={{ position: "absolute", zIndex: "9999999", top: "10px", left: "38%" }}>
                        <FullScreenOverlay
                            children={
                                <DialogBoxComponent
                                    heading="JUST CHECKING..."
                                    headingColor="#00AEEF"
                                    title="Ready To Invite?"
                                    // message={`Ready to invite ${firstName} at ${email}?`}
                                    message={`Ready to send an invitation to ${firstName} ${lastName}, inviting their store ${
                                        value || storeName
                                    } to join the Texcon group?`}
                                    onClickYes={() => {
                                        handleSubmit();
                                        setConfirmPopUp(false);
                                    }}
                                    onClickNo={() => {
                                        setConfirmPopUp(false);
                                    }}
                                    YesBtnColor={true}
                                />
                            }
                        />
                    </div>
                )}
                {inviteLoading ? (
                    <div style={{ position: "absolute", zIndex: "9999999", top: "10px", left: "38%" }}>
                        <FullScreenOverlay
                            children={
                                <DialogBoxComponent
                                    heading="SENDING INVITATION"
                                    headingColor="#00BB00"
                                    loading="true"
                                />
                            }
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div>
                <ListComponentLast10Entries
                    title="Stores"
                    columns={columns}
                    api={{
                        endpoint: "getGroupInvitedStoresData",
                        formData: {
                            companyID: localStorage.getItem("currentCompanyID")
                        }
                        //onSuccess: mapListData
                    }}
                    pageLimit={20}
                    currentPage={1}
                    queryKey={"StoresInviteList"}
                    actions={{ includeActions: true }}
                    includeStatus={{
                        stickyStatus: true,
                        statusFromPath: "status",
                        statusColors: [
                            { colorClass: "text-blue-600", forValues: ["Active", "Accepted"] },
                            { colorClass: "text-green-500", forValues: ["Invited"] }
                        ]
                    }}
                />
            </div>
        </div>
    );
};

export default StoresInvite;

function setupHeaders(props) {
    props.setControlBarLeftBtns([
        { to: "#", disabled: true, active: false, title: "BRANDS", icon: "fa-magnifying-glass" }
    ]);
    props.setControlBarCenterBtns([{ to: "/stores", active: true, disabled: false, title: "STORES" }]);
    props.setControlBarRightBtns([
        { to: "/stores", disabled: false, title: "View", icon: "eye" },
        { to: "/stores/invite", active: true, disable: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: false,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
