import React from "react";
import FormSimpleComponent from "../../../common/forms/FormSimpleComponent";

const OtherFieldInputs = () => {
    const style = {
        backgroundColor: "#10141B",
        color: "#4F5B6D",
        borderLeft: "5px solid #4F5B6D",
        margin: "0 auto", // Center the component horizontally
        padding: "0 10px" // Optional: Add padding to the sides
    };
    const maxWidth = "1000px";

    const inputWidth = window.innerWidth <= 1200 ? "100%" : "calc(100% - 0px)";

    return (
        <div className="product-info-container">
            <FormSimpleComponent
                disabledValue={true}
                titleName="Model Code"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Scanner"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Retail Group"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Retail Group Nr."
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Quality Discription"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Quality Code"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Assortment"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Assortment Code"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Assortment Qyt"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Comments"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Units"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
        </div>
    );
};

export default OtherFieldInputs;
