import { useEffect, useRef, useState } from "react";
// import "../../../App.css";

const SearchableDropdown = ({
    options,
    label,
    id,
    selectedVal,
    handleChange,
    handleInputChange,
    setLabelId,
    NoOptionsMessage,
    isLoading
    // inputRef
}) => {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    // const inputRef = useRef(null);

    const selectOption = option => {
        setQuery(() => "");
        handleChange(option[label]);
        setIsOpen(isOpen => !isOpen);
    };

    const getDisplayValue = () => {
        if (query) return query;
        if (selectedVal) return selectedVal;
        return "";
    };

    const filter = options => {
        return options?.filter(option => option[label]?.toLowerCase()?.startsWith(query.toLocaleLowerCase()));
    };

    const handleMenuOpen = () => {
        if (query.length <= 2) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    document.addEventListener("click", () => {
        setIsOpen(false);
    });

    return (
        <div className="dropdown">
            <div className="control">
                <div className="selected-value">
                    <input
                        style={{
                            borderLeft: query?.length > 0 || selectedVal ? "5px solid #141922" : "5px solid #FF9900"
                        }}
                        // style={{ borderLeft: "5px solid green" }}
                        className="editable-dropdown"
                        // ref={inputRef}
                        type="text"
                        value={getDisplayValue()}
                        name="searchTerm"
                        onChange={e => {
                            setQuery(e.target.value);
                            handleChange(null);
                            handleMenuOpen();
                            handleInputChange(e.target.value);
                        }}
                        autoComplete="off"
                    />
                </div>
            </div>

            <div className={`options ${isOpen ? "open" : ""}`}>
                {options || options?.length >= 1 ? (
                    <div>
                        {filter(options)?.map((option, index) => {
                            return (
                                <div
                                    style={{
                                        color:
                                            option?.affiliatedToGroup === true || option?.alreadyInvited === true
                                                ? "#4F5B6D"
                                                : "#d1d1d1"
                                    }}
                                    onClick={
                                        option?.affiliatedToGroup === true || option?.alreadyInvited === true
                                            ? null
                                            : () => {
                                                  selectOption(option);
                                                  setLabelId(option?._id);
                                              }
                                    }
                                    className={`option ${option[label] === selectedVal ? "selected" : ""}`}
                                    key={`${id}-${index}`}>
                                    {option[label]}{" "}
                                    {option?.affiliatedToGroup === true ? (
                                        <i style={{ color: "#4F5B6D" }}>Affiliated</i>
                                    ) : (
                                        ""
                                    )}
                                    {option?.alreadyInvited === true && option?.affiliatedToGroup === false ? (
                                        <i style={{ color: "#4F5B6D" }}>Already Invited</i>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    ""
                )}

                {!options && isLoading === false ? <div className="no-options-container">{NoOptionsMessage}</div> : ""}
            </div>
        </div>
    );
};

export default SearchableDropdown;
