import React from "react";
import FormSimpleComponent from "../../../common/forms/FormSimpleComponent";

const ProductInfoInputs = ({
    reference,
    labelStoreName,
    description,
    shortDescription,
    countryOfOrigin,
    brandName,
    productCategory
}) => {
    const style = {
        backgroundColor: "#10141B",
        color: "#4F5B6D",
        borderLeft: "5px solid #4F5B6D",
        margin: "0 auto", // Center the component horizontally
        padding: "0 10px" // Optional: Add padding to the sides
    };
    const maxWidth = "1000px";

    const inputWidth = window.innerWidth <= 1200 ? "100%" : "calc(100% - 0px)";
    return (
        <div className="product-info-container">
            <FormSimpleComponent
                disabledValue={true}
                value={brandName}
                titleName="Style Name"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Style Nr."
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                value={reference}
                titleName="Reference"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="SKU"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                value={productCategory}
                titleName="Category"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#4F5B6D"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Category code"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Sub-categ.1"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Sub-categ.2"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Short Description"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                value={shortDescription}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Description"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                value={description}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Net Weight"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Gross Weight"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Tarif Code"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Country of Origin"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                value={countryOfOrigin}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Country Code"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Gender"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
        </div>
    );
};

export default ProductInfoInputs;
