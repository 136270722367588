import React from "react";
import styled from "styled-components";
import GridCardComponent from "./generic/GridCardComponent";
import { defaultStatusColors, getSpanWithColor } from "../../../utils/CardUtils";

/* #region  SCOPED CSS */
const StyledPage = styled.div``;
/* #endregion */

/**
 * Renders a grid card component for displaying brand information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.title - The title of the brand.
 * @param {string} props.id - The ID of the brand.
 * @param {boolean} props.hasBorders - Indicates if the card has borders.
 * @param {string} [props.titlePrefix="BRAND"] - The prefix for the title.
 * @param {string} [props.idPrefix="BOTAG ID"] - The prefix for the ID.
 * @param {boolean} props.isCardEnabled - Indicates if the card is enabled.
 * @param {boolean} props.isCardSuspended - Indicates if the card is suspended.
 * @param {string} props.image - The image URL for the brand.
 * @param {Object[]} props.details - The details to display in the card.
 * @param {string} props.details[].key - The key of the detail.
 * @param {string} props.details[].value - The value of the detail.
 * @param {Object} props.edit - The edit button configuration.
 * @param {Object} props.suspend - The suspend button configuration.
 * @param {Object} props.delete - The delete button configuration.
 * @returns {JSX.Element} The rendered BrandsGridCard component.
 */
export default function BrandsGridCard(props) {
    let status = props?.data?.status ?? "--";

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <GridCardComponent
                primaryButton={{ title: "Details" }}
                secondaryButton={{ title: "Fulfillment Centers" }}
                title={props?.title}
                id={props?.id}
                hasBorders={props?.hasBorders}
                titlePrefix={props?.titlePrefix || "BRAND"}
                idPrefix={props?.idPrefix || "BOTAG ID"}
                isCardEnabled={props?.isCardEnabled}
                isCardSuspended={props?.isCardSuspended}
                image={props?.image}
                imageHasPadding={true}
                details={[
                    { key: "City, country:", value: props?.data?.cityCountry || "--" },
                    { key: "Invited by:", value: props?.data?.invitedBy || "--" },
                    { key: "Joined on:", value: props?.data?.joinedOn || "--" },
                    { key: "FFcenters/In group: ", value: props?.data?.ffcentersInGroup || "--" },
                    { key: "Contact:", value: props?.data?.contactPerson || "--" },
                    { key: "Phone:", value: props?.data?.phoneNumber || "--" },
                    { key: "Email:", value: props?.data?.contactEmail || "--" },
                    { key: "Status:", value: getSpanWithColor(status ?? "--", defaultStatusColors) }
                ]}
                edit={{ hidden: true, ...props?.edit }}
                suspend={props?.suspend}
                delete={props?.delete}
                isEditDisabled={props?.isEditDisabled}
                isSuspendDisabled={props?.isSuspendDisabled}
                isDeleteDisabled={props?.isDeleteDisabled}
            />
        </StyledPage>
    );
}
