import DatePickerComponent from "./DatePickerComponent";
import "../../../assets/css/components/DashboardHeader.css";

const DashboardHeader = ({ onClick }) => {
    return (
        <div className="dashboad-header-container">
            <div className="header-first-portion">
                <DatePickerComponent />
                <p>compare to</p>
                <div className="dashboard-custom-date-picker">
                    <i class="fa-light fa-calendar" style={{ color: "#4F5B6D" }}></i>
                    <div className="dates-wrapper" style={{ color: "#4F5B6D" }}>
                        <div style={{ color: "#4F5B6D" }}>26 January 2024</div>-
                        <div style={{ color: "#4F5B6D" }}>2 Feburary 2024</div>
                    </div>
                    <i class="fa-light fa-chevron-down" style={{ color: "#4F5B6D" }}></i>
                </div>
                <div className="dashboard-header-data-labels">
                    <i class="fa-light fa-store"></i>&nbsp; Active stores: 120
                </div>
                <div className="dashboard-header-data-labels">
                    <i class="fa-light fa-tags"></i>&nbsp; Active Brands: 79
                </div>
            </div>
            <div className="header-second-portion">
                <button className="dashboard-header-btn" onClick={onClick}>
                    CHANGE
                </button>
            </div>
        </div>
    );
};

export default DashboardHeader;
