import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useState } from "react";
import { APP_TITLE } from "../../../config/constant";
import { useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .profile-div {
        height: 120px !important;
    }

    .datatable-section h4 {
        color: var(--text-icons-gray-100);
        margin-right: 10px;
        font-size: 14px;
    }

    .datatable-section .search-bar {
        margin: 0;
        border-bottom: 2px solid var(--backgrounds-lines-n-400);
    }

    .datatable-section .search-bar > span {
        display: flex;
        line-height: 20px;
        color: var(--text-icons-gray-300);
        align-items: center;
        font-size: 9pt;
    }

    .filter-section > span {
        line-height: 35px;
        font-size: 10pt;
        margin-right: 10px;
        border-bottom: 2px;
    }

    .filter-section > .search-input {
        border-radius: 0;
        background: var(--backgrounds-lines-n-400);
        border-color: var(--backgrounds-lines-n-400);
        box-shadow: none;
        height: 35px;
    }

    .creators-section .cardsection {
        margin-bottom: 10px;
    }
`;
/* #endregion */

export default function CreateSupplier(props) {
    document.title = `${APP_TITLE} - New Supplier`;

    /* #region STATES */
    const { allUserInfo } = useSelector(state => state.userData);

    const [buttonName, setButtonName] = useState("INVITE");
    const [buttonGray, setButtonGray] = useState(true);

    const [preLoader, setPreLoader] = useState(true);
    const [submitLoader, setSubmitLoader] = useState(false);

    const [brandsOptions, setBrandsOptions] = useState();

    //INVITES LIST
    const [invitesList, setInvitesList] = useState([
        {
            companyName: "Kaare Lund Agenrurer",
            contactName: "Fredrik Lund",
            contactEmail: "fredrik@karelund.no",
            nrOfBrands: "2",
            brands: "Replay, The Product",
            country: "Norway",
            orgNr: "937 657 890",
            status: "Invited"
        }
    ]);
    const [dataLoading, setDataLoading] = useState(false);

    /* #region FORM FIELDS */
    const [fieldCategoryName, setFieldCategoryName] = useState("");
    const [fieldCategoryNameError, setFieldCategoryNameError] = useState(false);

    const [fieldDescription, setFieldDescription] = useState("");
    const [fieldDescriptionError, setFieldDescriptionError] = useState(false);

    const [fieldNrOfBrandsDefaultOption, setFieldNrOfBrandsDefaultOption] = useState();
    const [fieldNrOfBrandsError, setFieldNrOfBrandsError] = useState(false);

    const [fieldBrands, setFieldBrands] = useState();
    const [fieldBrandsError, setFieldBrandsError] = useState(false);
    /* #endregion */

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        //setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);

    useEffect(() => {
        if (!allUserInfo) return;

        setBrandsOptions(allUserInfo?.Company?._labelBrands?.map(brand => ({ label: brand.name, value: brand._id })));
    }, [allUserInfo]);

    /* #endregion */

    /* #region METHODS */
    const handleSubmit = () => {};

    /* #endregion */

    /* #region CONSTANTS */
    const columns = [
        {
            name: "Company Name",
            selector: row => row.companyName,
            sortable: true,
            width: "200px"
        },
        {
            name: "Contact name",
            selector: row => row.contactName,
            sortable: true,
            width: "150px"
        },
        {
            name: "Contact email",
            selector: row => row.contactEmail,
            sortable: true,
            width: "150px"
        },
        {
            name: "Nr. of brands",
            selector: row => row.nrOfBrands,
            sortable: true,
            width: "150px"
        },
        {
            name: "Brands",
            selector: row => row.brands,
            sortable: true
        },
        {
            name: "Country",
            selector: row => row.country,
            sortable: true,
            width: "150px"
        },
        {
            name: "Org. Nr.",
            selector: row => row.orgNr,
            sortable: true,
            width: "150px"
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true,
            width: "150px",
            cell: row => (
                <>
                    <span
                        className="store-name2"
                        style={{
                            color:
                                row.status === "Invited"
                                    ? "#FF9900"
                                    : row.status === "Active"
                                    ? "var(--text-icons-blue-500)"
                                    : row.status === "Enrolled"
                                    ? "#00BB00"
                                    : row.status === "Suspended"
                                    ? "#FF9900"
                                    : "#D1D1D1"
                        }}
                        title={row.status}>
                        {row.status}
                    </span>
                </>
            )
        },
        {
            name: "Action",
            selector: row => row.action,
            sortable: false,
            cell: row => (
                <>
                    <Link className="btn button-not-active main-btn--smaller" style={{ marginRight: "10px" }} to="#">
                        UPDATE
                    </Link>
                    <Link className="btn button-not-active main-btn--smaller" style={{ marginRight: "10px" }} to="#">
                        RESEND
                    </Link>
                    <Link className="btn button-not-active main-btn--smaller" to="#">
                        CANCEL
                    </Link>
                </>
            ),
            width: "220px",
            style: {
                justifyContent: "center",
                flexDirection: "row"
            }
        }
    ];

    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            "backgroundColor": "var(--backgrounds-lines-n-600)",
            "borderColor": "transparent",
            "border": state.isFocused ? "1px solid var(--BOStageG-Red-Outline)" : "1px solid transparent",
            "boxShadow": state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid var(--BOStageG-Red-Outline)"
            },
            "&:active": {
                border: "1px solid var(--BOStageG-Red-Outline)"
            }
        }),
        noOptionsMessage: styles => ({ ...styles, backgroundColor: "#333F50", color: "var(--text-icons-gray-100)" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                "backgroundColor": "#333F50",
                "color": "var(--text-icons-gray-100)",
                "cursor": "pointer",
                "border": "1px solid var(--backgrounds-lines-n-600)",
                "borderLeft": "1px solid transparent",
                "borderRight": "1px solid transparent",
                "&:hover": {
                    border: "1px solid var(--BOStageG-Red-Outline)"
                },
                "&:active": {
                    backgroundColor: "#333F50"
                }
            };
        },
        indicatorSeparator: styles => ({ ...styles, backgroundColor: "var(--backgrounds-lines-n-600)" }),
        menu: styles => ({
            ...styles,
            backgroundColor: "var(--backgrounds-lines-n-600)",
            marginTop: 0,
            borderRadius: 0
        }),
        menuList: styles => ({
            ...styles,
            "padding": 0,
            "paddingRight": "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: "var(--text-icons-gray-100)" }),
        placeholder: styles => ({ ...styles, color: "var(--text-icons-gray-100)" }),
        input: styles => ({ ...styles, color: "var(--text-icons-gray-100)" }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "var(--text-icons-gray-100)",
            backgroundColor: "#205380",
            borderRadius: "0px"
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            "color": "var(--text-icons-gray-100)",
            "backgroundColor": "#205380",
            "borderRadius": "0px",
            ":hover": {
                backgroundColor: "#205380",
                color: "white"
            }
        })
    };

    createTheme(
        "solarized",
        {
            text: {
                primary: "#D1D1D1",
                secondary: "#D1D1D1"
            },
            background: {
                default: "rgba(0, 0, 0, 0)"
            },
            context: {
                background: "#cb4b16",
                text: "#D1D1D1"
            },
            divider: {
                default: "#333F50"
            },
            action: {
                button: "rgba(0,0,0,.54)",
                hover: "rgba(0,0,0,.08)",
                disabled: "rgba(0,0,0,.12)"
            }
        },
        "dark"
    );

    const nrOfBrandsOptions = [
        { value: "*", label: "No limit" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" }
    ];

    const countryOptions = [{ value: "Norway", label: "Norway" }];

    const businessTypeOptions = [
        { value: "Retail", label: "Retail" },
        { value: "Label Brand", label: "Label Brand" }
    ];
    /* #endregion */

    /* #region COMPONENTS */
    const CustomLoader = () => (
        <div className="loaderclass">
            <i className="fa fa-spinner fa-spin"></i>
        </div>
    );

    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className="filtertopcombo" style={{ marginTop: "20px", textAlign: "right" }}>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <div className="filtersection creatorsbtnset enroll-brand ">
                            <span
                                className={
                                    buttonGray
                                        ? "customeset textinside next-new-btn"
                                        : "customeset textinside next-new-btn disabledbtn"
                                }>
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="btn btn-success main-btn"
                                    disabled={buttonGray ? true : ""}>
                                    {buttonName}
                                    {submitLoader ? <i className="fas fa-spinner fa-spin"></i> : ""}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <section className="new-section" style={{ marginTop: "30px" }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="cardsection">
                            <div className="card-header">
                                <h3 className="card-title">
                                    INVITE SUPPLIER
                                    <span className="formcautions">
                                        <ul>
                                            <li>
                                                <span className="makesqaure mandatorythis"></span>mandatory fields
                                            </li>
                                            <li>
                                                <span className="makesqaure lockedthis"></span>locked fields
                                            </li>
                                        </ul>
                                    </span>
                                </h3>
                            </div>

                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group activeborder">
                                                <label htmlFor="fieldCategoryName">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Company
                                                    name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fieldCategoryName"
                                                    value2={fieldCategoryName}
                                                    className={
                                                        fieldCategoryNameError
                                                            ? "form-control error-input"
                                                            : "form-control"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="fieldDescription">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Contact
                                                    email
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fieldDescription"
                                                    value2={fieldDescription}
                                                    className={
                                                        fieldDescriptionError
                                                            ? "form-control error-input"
                                                            : "form-control"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group activeborder">
                                                <label htmlFor="fieldDescription">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Contact
                                                    first name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fieldDescription"
                                                    value2={fieldDescription}
                                                    className={
                                                        fieldDescriptionError
                                                            ? "form-control error-input"
                                                            : "form-control"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group activeborder">
                                                <label htmlFor="fieldDescription">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Contact
                                                    last name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fieldDescription"
                                                    value2={fieldDescription}
                                                    className={
                                                        fieldDescriptionError
                                                            ? "form-control error-input"
                                                            : "form-control"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "15px" }}>
                                        <div className="col-md-3">
                                            <div className="form-group activeborder">
                                                <label htmlFor="fieldCategoryName">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Country
                                                </label>
                                                <Select
                                                    className={"selects"}
                                                    options={countryOptions}
                                                    styles={colourStyles}
                                                    placeholder={<div className="italic-placeholder">Select...</div>}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="fieldCategoryName">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Org.
                                                    number
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fieldDescription"
                                                    value2={fieldDescription}
                                                    className={
                                                        fieldDescriptionError
                                                            ? "form-control error-input"
                                                            : "form-control"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="fieldCategoryName">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Tax.
                                                    number
                                                </label>
                                                <input
                                                    type="text"
                                                    id="fieldDescription"
                                                    value2={fieldDescription}
                                                    className={
                                                        fieldDescriptionError
                                                            ? "form-control error-input"
                                                            : "form-control"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group inactiveborder">
                                                <label htmlFor="fieldCategoryName">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Business
                                                    type
                                                </label>
                                                <Select
                                                    className={"selects"}
                                                    options={businessTypeOptions}
                                                    styles={colourStyles}
                                                    isDisabled={true}
                                                    value={{ value: "Retail", label: "Retail" }}
                                                    placeholder={<div className="italic-placeholder">Select...</div>}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "15px" }}>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="fieldCategoryName">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Nr of
                                                    brands
                                                </label>
                                                <Select
                                                    className={fieldNrOfBrandsError ? "selects error-input" : "selects"}
                                                    options={nrOfBrandsOptions}
                                                    onChange={e => {
                                                        setFieldNrOfBrandsError(false);
                                                        setFieldNrOfBrandsDefaultOption(e);
                                                    }}
                                                    styles={colourStyles}
                                                    value={fieldNrOfBrandsDefaultOption}
                                                    placeholder={<div className="italic-placeholder">Select...</div>}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="form-group">
                                                <label htmlFor="fieldDescription">
                                                    <i className="fas fa-info-circle" aria-hidden="true"></i> Brands
                                                </label>
                                                <Select
                                                    className={fieldBrandsError ? "selects error-input" : "selects"}
                                                    options={brandsOptions}
                                                    onChange={e => {
                                                        setFieldBrandsError(false);
                                                        setFieldBrands(e);
                                                    }}
                                                    styles={colourStyles}
                                                    value={fieldBrands}
                                                    placeholder={<div className="italic-placeholder">Select...</div>}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="datatable-section">
                <div className="container-fluid" style={{ padding: "0" }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tablestructure productPages search-bar sticky-action">
                                <span>
                                    <h4>Invited suppliers {invitesList ? `(${invitesList.length})` : ""}</h4> (last 10
                                    entries)
                                </span>
                            </div>
                            <div className="col-md-6 hidden" style={{ marginTop: "15px" }}>
                                <div className="filter-section">
                                    <span>Search for: </span>
                                    <input
                                        className="form-control search-input"
                                        type="text"
                                        aria-label="Search"
                                        disabled="disabled"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 tablestructure productPages">
                                <DataTable
                                    className="showItems"
                                    columns={columns}
                                    data={invitesList}
                                    theme="solarized"
                                    sortIcon={
                                        <i
                                            className="fa fa-sort-amount-asc"
                                            aria-hidden="true"
                                            style={{ marginLeft: "5px" }}></i>
                                    }
                                    progressPending={dataLoading}
                                    selectableRows
                                    //selectableRowsComponent={Checkbox}
                                    //defaultSortFieldId={1}
                                    //defaultSortAsc={true}
                                    responsive={true}
                                    //expandableRowsComponent={ExpandedComponent}
                                    progressComponent={<CustomLoader />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Suppliers", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/suppliers", active: false, disabled: false, title: "Suppliers" },
        { to: "/suppliers/create", active: true, disabled: false, title: "New Supplier" },
        { to: "/suppliers/importer", active: false, disabled: false, title: "Importer" }
    ]);
    props.setControlBarRightBtns([
        { to: "#", active: true, title: "List", icon: "fa-list" },
        { to: "#", active: false, disabled: true, title: "Grid", icon: "fa-grid" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: false,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
