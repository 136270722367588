import React from "react";
import FormSimpleComponent from "../../../common/forms/FormSimpleComponent";

const MaterialAndCareInputs = ({ composition, maintenance }) => {
    const style = {
        backgroundColor: "#10141B",
        color: "#4F5B6D",
        borderLeft: "5px solid #4F5B6D",
        margin: "0 auto", // Center the component horizontally
        padding: "0 10px" // Optional: Add padding to the sides
    };
    const maxWidth = "1000px";

    const inputWidth = window.innerWidth <= 1200 ? "100%" : "calc(100% - 0px)";

    return (
        <div className="product-info-container">
            <FormSimpleComponent
                value={composition}
                disabledValue={true}
                titleName="Material 1"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Material 2"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Material 3"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Material 4"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Material 5"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Material 6"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                value={maintenance}
                titleName="Care 1"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Care 2"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Care 3"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Care 4"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Care 5"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
            <FormSimpleComponent
                disabledValue={true}
                titleName="Care 6"
                titleColor="#7C868B"
                backgroundColor="#10141B"
                border="true"
                inputColor="#00BB00"
                iconDisplay="none"
                maxWidth={maxWidth}
                style={{ ...style, width: inputWidth }}
            />
        </div>
    );
};

export default MaterialAndCareInputs;
